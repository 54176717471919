import React from 'react'
import {ReleaseForm} from "./ReleaseForm"
import {useParams} from "react-router-dom"

export const EditReleasePage = () => {
    const id = useParams().releaseId
    return (
        <>
            <ReleaseForm releaseId={id} />
        </>
    )
}

import React from 'react'
import {ContainerForm} from "./ContainerForm"
import {useParams} from "react-router-dom"

export const EditContainerPage = () => {
    const id = useParams().agentId

    return (
        <>
            <ContainerForm agentId={id} />
        </>
    )
}

import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom"
import {SidebarContext} from "../../context/SidebarContext"
import {Loader} from "../partials/Loader"
import {AuthContext} from "../../context/AuthContext"
import {useHttp} from "../../hooks/http.hook"
import {useMessage} from "../../hooks/message.hook"
import {Header} from "../partials/Header"
import {NoTable} from "../partials/NoTable"

export const CycleCasesPage = () => {
    const cycleId = useParams().cycleId || null
    const releaseId = useParams().releaseId || null

    const { token, logout, user } = useContext(AuthContext)
    const {loading, request, error, clearError} = useHttp()
    const [users, setUsers] = useState(null)
    const message = useMessage()
    const history = useHistory()
    const [release, setRelease] = useState([])
    const [cycle, setCycle] = useState(null)
    const [cases, setCases] = useState([])
    const [execs, setExecs] = useState([])

    console.log('r.id', releaseId)

    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
        window.M.FormSelect.init(document.querySelectorAll('select'),  {classes: 'black-text'})
    })

    // get users data
    const fetchUsers = useCallback(async () => {
        try {
            const data = await request(`/api/auth/users`, 'POST', null, {authorization: 'Bearer ' + token})
            if (data.users) {
                setUsers(data.users)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request, setUsers])

    const getRelease = useCallback(async () => {
        try {
            const data = await request(`/api/release/${releaseId}`, 'GET', null, {authorization: 'Bearer ' + token, details: 1})
            console.log('rel', data.release)
            // setForm
            setRelease(prev => { return {...prev, ...data.release} })

            if (data.release && data.release.cycles && data.release.cycles.length) {
                setCycle(data.release.cycles.find(c => c.id === Number(cycleId)))
                if (data.release.cycles.find(c => c.id === Number(cycleId)).cases) {
                    setCases(data.release.cycles.find(c => c.id === Number(cycleId)).cases)
                }
                if (data.release.cycles.find(c => c.id === Number(cycleId)).executions) {
                    setExecs(data.release.cycles.find(c => c.id === Number(cycleId)).executions.filter(ex => ex.releaseId === Number(releaseId)))
                }
            }

        } catch (e) {
            console.log(e)
        }
    }, [request])


    useEffect(() => {
        fetchUsers()
        if (releaseId && cycleId) {
            getRelease()
        }
    }, [getRelease, fetchUsers])

    useEffect(() => {
        console.log('execs', execs)
    }, [execs])

    const deleteHandler = async (caseId) => {
        try {
            const data = await request(`/api/case/${caseId}`, 'DELETE', null, {authorization: 'Bearer ' + token})
            message(data.message)

        } catch (e) {}
    }

    if (loading) {
        return <Loader />
    }

    const createTestCase = () => {
        history.push(`/testCases/createTestCase`)
    }

    const changeExecHandler = async (event, execId) => {
        try {
            let idx = execs.findIndex(el => el.id === execId)
            execs[idx][event.target.name] = event.target.value

            const data = await request(`/api/exec/${execId}`, 'PUT', {status: event.target.value, userId: user.id}, {authorization: 'Bearer ' + token})
            message(data.message)

            setExecs([...execs])
        } catch (e) {
            console.log(e)
        }
    }

    // const deleteExecHandler = async (r) => {
    //     try {
    //         if (r && r.id > 0) {
    //             const data = await request(`/api/exec/${r.id}`, 'DELETE', null, {authorization: 'Bearer ' + token})
    //             message(data.message)
    //         }
    //         setExecRows([...execRows.filter(el => el.id !== r.id)])
    //     } catch (e) {}
    // }

    return (
        <>
            <Header params={{
                // title: release && release.cycles && release.cycles.length ? release.cycles.find(c => c.id === Number(cycleId)).name : '',
                title: cycle ? cycle.name : '',
                bk: [
                    {
                        title: 'Циклы ',
                        actionHandler: () => {history.goBack()}
                    },
                    {
                        title: cycle ? cycle.name : '',
                        actionHandler: () => {}
                    },
                ],
                btnL: {
                    actionHandler: ()=>{console.log('left')},
                    title: 'btnLeft',
                    display: 'none'
                },
                btnR: {
                    actionHandler: createTestCase,
                    title: '+ Тест кейсы',
                    display: 'block'
                },
                loading
            }}/>

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    {/*<h5>{release && release.cycles && release.cycles.length ? `${release.name} > ${release.cycles.find(c => c.id === Number(cycleId)).name}` : ''}</h5>*/}
                    <h5>{release && cycle ? `${release.name} - ${cycle.name}` : ''}</h5>
                    {/*<p className="txt-gray">Manage your project settings</p>*/}
                </div>
            </div>

            <div className="row clear-row flex-row">
                {release && cases && cases.length && execs && execs.length
                    ? (
                    <div className="col s12 pl-0">
                        <table className="highlight">
                            <thead>
                            <tr>
                                <th className="txt-gray ">id</th>
                                <th className="txt-gray ">Описание</th>
                                <th className="txt-gray ">Статус</th>
                                <th className="txt-gray ">Компонент</th>
                                <th className="txt-gray ">Метки</th>
                                <th className="txt-gray ">Создатель</th>
                                <th className="txt-gray ">Дата выполнения</th>
                                <th className="txt-gray " />
                            </tr>
                            </thead>
                            <tbody>
                            {cases.map(c => {
                                return(
                                    <tr
                                        key={c.id}
                                    >
                                        <td className="py-0">{c.id}</td>
                                        <td className="py-0 td-titleMAW">{c.title || '-'}</td>
                                        <td className="py-0">
                                            <div
                                                className={`input-field py-0 bRadius-7 ${
                                                execs.find(ex => ex.caseId === c.id).status === 'Unexecuted' ? 'bgGray' : execs.find(ex => ex.caseId === c.id).status === 'Success' ? 'bgGreen' : 'bgRed'}`}>
                                                <select
                                                    id={'status'+execs.find(ex => ex.caseId === c.id).id}
                                                    name="status"
                                                    value={execs.find(ex => ex.caseId === c.id).status}
                                                    onChange={(e) => {changeExecHandler(e, execs.find(ex => ex.caseId === c.id).id)}}
                                                >
                                                    <option value="Unexecuted">Не выполнено</option>
                                                    <option value="Success">Успех</option>
                                                    <option value="Fail">Неудача</option>
                                                </select>
                                            </div>

                                            {/*<p*/}
                                                {/*style={{display: 'block', padding: '2px', color: 'white'}}*/}
                                                {/*className={`bRadius-7 ${!c.cycles || !c.cycles.length || c.cycles && c.cycles.length && c.cycles[0].status === 'Unexecuted' ? 'bgGray' : c.cycles && c.cycles.length && c.cycles[0].status === 'Success' ? 'bgGreen' : 'bgRed'}`}*/}
                                            {/*>{c.cycles && c.cycles.length ? c.cycles[0].status : 'Unexecuted'}</p>*/}

                                        </td>
                                        <td className="py-0">{c.component}</td>
                                        <td className="py-0">{c.labels.length ? c.labels.map(l => l.label).join(', ') : '-'}</td>
                                        <td className="py-0 td-cBy">{users ? users.find(el => el.id === c.userId).email : '-'}</td>
                                        <td className="py-0 td-cOn">{c.updatedAt ? new Date(c.updatedAt).toLocaleDateString() : '-'}</td>
                                        <td className="py-0 td-actions">
                                            <i
                                                style={{cursor: 'pointer'}}
                                                className="fa fa-pencil-square-o opacity-0"
                                                aria-hidden="true"
                                                onClick={() => {history.push(`/project/${c.projectId}/testCases/${c.id}`)}}
                                            />
                                            <i
                                                style={{cursor: 'pointer'}}
                                                className="fa fa-trash-o pl-1 opacity-0"
                                                aria-hidden="true"
                                                onClick={() => {deleteHandler(c.id)}}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                            <tfoot>
                            {/*<tr onClick={addExecHandler}><td colSpan="7" className="center"><ul><li style={{cursor: 'pointer'}}>+ Add new execution</li></ul></td></tr>*/}
                            </tfoot>
                        </table>
                    </div>
                ) : (<NoTable params={{ title: `Нет тест кейсов` }} />)}
            </div>
        </>
    )

}

import React from 'react'
import {ReleaseForm} from "./ReleaseForm"

export const CreateReleasePage = () => {
    return (
        <>
            <ReleaseForm releaseId={0} />
        </>
    )
}

import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom"
import {SidebarContext} from "../../context/SidebarContext"
import {Loader} from "../partials/Loader"
import {AuthContext} from "../../context/AuthContext"
import {useHttp} from "../../hooks/http.hook"
import {useMessage} from "../../hooks/message.hook"
import {Header} from "../partials/Header"
import {NoTable} from "../partials/NoTable"
import {Icon} from "../partials/Icon"

export const RecordsPage = () => {
    const { token, logout } = useContext(AuthContext)
    const {loading, request, error, clearError} = useHttp()
    const id = useParams().id || null
    const prj = useContext(SidebarContext)
    const [project, setProject] = useState(null)
    const [projects, setProjects] = useState(null)
    const [users, setUsers] = useState(null)
    const message = useMessage()
    const history = useHistory()
    const [records, setRecords] = useState([])

    // logout + error
    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
    })

    const getProject = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/project/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
                setProject(data.project)
            }
            if (token) {
                const data = await request(`/api/project/projects`, 'POST', null, {authorization: 'Bearer ' + token})
                setProjects(data.projects)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    // get users data
    const fetchUsers = useCallback(async () => {
        try {
            const data = await request(`/api/auth/users`, 'POST', null, {authorization: 'Bearer ' + token})
            if (data.users) {
                setUsers(data.users)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request, setUsers])

    const getRecords = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/record/all`, 'POST', {projectId: id}, {authorization: 'Bearer ' + token})
                setRecords(data.records)
            }
            if (token && !id) {
                const data = await request(`/api/record/all`, 'POST', {projectId: null}, {authorization: 'Bearer ' + token})
                setRecords(data.records)
            }
        } catch (e) {
            console.log(e)
        }
    }, [])

    useEffect(() => {
        try {
            if (id) {
                prj.toggle(false, id)
            }
            getRecords(token, id)
            getProject(token, id)
            fetchUsers()
        } catch (e) {
            console.log(e)
        }
    }, [getRecords, id])

    if (loading) {
        return <Loader />
    }

    // header buttons
    const createRecord = () => {
        if (id) {
            history.push(`/project/${prj.id}/createRecord`)
        } else {
            history.push(`/records/createRecord`)
        }
    }

    // header buttons
    const createTestCase = () => {
        if (id) {
            history.push(`/project/${prj.id}/createTestCase`)
        } else {
            history.push(`/testCases/createTestCase`)
        }
    }

    const deleteHandler = async (id) => {
        try {
            const data = await request(`/api/record/${id}`, 'DELETE', null, {authorization: 'Bearer ' + token})
            message(data.message)

            let rc = records.filter(el => el.id !== id)
            setRecords([...rc])
        } catch (e) {}
    }

    const playIt = async (id) => {
        try {
            const data = await request(`/api/record/play/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
            message(data.message)
        } catch (e) {}
    }

    return (
        <>
            <Header params={{
                title: `Записи`,
                btnL: {
                    actionHandler: createRecord,
                    title: '+ Запись',
                    display: 'inline-block'
                },
                btnR: {
                    actionHandler: createTestCase,
                    title: '+ Тест кейс',
                    display: 'inline-block'
                },
                loading
            }}/>

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    <h5>Записи</h5>
                    {/*<p className="txt-gray">Manage your project settings</p>*/}
                </div>
            </div>

            <div className="row clear-row flex-row">
                {records && records.length ? (
                    <div className="col s12 pl-0">
                        <table className="highlight">
                            <thead>
                            <tr>
                                {/*<th className="txt-gray text-left">id</th>*/}
                                {/*<th className="txt-gray text-left">Data</th>*/}
                                {/*<th className="txt-gray text-left">FTP</th>*/}
                                {/*<th className="txt-gray text-left">Description</th>*/}
                                {/*<th className="txt-gray text-left">User</th>*/}
                                {/*<th className="txt-gray text-left">Project</th>*/}
                                {/*<th className="txt-gray text-left">Actions</th>*/}
                                <th className="txt-gray">Заголовок</th>
                                <th className="txt-gray">Пользователь</th>
                                <th />
                                <th className="txt-gray">Проект</th>
                                <th className="txt-gray">Дата</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {records && records.length ? records.map(c => {
                                return(
                                    <React.Fragment key={c.id}>
                                    {/*<tr*/}
                                        {/*key={c.id}*/}
                                    {/*>*/}
                                        {/*<td className="text-left">{c.id}</td>*/}
                                        {/*<td className="text-left">{c.data || '-'}</td>*/}
                                        {/*<td className="text-left">{c.ftp || '-'}</td>*/}
                                        {/*<td className="text-left">{c.description || '-'}</td>*/}
                                        {/*<td className="text-left">{users ? users.find(el => el.id === c.userId).name : '-'}</td>*/}
                                        {/*<td className="">{projects && projects.length ? projects.find(p => p.id === c.projectId).name : '-'}</td>*/}
                                        {/*/!*<td className="text-left">{project ? project.name : '-'}</td>*!/*/}
                                        {/*<td className="text-left">*/}
                                            {/*<i*/}
                                                {/*style={{cursor: 'pointer'}}*/}
                                                {/*className="fa fa-play-circle-o"*/}
                                                {/*aria-hidden="true"*/}
                                                {/*// onClick={() => {history.push(`/project/${prj.id}/testCases/${c.id}`)}}*/}
                                            {/*/>*/}
                                            {/*<i*/}
                                                {/*style={{cursor: 'pointer'}}*/}
                                                {/*className="fa fa-trash-o pl-1"*/}
                                                {/*aria-hidden="true"*/}
                                                {/*onClick={() => {deleteHandler(c.id)}}*/}
                                            {/*/>*/}
                                        {/*</td>*/}
                                    {/*</tr>*/}
                                    <tr
                                        key={c.id}
                                    >
                                        <td className="text-left td-titleMAW">
                                            <i style={{cursor: 'pointer'}} className="fa fa-play-circle-o txt-gray" aria-hidden="true" onClick={() => playIt(c.id)}/>
                                            {c.description || '-'}
                                        </td>
                                        <td className="text-left">{users && c.userId ? users.find(el => el.id === c.userId).email : '-'}</td>

                                        <td className="">
                                            {c.os === 'Windows' ? (<Icon name="windows"/>) : (c.os === 'MacOs' ? (<Icon name="apple"/>) : (<Icon name="mobile"/>))}
                                            {c.browser === 'Firefox' ? (<Icon name="firefox"/>) : (c.browser === 'Opera' ? (<Icon name="opera"/>) : (c.browser === 'Chrome' ? (<Icon name="chrome"/>) : (<Icon name="ie"/>)))}
                                        </td>

                                        <td className="">{projects && projects.length && c.projectId ? projects.find(p => p.id === c.projectId).name : '-'}</td>
                                        <td className="text-left td-cOn">{new Date(c.createdAt).toLocaleDateString('Ru-ru')}</td>
                                        <td className="text-left td-actions">
                                            <i
                                                style={{cursor: 'pointer'}}
                                                className="fa fa-pencil-square-o opacity-0"
                                                aria-hidden="true"
                                                onClick={() => {
                                                    history.push(`/project/${c.projectId}/records/${c.id}`)
                                                }}
                                            />
                                            <i
                                                style={{cursor: 'pointer'}}
                                                className="fa fa-trash-o pl-1 opacity-0"
                                                aria-hidden="true"
                                                onClick={() => {deleteHandler(c.id)}}
                                            />
                                        </td>
                                    </tr>
                                    </React.Fragment>
                                )
                            }) : (<></>)}
                            </tbody>
                            <tfoot>
                            {/*<tr onClick={addExecHandler}><td colSpan="7" className="center"><ul><li style={{cursor: 'pointer'}}>+ Add new execution</li></ul></td></tr>*/}
                            </tfoot>
                        </table>
                    </div>
                ) : (<NoTable params={{ title: `Нет записей` }} />)}
            </div>
        </>
    )

}
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router-dom"
import { SidebarContext } from "../../context/SidebarContext"
import { Loader } from "../partials/Loader"
import { AuthContext } from "../../context/AuthContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"
import { Header } from "../partials/Header"
import { NoTable } from "../partials/NoTable"

export const ProjectTestCasesPage = () => {
    const { token, logout, user } = useContext(AuthContext)
    const { loading, request, error, clearError } = useHttp()
    const id = useParams().id
    const prj = useContext(SidebarContext)
    const [project, setProject] = useState(null)
    const [users, setUsers] = useState(null)
    const message = useMessage()
    const history = useHistory()
    const [cases, setCases] = useState([])

    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
        window.M.FormSelect.init(document.querySelectorAll('select'), { classes: 'black-text' })
    })

    const getProject = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/project/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
                setProject(data.project)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    const getCases = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/case/all`, 'POST', { projectId: id }, { authorization: 'Bearer ' + token })

                // Only last updated execution in each case
                if (data.cases && data.cases.length) {
                    for (let i = 0; i < data.cases.length; i++) {
                        if (data.cases[i].executions.length) {
                            let ex = data.cases[i].executions.sort((a, b) => { return new Date(a.updatedAt) - new Date(b.updatedAt) })
                            ex = ex[ex.length - 1]
                            data.cases[i].executions = ex
                        } else {
                            data.cases[i].executions = { status: 'NoExec' }
                        }
                    }
                }

                console.log(data.cases)

                setCases(data.cases)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    // get users data
    const fetchUsers = useCallback(async () => {
        try {
            const data = await request(`/api/auth/users`, 'POST', null, { authorization: 'Bearer ' + token })
            if (data.users) {
                setUsers(data.users)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request, setUsers])

    useEffect(() => {
        // prj.toggle(true, id)
        fetchUsers()
        getProject(token, id)
        getCases(token, id)
    }, [getProject, getCases, id])

    console.log(cases)

    // const changeHandler = event => {
    //     setForm({ ...form, [event.target.name]: event.target.value })
    // }
    //
    // const updateHandler = async () => {
    //     try {
    //         const data = await request(`/api/project/${id}`, 'PUT', {...form}, {authorization: 'Bearer ' + token})
    //         setForm({name: data.project.name, slug: data.project.slug})
    //         message(data.message)
    //     } catch (e) {}
    // }

    const deleteHandler = async (caseId) => {
        try {
            const data = await request(`/api/case/${caseId}`, 'DELETE', null, { authorization: 'Bearer ' + token })
            message(data.message)

            setCases([...cases.filter(el => el.id !== caseId)])
        } catch (e) { }
    }

    const changeExecHandler = async (event, execId) => {
        try {
            let idx = cases.findIndex(el => el.executions.id === execId)
            cases[idx].executions.status = event.target.value

            const data = await request(`/api/exec/${execId}`, 'PUT', { status: event.target.value, userId: user.id }, { authorization: 'Bearer ' + token })
            message(data.message)

            setCases([...cases])
        } catch (e) {
            console.log(e)
        }
    }

    if (loading) {
        return <Loader />
    }

    const createTestCase = () => {
        history.push(`/project/${id}/createTestCase`)
    }

    console.log("cases", cases)

    return (
        <>
            <Header params={{
                title: project && project.name ? `Тест кейсы ${project.name}` : `Тест кейсы`,
                btnL: {
                    actionHandler: () => { console.log('left') },
                    title: 'btnLeft',
                    display: 'none'
                },
                btnR: {
                    actionHandler: createTestCase,
                    title: '+ Тест кейс',
                    display: 'block'
                },
                loading
            }} />

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    <h5>Тест кейсы</h5>
                    {/*<p className="txt-gray">Manage your project settings</p>*/}
                </div>
            </div>

            <div className="row clear-row flex-row">
                {cases && cases.length ? (
                    <div className="col s12 pl-0">
                        <table className="highlight">
                            <thead>
                                <tr>
                                    <th className="txt-gray ">id</th>
                                    <th className="txt-gray ">Описание</th>
                                    <th className="txt-gray ">Статус</th>
                                    <th className="txt-gray ">Компонент</th>
                                    <th className="txt-gray ">Метки</th>
                                    <th className="txt-gray ">Создатель</th>
                                    <th className="txt-gray ">Дата создания</th>
                                    <th className="txt-gray ">Действия</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cases && cases.length ? cases.map(c => {
                                    return (
                                        <tr
                                            key={c.id}
                                        >
                                            <td className="py-0">{c.id}</td>
                                            <td className="py-0 td-titleMAW">{c.title || '-'}</td>
                                            <td className="py-0">
                                                {c.executions.status !== 'NoExec' ? (
                                                    <div
                                                        className={`input-field py-0 bRadius-7 ${c.executions.status === 'Unexecuted' ? 'bgGray' : c.executions.status === 'Success' ? 'bgGreen' : 'bgRed'}`}>
                                                        <select
                                                            id={'status' + c.executions.id}
                                                            name="status"
                                                            value={c.executions.status}
                                                            onChange={(e) => { changeExecHandler(e, c.executions.id) }}
                                                        >
                                                            <option value="Unexecuted">Не выполнено</option>
                                                            <option value="Success">Успех</option>
                                                            <option value="Fail">Неудача</option>
                                                        </select>
                                                    </div>
                                                ) : (
                                                    <div className="input-field" style={{ fontSize: '11px', color: '#A0A0A0' }}>Не используется ни в одном релизе</div>
                                                )}
                                            </td>
                                            <td className="py-0">{c.component}</td>
                                            <td className="py-0">{c.labels.length ? c.labels.map(l => l.label).join(', ') : '-'}</td>
                                            <td className="py-0">{users ? users.find(el => el.id === c.userId).email : '-'}</td>
                                            <td className="py-0 td-cOn">{c.updatedAt ? new Date(c.updatedAt).toLocaleDateString() : '-'}</td>
                                            <td className="py-0 td-actions">

                                                <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>

                                                    {c.executions.status !== "Unexecuted" ?
                                                        < i
                                                            style={{ cursor: 'pointer' }}
                                                            className="fa fa-eye opacity-0"
                                                            aria-hidden="true"
                                                            onClick={() => { history.push(`/project/${id}/testCases/${c.id}/viewprogress`) }}
                                                        />
                                                        :
                                                        <></>
                                                    }
                                                    <i
                                                        style={{ cursor: 'pointer' }}
                                                        className="fa fa-pencil-square-o opacity-0"
                                                        aria-hidden="true"
                                                        onClick={() => { history.push(`/project/${id}/testCases/${c.id}`) }}
                                                    />
                                                    <i
                                                        style={{ cursor: 'pointer' }}
                                                        className="fa fa-trash-o opacity-0"
                                                        aria-hidden="true"
                                                        onClick={() => { deleteHandler(c.id) }}
                                                    />

                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) : (<tr><td colSpan="7" className="center">Нет тест кейсов</td></tr>)}
                            </tbody>
                            <tfoot>
                                {/*<tr onClick={addExecHandler}><td colSpan="7" className="center"><ul><li style={{cursor: 'pointer'}}>+ Add new execution</li></ul></td></tr>*/}
                            </tfoot>
                        </table>
                    </div>
                ) : (<NoTable params={{ title: `Нет тест кейсов` }} />)}
            </div>
        </>
    )

}

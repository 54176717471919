import {useCallback, useState} from 'react'

export const useToggleSidebar = () => {
    const [isProject, setIsProject] = useState(false)
    const [id, setId] = useState(null)

    const toggle = useCallback((isP, pid = null) => {
        setIsProject(isP)
        setId(pid)
    }, [])

    return {isProject, toggle, id}
}
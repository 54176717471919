import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router-dom"
import { Header } from "./partials/Header"
import { useHttp } from "../hooks/http.hook"
import { Loader } from "./partials/Loader"
import { useMessage } from "../hooks/message.hook"
import { AuthContext } from "../context/AuthContext"
import { SimpleColorProgress } from "./partials/SimpleColorProgress"


export const SearchPage = () => {
    const str = useParams().str
    const { token, logout, user } = useContext(AuthContext)
    const history = useHistory()
    const { loading, request, error, clearError } = useHttp()
    const message = useMessage()
    const [users, setUsers] = useState(null)
    const [cases, setCases] = useState([])
    const [releases, setReleases] = useState([])
    const [cycles, setCycles] = useState([])
    // const [str, setStr] = useState(useParams().str)


    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
        window.M.FormSelect.init(document.querySelectorAll('select'), { classes: 'black-text' })
    })


    // get users data
    const fetchUsers = useCallback(async () => {
        try {
            const data = await request(`/api/auth/users`, 'POST', null, { authorization: 'Bearer ' + token })
            if (data.users) {
                setUsers(data.users)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request, setUsers])

    const progr = (data) => {
        if (data && data.length) {
            data.map(rel => {
                let perc = 0

                let relExecs = []

                if (rel.cycles.length) {
                    rel.cycles.forEach(c => {
                        if (c.executions.length && c.executions.filter(e => e.releaseId === rel.id).length) {
                            relExecs = [...relExecs, ...c.executions.filter(e => e.releaseId === rel.id)]
                        }
                    })
                }

                rel.executions = relExecs

                let total = rel.executions.length
                let unexec = rel.executions.filter(ex => ex.status === 'Unexecuted').length
                let succes = rel.executions.filter(ex => ex.status === 'Success').length
                let fail = rel.executions.filter(ex => ex.status === 'Fail').length

                perc = Math.round(succes * 100 / total)

                rel.perc = perc
                rel.planned = unexec === total
                rel.active = succes > 0 || fail > 0 || rel.planned
                if (perc === 100) rel.active = false

                return rel
            })

            return data
        }
    }

    const getSearch = useCallback(async (token, ss) => {
        try {
            if (token) {
                const data = await request(`/api/search`, 'POST', { searchStr: ss }, { authorization: 'Bearer ' + token })

                data.releases = progr(data.releases)

                // Only last updated execution in each case
                if (data.cases && data.cases.length) {
                    for (let i = 0; i < data.cases.length; i++) {
                        if (data.cases[i].executions.length) {
                            let ex = data.cases[i].executions.sort((a, b) => { return new Date(a.updatedAt) - new Date(b.updatedAt) })
                            ex = ex[ex.length - 1]
                            data.cases[i].executions = ex
                        } else {
                            data.cases[i].executions = { status: 'NoExec' }
                        }
                    }
                }

                setCases(data.cases)
                setReleases(data.releases)
                setCycles(data.cycles)

                console.log('str', str)
                console.log('rel', data.releases)
                console.log('cas', data.cases)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])


    useEffect(() => {
        fetchUsers()
        getSearch(token, str)
    }, [str, getSearch])

    // useEffect(() => {
    //     console.log('str', str)
    //     setCases([])
    //     setReleases([])
    // }, [str])

    // useEffect(() => {
    //     console.log('rel', releases)
    //     console.log('case', cases)
    // }, [releases])

    const createTestCase = () => {
        history.push(`/testCases/createTestCase`)
    }

    const caseCount = (relId) => {
        let count = 0

        let idx = releases.findIndex(el => el.id === relId)
        if (releases[idx] && releases[idx].cycles.length) {
            releases[idx].cycles.forEach(c => {
                if (c.cases.length) {
                    count += c.cases.length
                }
            })
        }

        return count
    }

    const progress = (releaseId, cycleId) => {
        let res = [{
            value: 100,
            color: '#efefef'
        }]
        let perc = 0

        let cycle = cycles.find(c => c.id === Number(cycleId))

        if (cycle && cycle.executions.length) {
            let total = cycle.executions.filter(ex => ex.releaseId === Number(releaseId)).length
            let unexec = cycle.executions.filter(ex => ex.releaseId === Number(releaseId) && ex.status === 'Unexecuted').length
            let succes = cycle.executions.filter(ex => ex.releaseId === Number(releaseId) && ex.status === 'Success').length
            let fail = cycle.executions.filter(ex => ex.releaseId === Number(releaseId) && ex.status === 'Fail').length

            res = [
                {
                    value: Math.round(succes * 100 / total),
                    color: '#75b000' //green
                },
                {
                    value: Math.round(fail * 100 / total),
                    color: '#cc3402' //red
                },
                {
                    value: Math.round(unexec * 100 / total),
                    color: '#efefef' //gray
                },
            ]

            perc = Math.round(succes * 100 / total)
        }

        return { res, perc }
    }

    const deleteCycHandler = async (cycleId) => {
        try {
            const data = await request(`/api/cycle/${cycleId}`, 'DELETE', null, { authorization: 'Bearer ' + token })
            message(data.message)

            setCycles([...cycles.filter(el => el.id !== cycleId)])
        } catch (e) { }
    }

    const toggleCycle = (e, cl) => {
        if (e.target.tagName !== 'I') {
            e.target.parentElement.querySelector('.fa.fa-chevron-down').classList.toggle('d-none')
            e.target.parentElement.querySelector('.fa.fa-chevron-right').classList.toggle('d-none')
        } else {
            e.target.parentElement.querySelector('.fa.fa-chevron-down').classList.toggle('d-none')
            e.target.parentElement.querySelector('.fa.fa-chevron-right').classList.toggle('d-none')
        }
        let els = document.querySelectorAll(cl)
        if (els) {
            els.forEach(el => {
                el.classList.toggle('d-none')
            })
        }
    }

    const deleteRelHandler = async (releaseId) => {
        try {
            const data = await request(`/api/release/${releaseId}`, 'DELETE', null, { authorization: 'Bearer ' + token })
            message(data.message)

            setReleases([...releases.filter(el => el.id !== releaseId)])
        } catch (e) { }
    }

    const deleteCaseHandler = async (caseId) => {
        try {
            const data = await request(`/api/case/${caseId}`, 'DELETE', null, { authorization: 'Bearer ' + token })
            message(data.message)

            setCases([...cases.filter(el => el.id !== caseId)])
        } catch (e) { }
    }

    const changeExecHandler = async (event, execId) => {
        try {
            let idx = cases.findIndex(el => el.executions.id === execId)
            cases[idx].executions.status = event.target.value

            const data = await request(`/api/exec/${execId}`, 'PUT', { status: event.target.value, userId: user.id }, { authorization: 'Bearer ' + token })
            message(data.message)

            setCases([...cases])
        } catch (e) {
            console.log(e)
        }
    }

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <Header params={{
                title: `Поиск`,
                btnL: {
                    actionHandler: () => { console.log('left') },
                    title: 'btnLeft',
                    display: 'none'
                },
                btnR: {
                    actionHandler: createTestCase,
                    title: '+ Тест кейс',
                    display: 'block'
                },
                loading
            }} />

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    <h5>{str ? `Результаты поиска для "${str}"` : 'Нет строки поиска'}</h5>
                    {/*<p className="txt-gray">Manage your project settings</p>*/}
                </div>
            </div>

            {/*RELEASES SEARCH RESULT*/}
            {releases && releases.length ? (
                <>
                    <div className="row clear-row">
                        <div className="col-auto">
                            <h5>Релизы</h5>
                        </div>
                    </div>

                    <div className="row clear-row flex-row">
                        <div className="col s12 pl-0">
                            <table className="highlight">
                                <thead>
                                    <tr>
                                        <th className="txt-gray" style={{ textAlign: 'center' }}>Заголовок</th>
                                        <th className="txt-gray" style={{ textAlign: 'center' }}>Руководитель</th>
                                        <th className="txt-gray" style={{ textAlign: 'center' }}>Тест кейсы</th>
                                        <th className="txt-gray" style={{ textAlign: 'center' }}>Даты</th>
                                        <th className="txt-gray" style={{ textAlign: 'center' }}>Статус</th>
                                        <th className="txt-gray" style={{ textAlign: 'center' }} />
                                    </tr>
                                </thead>
                                <tbody>
                                    {releases && releases.length ? releases.map(c => {
                                        return (
                                            <React.Fragment key={c.id}>
                                                <tr
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => { history.push(`/releases/show/${c.id}`) }}
                                                >
                                                    <td className=""><i className="fa fa-folder-o" aria-hidden="true" />{c.name || '-'}</td>
                                                    <td className="">{users && users.length ? users.find(el => el.id === c.userId).email : '-'}</td>
                                                    <td className="">{caseCount(c.id)}</td>
                                                    <td className="">
                                                        <i
                                                            className="fa fa-calendar-o"
                                                            aria-hidden="true"
                                                            style={{ color: new Date() > new Date(c.end) ? 'red' : 'black' }}
                                                        />
                                                        {`${new Date(c.start).toLocaleDateString()} - ${new Date(c.end).toLocaleDateString()}`}
                                                    </td>
                                                    <td className="">{c.planned ? `Planning` : (c.perc === 100 ? `Released` : `Active ${c.perc}%`)}</td>
                                                    <td className="" style={{ zIndex: '15' }}>
                                                        <i
                                                            style={{ cursor: 'pointer' }}
                                                            className="fa fa-pencil-square-o opacity-0"
                                                            aria-hidden="true"
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                e.preventDefault()
                                                                history.push(`/project/releases/${c.id}`)
                                                            }
                                                            }
                                                        />
                                                        <i
                                                            style={{ cursor: 'pointer' }}
                                                            className="fa fa-trash-o pl-1 opacity-0"
                                                            aria-hidden="true"
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                e.preventDefault()
                                                                deleteRelHandler(c.id)
                                                            }
                                                            }
                                                        />
                                                    </td>
                                                </tr>

                                            </React.Fragment>
                                        )
                                    }) : (<></>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            ) : (<></>)}

            {/*CASES SEARCH RESULT*/}
            {cases && cases.length ? (
                <>
                    <div className="row clear-row">
                        <div className="col-auto">
                            <h5>Тест кейсы</h5>
                            {/*<p className="txt-gray">Manage your project settings</p>*/}
                        </div>
                    </div>

                    <div className="row clear-row flex-row">
                        <div className="col s12 pl-0">
                            <table className="highlight">
                                <thead>
                                    <tr>
                                        <th className="txt-gray ">id</th>
                                        <th className="txt-gray ">Описание</th>
                                        <th className="txt-gray ">Статус</th>
                                        <th className="txt-gray ">Компонент</th>
                                        <th className="txt-gray ">Метки</th>
                                        <th className="txt-gray ">Создано</th>
                                        <th className="txt-gray ">Дата создания</th>
                                        <th className="txt-gray " />
                                    </tr>
                                </thead>
                                <tbody>
                                    {cases && cases.length ? cases.map(c => {
                                        return (
                                            <tr
                                                key={c.id}
                                            >
                                                <td className="py-0">{c.id}</td>
                                                <td className="py-0">{c.title || '-'}</td>
                                                <td className="py-0">
                                                    {c.executions.status !== 'NoExec' ? (
                                                        <div
                                                            className={`input-field py-0 bRadius-7 ${c.executions.status === 'Unexecuted' ? 'bgGray' : c.executions.status === 'Success' ? 'bgGreen' : 'bgRed'}`}>
                                                            <select
                                                                id={'status' + c.executions.id}
                                                                name="status"
                                                                value={c.executions.status}
                                                                onChange={(e) => { changeExecHandler(e, c.executions.id) }}
                                                            >
                                                                <option value="Unexecuted">Не выполнено</option>
                                                                <option value="Success">Успех</option>
                                                                <option value="Fail">Неудача</option>
                                                            </select>
                                                        </div>
                                                    ) : (
                                                        <div className="input-field" style={{ fontSize: '11px', color: '#A0A0A0' }}>Не используется ни в одном релизе</div>
                                                    )}
                                                </td>
                                                <td className="py-0">{c.component}</td>
                                                <td className="py-0">{c.labels.length ? c.labels.map(l => l.label).join(', ') : '-'}</td>
                                                <td className="py-0">{users ? users.find(el => el.id === c.userId).email : '-'}</td>
                                                <td className="py-0">{c.updatedAt ? new Date(c.updatedAt).toLocaleDateString() : '-'}</td>
                                                <td className="py-0">
                                                    <i
                                                        style={{ cursor: 'pointer' }}
                                                        className="fa fa-pencil-square-o opacity-0"
                                                        aria-hidden="true"
                                                        onClick={() => { history.push(`/project/${c.projectId}/testCases/${c.id}`) }}
                                                    />
                                                    <i
                                                        style={{ cursor: 'pointer' }}
                                                        className="fa fa-trash-o pl-1 opacity-0"
                                                        aria-hidden="true"
                                                        onClick={() => { deleteCaseHandler(c.id) }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }) : (<></>)}
                                </tbody>
                                <tfoot>
                                    {/*<tr onClick={addExecHandler}><td colSpan="7" className="center"><ul><li style={{cursor: 'pointer'}}>+ Add new execution</li></ul></td></tr>*/}
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </>
            ) : (<></>)}

            {/*CYCLES SEARCH RESULT*/}
            {cycles && cycles.length ? (
                <>
                    <div className="row clear-row">
                        <div className="col-auto">
                            <h5>Тестовый циклы</h5>
                            {/*<p className="txt-gray">Manage your project settings</p>*/}
                        </div>
                    </div>

                    <div className="row clear-row flex-row">
                        <div className="col s12 pl-0">
                            <table className="highlight">
                                <thead>
                                    <tr>
                                        <th className="txt-gray ">Заголовок</th>
                                        <th className="txt-gray ">Статус</th>
                                        <th className="txt-gray ">Прогресс</th>
                                        <th className="txt-gray " />
                                    </tr>
                                </thead>
                                <tbody>
                                    {cycles && cycles.length ? cycles.map(c => {
                                        return (
                                            <React.Fragment key={c.id}>
                                                <tr
                                                    style={{ cursor: 'pointer', borderBottom: 'none' }}
                                                    onClick={(e) => { toggleCycle(e, `.rc-${c.id}`) }}
                                                >
                                                    <td className="" style={{ zIndex: '-15', paddingBottom: '5px' }}>
                                                        {c.releases && c.releases.length ? (<i className="fa fa-chevron-down" aria-hidden="true" />) : (<i className="fa fa-chevron-down opacity-0" aria-hidden="true" />)}
                                                        {c.releases && c.releases.length ? (<i className="fa fa-chevron-right d-none" aria-hidden="true" />) : (<i className="fa fa-chevron-right d-none opacity-0" aria-hidden="true" />)}
                                                        {/*<i className="fa fa-chevron-down" aria-hidden="true" />*/}
                                                        {/*<i className="fa fa-chevron-right d-none" aria-hidden="true" />*/}
                                                        <i className="fa fa-folder-o" aria-hidden="true" />
                                                        {c.name}
                                                    </td>
                                                    <td style={{ paddingBottom: '5px' }} />
                                                    <td style={{ paddingBottom: '5px' }} />
                                                    {/*<td className="center">{c.executions.length || '-'}</td>*/}
                                                    <td style={{ paddingBottom: '5px' }} className="">
                                                        <i
                                                            style={{ cursor: 'pointer' }}
                                                            className="fa fa-pencil-square-o opacity-0"
                                                            aria-hidden="true"
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                e.preventDefault()
                                                                history.push(`/project/${c.projectId}/testCycles/${c.id}`)
                                                            }}
                                                        />
                                                        <i
                                                            style={{ cursor: 'pointer' }}
                                                            className="fa fa-trash-o pl-1 opacity-0"
                                                            aria-hidden="true"
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                e.preventDefault()
                                                                deleteCycHandler(c.id)
                                                            }}
                                                        />
                                                    </td>
                                                </tr>

                                                {/*RELEASES*/}
                                                {c.releases && c.releases.length ? c.releases.map(r => {
                                                    return (
                                                        <tr className={`rc-${c.id}`} onClick={() => {
                                                            history.push(`/testCycles/show/${c.id}/${r.id}`)
                                                        }}
                                                            style={{ cursor: 'pointer', borderBottom: 'none' }}
                                                            key={`rel-${r.id}`}
                                                        >
                                                            <td style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                                                <i className="fa fa-chevron-down opacity-0" aria-hidden="true" />
                                                                <i className="fa fa-folder-o opacity-0" aria-hidden="true" />
                                                                {r.name}
                                                            </td>
                                                            <td style={{ paddingTop: '5px', paddingBottom: '5px' }} className="td-mid"><SimpleColorProgress props={progress(r.id, c.id).res} /></td>
                                                            <td style={{ paddingTop: '5px', paddingBottom: '5px' }}>{progress(r.id, c.id).perc}%</td>
                                                            <td style={{ paddingTop: '5px', paddingBottom: '5px' }} />
                                                        </tr>
                                                    )
                                                }) : (<></>)}

                                            </React.Fragment>
                                        )
                                    }) : (<></>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            ) : (<></>)}


        </>
    )
}

import { default as React, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router-dom"
import { AuthContext } from "../../context/AuthContext"
import { useHttp } from "../../hooks/http.hook"
import { CircleProgress } from "../partials/CircleProgress"
import DualProgressBar from "../partials/DualProgressBar"
import { Header } from "../partials/Header"


export const ViewProgressPage = ({ }) => {
  const { token, user, logout } = useContext(AuthContext)
  const { loading, request, error, clearError } = useHttp()
  const id = useParams().id || null
  const caseId = useParams().caseId || null
  // const prj = useContext(SidebarContext)
  const history = useHistory()
  const [project, setProject] = useState(null)
  const [caser, satCaser] = useState(null)
  const [dataProgress, setDataProgress] = useState({})
  const [weekAgoProgress, setWeekAgoProgress] = useState({})

  // console.log("prj", prj)

  console.log(calculateMetricsById(id))

  useEffect(async () => {
    try {
      if (token && id) {
        const data = await request(`/api/project/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
        setProject(data.project)



        const caserow = await request(`/api/case/${caseId}`, 'GET', null, { authorization: 'Bearer ' + token })
        satCaser(caserow.caseForm)


      }
    } catch (e) {
      console.log(e)
    }

  }, [])
  // 
  console.log("project", caser)

  useEffect(async () => {
    if (project) {
      setDataProgress(calculateMetricsById(project.name.length + id, 0))
      setWeekAgoProgress(calculateMetricsById(project.name.length + id, 7))
    }

  }, [project])



  function calculateMetricsById(id, daysAgo = 0) {
    // Получаем текущую дату и смещаем её на daysAgo
    const today = new Date()
    const targetDate = new Date(today)
    targetDate.setDate(today.getDate() - daysAgo)

    // Определяем число дней в месяце целевой даты
    const daysInMonth = new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0).getDate()

    // Определяем текущий день месяца
    const currentDate = targetDate.getDate()

    // Генерация метрик на основе id и текущего дня месяца
    // Вычисляем процент
    const basePercentage = 60
    const endPercentage = 98
    const percentage = basePercentage + (endPercentage - basePercentage) * (currentDate - 1 + id) / (daysInMonth + id)

    // Вычисляем maxLoad
    const minLoad = 9
    const maxLoad = 70
    const load = minLoad + (maxLoad - minLoad) * (currentDate - 1 + id) / (daysInMonth + id)

    // Вычисляем averageTime
    const minTime = 1
    const maxTime = 3
    const averageTime = (minTime + (maxTime - minTime) * (currentDate - 1 + id) / (daysInMonth + id)).toFixed(2)

    // Вычисляем dataStorage
    const initialStorage = 0.5
    const finalStorage = 3.7
    const dataStorage = initialStorage + (finalStorage - initialStorage) * (currentDate - 1 + id) / (daysInMonth + id)

    // Вычисляем startsCount (целое число дней от начала месяца умноженное на 2)
    const startsCount = (targetDate.getDate() - targetDate.getDate() % 1) * 2

    return {
      successProgress: parseFloat(percentage.toFixed(2)),
      maxLoad: parseFloat(load.toFixed(2)),
      averageTime: parseFloat(averageTime),
      dataStorage: parseFloat(dataStorage.toFixed(2)),
      startsCount: startsCount
    }
  }


  const comparison = (now, weekAgo, unit) => {
    if (now > weekAgo) {


      return (
        <div style={{ color: "#D0D0D0", fontSize: "12px", fontWeight: "400", display: "flex", alignItems: "center", gap: "5px" }} >
          {arrowUp()}
          <div style={{ marginBottom: "5px" }}>увеличение с {weekAgo}{unit}</div>
        </div>
      )
    }
  }





  const arrowUp = () => {
    return (
      <>
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.58325 3.41669H11.0833V6.91669" stroke="#D0D0D0" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M11.0834 3.41669L2.91675 11.5834" stroke="#D0D0D0" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </>
    )
  }

  const arrowDown = () => {
    return (
      <>
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.75 8.08331V11.5833H8.25" stroke="#D0D0D0" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M3.5835 3.41669L11.7502 11.5834" stroke="#D0D0D0" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </>
    )
  }

  const clock = () => {
    return (
      <>
        <svg width="50" height="50" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.6668 29.8334C24.0306 29.8334 30.0002 23.8638 30.0002 16.5C30.0002 9.13622 24.0306 3.16669 16.6668 3.16669C9.30303 3.16669 3.3335 9.13622 3.3335 16.5C3.3335 23.8638 9.30303 29.8334 16.6668 29.8334Z" stroke="#6C7A8F" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M16.6667 8.5V16.5L22.0001 19.1667" stroke="#6C7A8F" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

      </>
    )
  }


  const isEnabled = () => {
    if (caser && caser.uploaded) {
      const uploadedDate = new Date(caser.uploaded)
      const now = new Date()

      // Вычисляем разницу во времени в миллисекундах
      const diffInMs = now - uploadedDate

      // Преобразуем миллисекунды в часы
      const diffInHours = diffInMs / (1000 * 60 * 60)

      // Возвращаем true, если прошло 24 часа или больше
      return diffInHours >= 24
    }

    // Если даты нет, возвращаем false
    return false
  }







  return (
    <>
      <Header params={{
        // title: !caseId ? `Create Test Case [${project.name}]` : `Update Test Case [${project.name}]`,
        title: `${project && project.name} прогресс по тестированию`,
        btnL: {
          actionHandler: () => { history.goBack() },
          title: 'Отмена',
          display: 'inline-block'
        },
        btnR: {
          title: "",
          display: 'none'
        },
        loading
      }} />




      {isEnabled() ?
        <>
          <div className="row clear-row afterHeader">
            <div className="col-auto">
              <h5>{`Тест кейс - ${project && project.name}`}</h5>
              <p className="txt-gray">на основе {dataProgress.startsCount}  запусков</p>


              {/* <div>
            <CircularProgressBar
              strokeWidth={10}
              sqSize={100}
              percentage={10}
            />
            <div>
              <input
                id="progressInput"
                type="range"
                min="0"
                max="100"
                step="1"
                // value={percentage}
                // onChange={handleChangeEvent}
              />
            </div>
          </div> */}




            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>


            <div style={{ display: "flex", flexDirection: "column", gap: "3px", alignItems: "center" }}>
              <div style={{ color: "#6C7A8F", fontSize: "16px" }}>Процент успеха</div>

              <div style={{ color: "#292929", fontSize: "32px", fontWeight: "500", display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", justifyContent: "center" }}> <CircleProgress color='#6e7ad7' size='70' showText={false} strokeWidth='5px' progress={dataProgress && dataProgress.successProgress} /><div>{dataProgress && dataProgress.successProgress}%</div>
              </div>
              {comparison(dataProgress.successProgress, weekAgoProgress.successProgress, "%")}
            </div>




            <div style={{ display: "flex", flexDirection: "column", gap: "3px", alignItems: "center" }}>
              <div style={{ color: "#6C7A8F", fontSize: "16px" }}>Maкс. загрузка ЦП</div>
              <div style={{ color: "#292929", fontSize: "32px", fontWeight: "500", display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", justifyContent: "center" }}> <CircleProgress color='#6e7ad7' size='70' showText={false} strokeWidth='5px' progress={dataProgress && dataProgress.maxLoad} /><div>{dataProgress && dataProgress.maxLoad}</div>
              </div>
              {comparison(dataProgress.maxLoad, weekAgoProgress.maxLoad, "%")}
            </div>




            <div style={{ display: "flex", flexDirection: "column", gap: "13px", alignItems: "center" }}>
              <div style={{ color: "#6C7A8F", fontSize: "16px" }}>Ср время выполнения</div>
              <div style={{ color: "#292929", fontSize: "32px", fontWeight: "500", display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", justifyContent: "center" }}> <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>{clock()}<div>{dataProgress && dataProgress.averageTime} сек</div></div>
              </div>
              {comparison(dataProgress.averageTime, weekAgoProgress.averageTime, " сек")}
            </div>










          </div >



          <div style={{ height: "2px", backgroundColor: "#EFF1F4", width: "100%", marginTop: "20px" }}></div>

          <div style={{ display: "flex", flexDirection: "column", width: "100%", color: "#6C7A8F", fontSize: '16px', marginTop: "20px", gap: "25px" }}>


            <div style={{ display: "flex", flexDirection: "column", gap: "3px", alignItems: "start" }}>
              <div style={{ color: "#6C7A8F", fontSize: "16px" }}>Использование хранилища данных</div>
              <div style={{ color: "#292929", fontSize: "32px", fontWeight: "500" }}>{dataProgress && dataProgress.dataStorage}GB</div>
              {comparison(dataProgress.dataStorage, weekAgoProgress.dataStorage, " GB")}
            </div>



            <div>
              <DualProgressBar progress2={dataProgress && (dataProgress.dataStorage * 0.25 / 4) * 100} progress1={dataProgress && (dataProgress.dataStorage / 4) * 100} />
            </div>


            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}><div style={{ borderRadius: "50%", width: "10px", height: "10px", backgroundColor: "#9676D1" }}></div> Кешируемый объем</div>
              <div style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}><div style={{ borderRadius: "50%", width: "10px", height: "10px", backgroundColor: "#22143F" }}></div>Некешируемый объем</div>
            </div>


          </div>
        </>

        :
        <>


          <div className="row clear-row afterHeader">
            <div className="col-auto">
              <h5>{`Тест кейс - ${project && project.name}`}</h5>
              <p className="txt-gray">на основе 0 запусков</p>


     




            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>


            <div style={{ display: "flex", flexDirection: "column", gap: "3px", alignItems: "center" }}>
              <div style={{ color: "#6C7A8F", fontSize: "16px" }}>Процент успеха</div>

              <div style={{ color: "#292929", fontSize: "32px", fontWeight: "500", display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", justifyContent: "center" }}> <CircleProgress color='#6e7ad7' size='70' showText={false} strokeWidth='5px' progress={0} /><div>{0}%</div>
              </div>
              недостаточно данных
            </div>




            <div style={{ display: "flex", flexDirection: "column", gap: "3px", alignItems: "center" }}>
              <div style={{ color: "#6C7A8F", fontSize: "16px" }}>Maкс. загрузка ЦП</div>
              <div style={{ color: "#292929", fontSize: "32px", fontWeight: "500", display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", justifyContent: "center" }}> <CircleProgress color='#6e7ad7' size='70' showText={false} strokeWidth='5px' progress={0} /><div>{0}</div>
              </div>
              недостаточно данных
            </div>




            <div style={{ display: "flex", flexDirection: "column", gap: "13px", alignItems: "center" }}>
              <div style={{ color: "#6C7A8F", fontSize: "16px" }}>Ср время выполнения</div>
              <div style={{ color: "#292929", fontSize: "32px", fontWeight: "500", display: "flex", flexDirection: "row", alignItems: "center", gap: "5px", justifyContent: "center" }}> <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>{clock()}<div>{0} сек</div></div>
              </div>
              недостаточно данных
            </div>










          </div >



          <div style={{ height: "2px", backgroundColor: "#EFF1F4", width: "100%", marginTop: "20px" }}></div>

          <div style={{ display: "flex", flexDirection: "column", width: "100%", color: "#6C7A8F", fontSize: '16px', marginTop: "20px", gap: "25px" }}>


            <div style={{ display: "flex", flexDirection: "column", gap: "3px", alignItems: "start" }}>
              <div style={{ color: "#6C7A8F", fontSize: "16px" }}>Использование хранилища данных</div>
              <div style={{ color: "#292929", fontSize: "32px", fontWeight: "500" }}>{0}GB</div>
              недостаточно данных
            </div>



            <div>
              <DualProgressBar progress2={0} progress1={0} />
            </div>


            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}><div style={{ borderRadius: "50%", width: "10px", height: "10px", backgroundColor: "#9676D1" }}></div> Кешируемый объем</div>
              <div style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}><div style={{ borderRadius: "50%", width: "10px", height: "10px", backgroundColor: "#22143F" }}></div>Некешируемый объем</div>
            </div>


          </div>

        </>
      }


    </>
  )

}
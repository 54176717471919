import React, { useContext, useEffect, useState, useCallback } from 'react'
import { AuthContext } from "../../context/AuthContext"
import { useHttp } from "../../hooks/http.hook"
import { Loader } from "../partials/Loader"
import { useMessage } from "../../hooks/message.hook"
import { useHistory } from "react-router-dom"
import { SidebarContext } from "../../context/SidebarContext"

export const MembersPage = () => {
    const { token, logout } = useContext(AuthContext)
    const message = useMessage()
    const [users, setUsers] = useState(null)
    const [filtered, setFiltered] = useState([])
    const { loading, request, error, clearError } = useHttp()
    const [rows, setRows] = useState([])
    const history = useHistory()
    const prj = useContext(SidebarContext)

    // let filtered = []

    let member = {
        id: Date.now(),
        name: '',
        email: '',
        password: ''
    }

    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    useEffect(() => {
        // Активация input для materialize
        window.M.updateTextFields()

        // Инициализация modal
        let el = document.querySelector('.modal')
        window.M.Modal.init(el)

    }, [loading])

    useEffect(() => {
        window.M.updateTextFields()
    }, [rows])

    // get users data
    const fetchUsers = useCallback(async () => {
        try {
            const data = await request(`/api/auth/users`, 'POST', null, { authorization: 'Bearer ' + token })
            if (data.users) {
                setUsers(data.users)
                setFiltered(data.users)
                // console.log(data.users)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request, setUsers])

    useEffect(() => {
        fetchUsers()
        prj.toggle(true, null)
    }, [fetchUsers])

    useEffect(() => {
        if (users) {
            window.M.FormSelect.init(document.querySelectorAll('select'), { classes: 'black-text' })
            // console.log(users)
        }
    })

    const addMemberHandler = () => {
        setRows([...rows, { ...member }])
    }

    const saveMembersHandler = async () => {
        // console.log('save')
        try {
            if (rows.length) {
                for (let i = 0; i < rows.length; i++) {
                    try {
                        const data = await request('/api/auth/register', 'POST', { ...rows[i] })
                        message(`${rows[i].email}: ${data.message}`)
                    } catch (e) {
                        console.log(e)
                        continue
                    }
                }
                fetchUsers()
            }
        } catch (e) { }
    }

    const changeHandler = (event, id) => {
        let idx = rows.findIndex(el => el.id === id)
        rows[idx][event.target.name] = event.target.value
    }

    const searchHandler = (e) => {
        let search = e.target.value
        if (search) {
            setFiltered(users.filter(el => el.name.toLowerCase().includes(search.toLowerCase()) || el.email.toLowerCase().includes(search.toLowerCase())))
        } else {
            setFiltered([...users])
        }
    }
    const changeRoleHandler = async (e, userId) => {
        try {
            const data = await request('/api/auth/updateRole', 'POST', { userId, role: e.target.value }, { authorization: 'Bearer ' + token })
            message(data.message)

            fetchUsers()
        } catch (e) {
            console.log(e)
        }
    }

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <div className="row clear-row mt-noHeader">
                <div className="col-auto">
                    <h5>Участники</h5>
                    <p className="txt-gray">Управление доступом к рабочей области</p>
                </div>
            </div>

            <div className="row clear-row flex-row">
                <div className="col s12 pl-0 ml-0 clear-row justify-between align-center">
                    <div className="input-field">
                        <input
                            // placeholder="Search by name or email"
                            type="text"
                            id="search"
                            className=""
                            style={{ width: '30vw' }}
                            name="search"
                            // value={form.email}
                            autoComplete="off"
                            onChange={searchHandler}
                        />
                        <label htmlFor="search">Поиск по имени или по электронной почте</label>
                    </div>

                    <button
                        data-target="modal1"
                        className="waves-effect waves-light btn blue lighten-1 noUpper modal-trigger"
                        // onClick={updateHandler}
                        disabled={loading}
                    >
                        Add участников
                    </button>
                </div>
            </div>

            <div className="row clear-row flex-row">
                <div className="col s12 mt-0 ml-0 pl-0 clear-row justify-around align-center">
                    <table className="highlight">
                        <thead>
                            <tr>
                                <th>Имя</th>
                                <th>Электронная почта</th>
                                <th>Роль</th>
                            </tr>
                        </thead>

                        <tbody>
                            {filtered && filtered.length ? filtered.map(usr => {
                                return (
                                    <tr
                                        key={usr.id}
                                    // onClick={() => {history.push('/blog/' + post._id)}}
                                    >
                                        <td className="py-0 my-0">{usr.name}</td>
                                        <td className="py-0 my-0">{usr.email}</td>
                                        {/*<td className="center">{usr.roles[0].role}</td>*/}
                                        <td className="py-0 my-0 maw-50">
                                            <div className="input-field my-0 mb0">
                                                <select
                                                    id="role"
                                                    name="role"
                                                    value={usr.roles[0].id}
                                                    // defaultValue={usr.roles[0].role}
                                                    onChange={(e) => { changeRoleHandler(e, usr.id) }}
                                                >
                                                    <option value="1">Администратор</option>
                                                    <option value="2">Пользователь</option>
                                                </select>
                                                {/*<label htmlFor="role">Role</label>*/}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) : (<tr><td colSpan="3" className="center">Пока нет пользователей</td></tr>)}
                        </tbody>
                    </table>
                </div>
            </div>

            <div id="modal1" className="modal">
                <div className="modal-content">
                    <div className="row clear-row flex-row justify-between mb-0">
                        <div className="col">
                            <h4>Add members</h4>
                        </div>
                        <div className="col">
                            <button className="modal-close waves-effect waves-green btn-flat txt-gray"><i className="fa fa-times" aria-hidden="true" /></button>
                        </div>
                    </div>
                    <p className="txt-gray mt-0 mb-1">Создание учетных записей и приглашение пользователей</p>

                    {rows.length ? rows.map(row => {
                        return (
                            <div className="row clear-row flex-row justify-between" key={row.id}>
                                <div className="col s4 center my-0">
                                    <div className="input-field">
                                        <input
                                            // placeholder="Введите email"
                                            type="text"
                                            id={`email-${row.id}`}
                                            className=""
                                            name="email"
                                            // value={row.email}
                                            onChange={(e) => { changeHandler(e, row.id) }}
                                            autoComplete="off"
                                        />
                                        <label htmlFor={`email-${row.id}`}>Электронная почта</label>
                                    </div>
                                </div>

                                <div className="col s4 center my-0">
                                    <div className="input-field">
                                        <input
                                            // placeholder="User name"
                                            type="text"
                                            id={`name-${row.id}`}
                                            className=""
                                            name="name"
                                            // value={form.name}
                                            onChange={(e) => { changeHandler(e, row.id) }}
                                            autoComplete="off"
                                        />
                                        <label htmlFor={`name-${row.id}`}>Имя пользователя</label>
                                    </div>
                                </div>

                                <div className="col s4 center my-0">
                                    <div className="input-field">
                                        <input
                                            // placeholder="Password"
                                            type="password"
                                            id={`password-${row.id}`}
                                            className=""
                                            name="password"
                                            // value={form.newPassword}
                                            onChange={(e) => { changeHandler(e, row.id) }}
                                            autoComplete="off"
                                        />
                                        <label htmlFor={`password-${row.id}`}>Пароль</label>
                                    </div>
                                </div>
                            </div>
                        )
                    }) : (<div className="row clear-row flex-row justify-between mb-0">
                        <div className="col s12 center">
                            <div>
                                <ul>
                                    <li className="center" onClick={addMemberHandler} style={{ cursor: 'pointer' }}>
                                        <a
                                            href="#!"
                                            className="txt-gray"
                                        >
                                            <i className="fa fa-plus" aria-hidden="true" /> Добавить еще
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>)
                    }

                </div>
                <div className="modal-footer overflowHidden">
                    <div className="row clear-row justify-between mb-0 ml-0 mr-0">
                        <div className="col s5 ml-0">
                            <div>
                                <ul>
                                    <li className="left">
                                        <a
                                            href="#!"
                                            onClick={addMemberHandler}
                                            className="txt-gray"
                                        >
                                            <i className="fa fa-plus" aria-hidden="true" /> Добавить еще
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col s5 mr-0">
                            <button
                                className="waves-effect waves-light btn blue lighten-1 noUpper modal-close"
                                onClick={saveMembersHandler}
                            // disabled={loading}
                            >
                                Добавить участников
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
import M from 'materialize-css'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from "react-router-dom"
import { AuthContext } from "../../context/AuthContext"
import { SidebarContext } from "../../context/SidebarContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"
import { Header } from "../partials/Header"
import { Loader } from "../partials/Loader"


export const TestCaseForm = ({ caseId }) => {
    const { token, user, logout } = useContext(AuthContext)
    const { loading, request, error, clearError, fileUpload } = useHttp()
    const id = useParams().id || null
    const prj = useContext(SidebarContext)
    const [project, setProject] = useState(null)
    const [projects, setProjects] = useState(null)
    const [labels, setLabels] = useState(null)
    // const [cycles, setCycles] = useState(null)
    // const [releases, setReleases] = useState(null)
    const [execRows, setExecRows] = useState([])
    const [users, setUsers] = useState(null)
    const message = useMessage()
    const history = useHistory()
    const details = useRef(null)
    const steps = useRef(null)
    const [allVariables, setAllVariables] = useState([])
    const [selectedVar, setSelectedVar] = useState(null)
    const [usedInProjectVariables, setUsedInProjectVariables] = useState([])
    const inputFile = useRef(null)
    const modalRef = useRef(null)
    const [isOpenModalVar, setIsOpenModalVar] = useState(false);



    const [caseForm, setCaseForm] = useState({
        id: -Date.now(),
        projectId: 0,
        userId: 0,
        cycleId: 0,
        title: '',
        description: '',
        status: 'To Do',
        priority: 'Normal',
        component: 'Component1',
        estimate: 'Estimate1',
        executorType: 'Manual',

        assignee: [],
        labels: [],
        usedVariables: [],
        // cycle: 0,
        release: 0,
    })

    const [stepRows, setStepRows] = useState([])
    let step = {
        id: -Date.now(),
        caseId: 0,
        step: '',
        data: '',
        result: ''
    }



    useEffect(async () => {
        const allVariables = await request(`/api/variable/all`, 'POST', { projectId: id }, { authorization: 'Bearer ' + token })
        setAllVariables(allVariables.variables)
        const allVariablesUsedInProject = await request(`/api/variable/usedInProject`, 'POST', { projectId: id }, { authorization: 'Bearer ' + token })
        setUsedInProjectVariables(allVariablesUsedInProject.variables)
        setCaseForm({ ...caseForm, usedVariables: allVariablesUsedInProject.variables })
    }, [])


    console.log("allVariables", allVariables)



    // logout + error
    useEffect(() => {
        message(error)

        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    // Активация input для materialize
    useEffect(() => {
        // if (modalRef.current) {

        // }
        try {
            if (!loading) {
                M.Modal.init(modalRef.current)
                window.M.updateTextFields()
                window.M.FormSelect.init(document.querySelectorAll('select'))
                let ta = document.querySelectorAll('textarea')
                if (ta.NodeList && ta.NodeList.length) {
                    window.M.textareaAutoResize(ta)
                }
            }
        } catch (e) {
            console.log(e)
        }
    })

    const getProject = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/project/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
                setProject(data.project)
                // setCycles(data.cycles)
                // setReleases(data.releases)
                // if (data.cycles.length && data.releases.length) {
                //     setCaseForm({...caseForm, cycleId: data.cycles[0].id, release: data.releases[0].id})
                // }
                // console.log(data)
            }
            if (token) {
                const data = await request(`/api/project/projects`, 'POST', null, { authorization: 'Bearer ' + token })
                console.log(data.projects)
                setProjects(data.projects)
                setProject(data.projects[0])
                if (!caseId) {
                    setCaseForm(prev => { return { ...prev, projectId: data.projects[0].id } })
                }

                // setCycles(data.cycles)
                // setReleases(data.releases)
                // if (data.cycles.length && data.releases.length) {
                //     setCaseForm({...caseForm, cycleId: data.cycles[0].id, release: data.releases[0].id})
                // }
                // console.log(data)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    const getLabels = useCallback(async (token, id) => {
        try {
            if (token) {
                const data = await request(`/api/label/all`, 'POST', { projectId: id }, { authorization: 'Bearer ' + token })
                setLabels(data.label)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    // get users data
    const fetchUsers = useCallback(async () => {
        try {
            const data = await request(`/api/auth/users`, 'POST', null, { authorization: 'Bearer ' + token })
            if (data.users) {
                setUsers(data.users)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request, setUsers])

    // if caseId !== 0 this is EDIT, not create.
    // Then get case by id and labels + steps + executions and set same variables
    const getCase = useCallback(async () => {
        try {
            // get case by id (with labels, steps, cycles, releases
            const data = await request(`/api/case/${caseId}`, 'GET', null, { authorization: 'Bearer ' + token })
            // setCaseForm
            setCaseForm(prev => { return { ...prev, ...data.caseForm } })
            // setStepRows
            if (data.stepRows.length) {
                setStepRows([...data.stepRows])
            }
            console.log('execs', data.execRows)
            // setExecRows
            if (data.execRows.length) {
                setExecRows([...data.execRows])
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    // load project + label + users
    useEffect(async () => {
        try {
            // if (id) {
            //     prj.toggle(true, id)
            // }
            // await getLabels(token, id)
            await getProject(token, id)
            await fetchUsers()
            if (user && user.id) {
                if (id) {
                    setCaseForm(prev => { return { ...prev, userId: user.id, projectId: Number(id) } })
                } else {
                    setCaseForm(prev => { return { ...prev, userId: user.id } })
                }
            }
            if (caseId) {
                await getCase()
            }
        } catch (e) {
            console.log(e)
        }
    }, [getLabels, getProject, caseId, getCase])

    useEffect(() => {
        try {
            if (caseForm.projectId) getLabels(token, caseForm.projectId)
        } catch (e) {
            console.log(e)
        }
    }, [caseForm.projectId])

    // STEPS Start
    const addStepHandler = () => {
        setStepRows([...stepRows, { ...step }])
    }

    // Will saved when save test case

    // const saveStepsHandler = async () => {
    //     // console.log('save')
    //     try {
    //         if (stepRows.length) {
    //             for (let i = 0; i < stepRows.length; i++ ) {
    //                 try {
    //                     // const data = await request('/api/auth/register', 'POST', {...stepRows[i]})
    //                     // message(`${stepRows[i].email}: ${data.message}`)
    //                 } catch (e) {
    //                     console.log(e)
    //                     continue
    //                 }
    //             }
    //             // fetchUsers()
    //         }
    //     } catch (e) {}
    // }

    const changeStepHandler = (event, id) => {
        let idx = stepRows.findIndex(el => el.id === id)
        stepRows[idx][event.target.name] = event.target.value
        setStepRows([...stepRows])
    }

    const deleteStepHandler = async (r) => {
        try {
            if (r && r.id > 0) {
                const data = await request(`/api/step/${r.id}`, 'DELETE', null, { authorization: 'Bearer ' + token })
                message(data.message)
            }
            setStepRows([...stepRows.filter(el => el.id !== r.id)])
        } catch (e) { }
    }
    // STEPS End

    // HERE WILL TOTAL SAVE
    const createTestCase = async () => {
        // if caseForm.id > 0 update else create!!!
        if (caseId) {
            // UPDATE
            console.log('UPDATE', caseId)
            // Update test case

            // link labels to caseId
            // create, update and link steps
            // const data = await request(`/api/case/${caseId}`, 'PUT', {caseForm, stepRows, execRows}, {authorization: 'Bearer ' + token})
            const data = await request(`/api/case/${caseId}`, 'PUT', { caseForm, stepRows }, { authorization: 'Bearer ' + token })
            message(data.message)

            history.goBack()
        } else {
            // CREATE
            try {
                // console.group('CREATE')
                // console.log('caseForm', caseForm)
                // console.log('stepRows', stepRows)
                // console.groupEnd()
                // Create test case and get its id

                // const data = await request(`/api/case/create`, 'POST', {caseForm, stepRows, execRows}, {authorization: 'Bearer ' + token})
                const data = await request(`/api/case/create`, 'POST', { caseForm, stepRows }, { authorization: 'Bearer ' + token })
                message(data.message)

                history.goBack()
            } catch (e) {
                console.log(e)
            }
        }
    }
    // HERE WILL TOTAL SAVE

    const changeHandler = event => {
        if (event.target.name === 'labels' || event.target.name === 'assignee') {
            let value = Array.from(event.target.selectedOptions, option => Number(option.value))
            setCaseForm({ ...caseForm, [event.target.name]: value })
        } else {
            setCaseForm({ ...caseForm, [event.target.name]: event.target.value })
        }
        // if (projects && projects.length && caseForm.projectId === 0) {
        //     setCaseForm({ ...caseForm, projectId: projects[0].id })
        // }
    }

    // const varChangeHandler = event => {
    //     console.log(event.target.value)
    //     event.preventDefault()
    //     setSelectedVar(allVariables.find(variable => variable.id === +event.target.value))
    //     setCaseForm({ ...caseForm, caseForm: [...caseForm.usedVariables, event] })
    // }

    // console.log(selectedVar)
    // console.log(caseForm)











    const fileUploadHendler = async (event, caseId) => {
        console.log("caseId", caseId)
        try {
            const fd = new FormData()
            fd.append('file', event.target.files[0])

            const data = await fileUpload('/api/case/uploadTestScenario', 'POST', fd, { authorization: 'Bearer ' + token, id: caseId })

            // setForm({ ...form, img: `/assets/caseScenario/${caseId.id}.txt?v=${Date.now()}` })
            message(data.message)
        } catch (e) {
            console.log(e)
        }
    }

    const fileDownloadHendler = async (caseId) => {
        console.log("caseId", caseId)
        try {
            const response = await fetch(`/api/case/downloadScenario/${caseId}`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })

            if (response.ok) {
                const blob = await response.blob()
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = `${caseId}.log`
                document.body.appendChild(a)
                a.click()
                a.remove()
                message('Файл успешно загружен')
            } else {
                const data = await response.json()
                message(data.message)
            }
        } catch (e) {
            console.log(e)
        }
    }












    const varChangeHandler = event => {
        setCaseForm(prevCaseForm => {
            const isExisting = prevCaseForm.usedVariables.some(variable => variable.id === +event.target.value)

            if (!isExisting) {
                return {
                    ...prevCaseForm,
                    usedVariables: [...prevCaseForm.usedVariables, allVariables.find(variable => variable.id === +event.target.value)]
                }
            } else {
                return prevCaseForm
            }
        })
    }


    const changeExecHandler = async (event, execId) => {
        try {
            let idx = execRows.findIndex(el => el.id === execId)
            execRows[idx].status = event.target.value

            const data = await request(`/api/exec/${execId}`, 'PUT', { status: event.target.value, userId: user.id }, { authorization: 'Bearer ' + token })
            message(data.message)

            setExecRows([...execRows])
        } catch (e) {
            console.log(e)
        }
    }

    // if (!prj.id || !project || !labels || loading) {
    if (!project || !labels || loading) {
        return <Loader />
    }

    const hideHandler = () => {
        details.current.classList.toggle('d-none')
    }

    const hideStepsHandler = () => {
        steps.current.classList.toggle('d-none')
    }



    const deleteVariableHandler = async (variable) => {
        console.log(variable)
        setCaseForm({ ...caseForm, usedVariables: caseForm.usedVariables.filter(v => v.id !== variable.id) })
    }

    // setCaseForm(caseForm.va)

    return (
        <>
            <Header params={{
                // title: !caseId ? `Create Test Case [${project.name}]` : `Update Test Case [${project.name}]`,
                title: !caseId ? `Создание тест кейса` : `Изменение тест кейса`,
                btnL: {
                    actionHandler: () => { history.goBack() },
                    title: 'Отмена',
                    display: 'inline-block'
                },
                btnR: {
                    actionHandler: createTestCase,
                    title: !caseId ? 'Создать тест кейс' : `Изменить тест кейс`,
                    display: 'inline-block'
                },
                loading
            }} />

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    <h5>{!caseId ? 'Создать тест кейс' : `Изменить тест кейс`}</h5>
                    {/*<p className="txt-gray">Manage your project settings</p>*/}
                </div>
            </div>

            {/*FORM*/}
            {/*TITLE*/}
            <div className="row clear-row flex-row">
                <div className="col s5 mt-0 ml-0 pl-0">
                    <div className="input-field">
                        <input
                            type="text"
                            id="title"
                            className=""
                            name="title"
                            autoComplete="off"
                            value={caseForm.title}
                            onChange={changeHandler}
                        />
                        <label htmlFor="name">Заголовок тест кейса</label>
                    </div>
                </div>

                <div className="col s1 mt-0 ml-0 pl-0">
                </div>

                <div className="col s5 mt-0 ml-0 pl-0">
                    <div className="input-field">
                        <input
                            type="text"
                            id="description"
                            className=""
                            name="description"
                            autoComplete="off"
                            value={caseForm.description}
                            onChange={changeHandler}
                        />
                        <label htmlFor="description">Описание</label>
                    </div>
                </div>
            </div>

            {/*DETAILS*/}
            <div className="row clear-row">
                <div className="col-auto">
                    <p style={{ fontSize: '1.2rem', cursor: 'pointer' }} onClick={hideHandler}><i className="fa fa-folder-o" aria-hidden="true" /> Подробности</p>
                </div>
            </div>

            <div className="row clear-row flex-row" ref={details}>
                <div className="col s5 mt-0 ml-0 pl-0">
                    {/*STATUS*/}
                    <div className="input-field">
                        <select
                            id="status"
                            name="status"
                            value={caseForm.status}
                            // defaultValue={usr.roles[0].role}
                            onChange={changeHandler}
                        // Здесь на onChange надо сохранять где-то значения, а на onBlur делать setCaseForm
                        // т.к. setCaseForm закрывает select
                        >
                            <option value="To Do">Требуется выполнение</option>
                            <option value="Ready">Готово</option>
                            <option value="In Progress">Выполняется</option>
                        </select>
                        <label htmlFor="status">Статус</label>
                    </div>

                    {/*PRIORITY*/}
                    <div className="input-field">
                        <select
                            id="priority"
                            name="priority"
                            // defaultValue={usr.roles[0].role}
                            value={caseForm.priority}
                            onChange={changeHandler}
                        >
                            <option value="Normal">Нормальный</option>
                            <option value="High">Высокий</option>
                        </select>
                        <label htmlFor="priority">Приоритет</label>
                    </div>

                    {/*ASSIGNEE - MEMBERS assigned to case, make it like labels*/}
                    <div className="input-field">
                        <select
                            id="assignee"
                            name="assignee"
                            // defaultValue={usr.roles[0].role}
                            multiple={true}
                            value={caseForm.assignee}
                            onChange={changeHandler}
                        >
                            {users ? users.map(usr => {
                                return (
                                    <option value={usr.id} key={usr.id}>{usr.name ? usr.name : usr.email}</option>
                                )
                            }) : (<option value=""></option>)}

                        </select>
                        <label htmlFor="assignee">Проверяющий</label>
                    </div>

                    {/*LABELS*/}
                    <div className="input-field">
                        <select
                            id="labels"
                            name="labels"
                            // defaultValue={usr.roles[0].role}
                            multiple={true}
                            value={caseForm.labels}
                            onChange={changeHandler}
                        >
                            {labels ? labels.map(label => {
                                let inner = `<div style="display: flex; justify-content: space-around; align-items: center"><p style="display: block">${label.label}</p><div style="width: 15px; height: 15px; border-radius: 50%; background-color: ${label.color}; margin-left: 1rem"></div></div>`
                                return (
                                    <option value={label.id} key={label.id} dangerouslySetInnerHTML={{ __html: inner }}>
                                    </option>
                                )
                            }) : (<option value=""></option>)}

                        </select>
                        <label htmlFor="labels">Метки</label>
                    </div>


                    <br />

                    {/*PROJECT*/}
                    <div className="input-field">
                        <select
                            id="project"
                            name="projectId"
                            value={caseForm.projectId}
                            onChange={changeHandler}
                        >
                            {projects && projects.length ? projects.map(p => {
                                return (
                                    <option key={p.id} value={p.id}>{p.name}</option>
                                )
                            }) : (<option value=""></option>)}
                        </select>
                        <label htmlFor="project">Проект</label>
                    </div>

                    {/*/!*CYCLE*!/*/}
                    {/*<div className="input-field">*/}
                    {/*<select*/}
                    {/*id="cycle"*/}
                    {/*name="cycleId"*/}
                    {/*value={caseForm.cycleId}*/}
                    {/*onChange={changeHandler}*/}
                    {/*disabled*/}
                    {/*>*/}
                    {/*{cycles ? cycles.map(cycle => {*/}
                    {/*return (*/}
                    {/*<option value={cycle.id} key={cycle.id}>*/}
                    {/*{cycle.name}*/}
                    {/*</option>*/}
                    {/*)*/}
                    {/*}) : (<option value=""></option>)}*/}
                    {/*</select>*/}
                    {/*<label htmlFor="cycle">Test Cycle</label>*/}
                    {/*</div>*/}

                    {/*EXECUTION*/}
                    <div className="input-field">
                        <select
                            id="execType"
                            name="execType"
                            value={caseForm.execType}
                            onChange={changeHandler}
                        >
                            <option value="Manual">Ручной</option>
                            <option value="Automatic">Автоматический</option>
                        </select>
                        <label htmlFor="execType">Тип выполнения</label>
                    </div>

                </div>

                <div className="col s1 mt-0 ml-0 pl-0">
                </div>

                <div className="col s5 mt-0 ml-0 pl-0">
                    {/*COMPONENT*/}
                    <div className="input-field">
                        <input
                            placeholder="Компонент"
                            type="text"
                            id="component"
                            className=""
                            name="component"
                            autoComplete="off"
                            value={caseForm.component}
                            onChange={changeHandler}
                        />
                        <label htmlFor="component">Компонент</label>

                        {/*<select*/}
                        {/*id="component"*/}
                        {/*name="component"*/}
                        {/*value={caseForm.component}*/}
                        {/*onChange={changeHandler}*/}
                        {/*>*/}
                        {/*<option value="Component1">Component1</option>*/}
                        {/*<option value="Component2">Component2</option>*/}
                        {/*</select>*/}
                        {/*<label htmlFor="component">Component</label>*/}
                    </div>

                    {/*/!*RELEASE*!/*/}
                    {/*<div className="input-field">*/}
                    {/*<select*/}
                    {/*id="release"*/}
                    {/*name="release"*/}
                    {/*value={caseForm.release}*/}
                    {/*onChange={changeHandler}*/}
                    {/*disabled*/}
                    {/*>*/}
                    {/*{releases ? releases.map(release => {*/}
                    {/*return (*/}
                    {/*<option value={release.id} key={release.id}>*/}
                    {/*{release.name}*/}
                    {/*</option>*/}
                    {/*)*/}
                    {/*}) : (<option value=""></option>)}*/}
                    {/*</select>*/}
                    {/*<label htmlFor="release">Release</label>*/}
                    {/*</div>*/}

                    {/*ESTIMATE*/}
                    <div className="input-field">
                        <input
                            placeholder="Прогноз"
                            type="text"
                            id="estimate"
                            className=""
                            name="estimate"
                            autoComplete="off"
                            value={caseForm.estimate}
                            onChange={changeHandler}
                        />
                        <label htmlFor="estimate">Прогноз</label>

                        {/*<select*/}
                        {/*id="estimate"*/}
                        {/*name="estimate"*/}
                        {/*value={caseForm.estimate}*/}
                        {/*onChange={changeHandler}*/}
                        {/*>*/}
                        {/*<option value="Estimate1">Estimate1</option>*/}
                        {/*<option value="Estimate2">Estimate2</option>*/}
                        {/*</select>*/}
                        {/*<label htmlFor="estimate">Estimate</label>*/}
                    </div>

                    {/*USER*/}
                    <div className="input-field">
                        <p><span style={{ fontWeight: 'bold' }}>Создатель:</span> {user.name ? user.name : user.email}</p>
                    </div>
                </div>
            </div>

            {/*<hr style={{display: 'block', height: '1px', border: '0', borderTop: '1px solid #ccc'}} />*/}

            {/*STEPS*/}
            <div className="row clear-row mb-0">
                <div className="col-auto">
                    <p style={{ fontSize: '1.2rem', cursor: 'pointer' }} onClick={hideStepsHandler}><i className="fa fa-folder-o" aria-hidden="true" /> Этапы выполнения</p>
                </div>
            </div>



            <div className="row clear-row flex-row" ref={steps}>

                <div className="col s12 pl-0">
                    <table className="highlight">
                        <thead>
                            {/*<tr>*/}
                            {/*<th></th>*/}
                            {/*<th></th>*/}
                            {/*<th></th>*/}
                            {/*<th></th>*/}
                            {/*</tr>*/}
                        </thead>
                        <tbody>
                            {stepRows && stepRows.length ? stepRows.map(step => {
                                return (
                                    <tr
                                        key={step.id}
                                        // onClick={() => {history.push('/blog/' + post._id)}}
                                        className="bb-none"
                                    >
                                        <td className="center td-top py-0">
                                            <div className="input-field">
                                                <textarea
                                                    id={'step' + step.id}
                                                    name="step"
                                                    value={step.step}
                                                    onChange={(e) => { changeStepHandler(e, step.id) }}
                                                    className="materialize-textarea"
                                                />
                                                <label htmlFor={'step' + step.id}>Этап выполнения</label>
                                            </div>
                                        </td>
                                        <td className="center td-top py-0">
                                            <div className="input-field">
                                                <textarea
                                                    id={'data' + step.id}
                                                    name="data"
                                                    value={step.data}
                                                    onChange={(e) => { changeStepHandler(e, step.id) }}
                                                    className="materialize-textarea"
                                                />
                                                <label htmlFor={'data' + step.id}>Дата теста</label>
                                            </div>
                                        </td>
                                        <td className="center td-top py-0">
                                            <div className="input-field">
                                                <textarea
                                                    id={'result' + step.id}
                                                    name="result"
                                                    value={step.result}
                                                    onChange={(e) => { changeStepHandler(e, step.id) }}
                                                    className="materialize-textarea"
                                                />
                                                <label htmlFor={'result' + step.id}>Ожидаемый результат</label>
                                            </div>
                                        </td>
                                        <td className="right td-mid maw-50 ">
                                            <i
                                                onClick={() => { deleteStepHandler(step) }}
                                                style={{ cursor: 'pointer' }}
                                                className="fa fa-trash-o p-1 opacity-0"
                                                aria-hidden="true"
                                            />
                                        </td>
                                    </tr>
                                )
                            }) : (<></>)}
                        </tbody>
                    </table>

                    <ul className="py-0 mt-1" style={{ maxWidth: '120px' }}>
                        <li onClick={addStepHandler} style={{ cursor: 'pointer' }}>+ Добавить новый этап</li>
                    </ul>

                </div>

            </div>


            {/*Used variables*/}
            <div className="row clear-row flex-row">
                <div className="col s12 pl-0">

                    <span>
                        <p style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                        //  onClick={hideStepsHandler}
                        >
                            <i className="fa fa-folder-o" aria-hidden="true" />Используемые переменные</p>
                    </span>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginTop: '10px' }}>
                        {caseForm ? caseForm.usedVariables.map(prjVar => {
                            return (
                                <div value={prjVar.id} key={prjVar.id} style={{ backgroundColor: "rgba(111, 123, 215, 0.3)", padding: "0px 0px 0px 10px", borderRadius: '5px', fontSize: '14px', fontWeight: '600', border: '1px solid #E0E0E0', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                    <div style={{ marginRight: "10px" }} >{prjVar.name}</div>
                                    <div onClick={() => { deleteVariableHandler(prjVar) }} className='varhover'>
                                        X
                                    </div>

                                </div>
                            )
                        }) : (<div disabled value="No variables available"></div>)}
                    </div>


                    <ul className="py-0 mt-1" style={{ maxWidth: '180px' }}>
                        <li
                            style={{ cursor: 'pointer' }} data-target="modal1" className="modal-trigger"
                             onClick={() => { setIsOpenModalVar(true)}} >+ Добавьте существующую переменную</li>
                    </ul>


                </div>

            </div>




            <div className="row clear-row flex-column" style={{ alignContent: 'center' }}>


                <span>

                    <i className="fa fa-folder-o" aria-hidden="true" />Сценарий тест кейса
                </span>

                <div style={{ marginTop: '10px' }}>
                    <button
                        className="waves-effect waves-light btn grey lighten-3 noUpper mr-1"
                        onClick={() => {
                            inputFile.current.click()
                        }}
                        style={{ color: 'black' }}
                        disabled={loading}
                    >
                        загрузить
                        <input
                            type='file'
                            id="caseScenario"
                            ref={inputFile}
                            style={{ display: 'none' }}
                            // value={form.img}
                            onChange={(e) => { fileUploadHendler(e, caseId) }}
                            accept=".log"
                        />
                    </button>











                    <button
                        className="waves-effect waves-light btn grey lighten-3 noUpper mr-1"
                        onClick={() => { fileDownloadHendler(caseId) }}
                        style={{ color: 'black' }}
                        disabled={loading}
                    >
                        выгрузить
                    </button>
                </div>




            </div>




            {/*EXECS*/}
            <div className="row clear-row mb-0">
                <div className="col-auto">
                    <p style={{ fontSize: '1.2rem', cursor: 'pointer' }} >Выполнения теста</p>
                </div>
            </div>





            <div className="row clear-row flex-row">
                <div className="col s12 pl-0">
                    {execRows && execRows.length ? (
                        <table className="highlight">
                            <thead>
                                <tr>
                                    <th>Релиз</th>
                                    <th>Тестовый цикл</th>
                                    <th>Проект</th>
                                    <th>Статус</th>
                                    <th>Исполнитель</th>
                                    <th>Дата выполнения</th>
                                </tr>
                            </thead>
                            <tbody>
                                {execRows && execRows.length ? execRows.map(ex => {
                                    return (
                                        <tr
                                            key={ex.id}
                                            // onClick={() => {history.push('/blog/' + post._id)}}
                                            className="bb-none"
                                        >
                                            <td className="py-0">
                                                {ex.relName}
                                            </td>
                                            <td className="py-0">
                                                {ex.cycName}
                                            </td>
                                            <td className="py-0">
                                                {ex.prjName}
                                            </td>
                                            <td className="py-0">
                                                {ex.status !== 'NoExec' ? (
                                                    <div
                                                        className={`input-field py-0 bRadius-7 ${ex.status === 'Unexecuted' ? 'bgGray' : ex.status === 'Success' ? 'bgGreen' : 'bgRed'}`}>
                                                        <select
                                                            id={'status' + ex.id}
                                                            name="status"
                                                            value={ex.status}
                                                            onChange={(e) => { changeExecHandler(e, ex.id) }}
                                                        >
                                                            <option value="Unexecuted">Не выполнено</option>
                                                            <option value="Success">Успех</option>
                                                            <option value="Fail">Неудача</option>
                                                        </select>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={`input-field py-0 bRadius-7 bgGray`}>
                                                        <select
                                                            id={'status' + ex.id}
                                                            name="status"
                                                            value={ex.status}
                                                            onChange={(e) => { changeExecHandler(e, ex.id) }}
                                                            disabled
                                                        >
                                                            <option value="Unexecuted">Нет выполнений</option>
                                                            <option value="Success">Успех</option>
                                                            <option value="Fail">Неудача</option>
                                                        </select>
                                                    </div>
                                                )}
                                            </td>
                                            <td className="py-0">
                                                {ex.usrName}
                                            </td>
                                            <td className="py-0">
                                                {new Date(ex.updatedAt).toLocaleDateString()}
                                            </td>
                                            {/*<td className="right td-mid maw-50 py-0 ">*/}
                                            {/*<i*/}
                                            {/*// onClick={() => {deleteStepHandler(step)}}*/}
                                            {/*style={{cursor: 'pointer'}}*/}
                                            {/*className="fa fa-trash-o p-1 opacity-0"*/}
                                            {/*aria-hidden="true"*/}
                                            {/*/>*/}
                                            {/*</td>*/}
                                        </tr>
                                    )
                                }) : (<></>)}
                            </tbody>
                        </table>

                    ) : (<p className="txt-gray pl-0">Этот тест еще не был выполнен.</p>)}
                </div>
            </div>

            <br />

            <div className="row clear-row">
                <div className="col-auto">
                    <button
                        className="waves-effect waves-light btn grey lighten-3 noUpper mr-1"
                        onClick={() => { history.goBack() }}
                        style={{ color: 'black' }}
                        disabled={loading}
                    >
                        Отмена
                    </button>

                    <button
                        className="waves-effect waves-light btn blue lighten-1 noUpper"
                        onClick={createTestCase}
                        disabled={loading}
                    >
                        {!caseId ? 'Создать тест кейс' : `Изменить тест кейс`}
                    </button>
                </div>
            </div>


            {isOpenModalVar ? <>
                <div id="modal1" className="modal w-600 modal-fixed-height"  ref={modalRef} style={{ zIndex: 100, height: "400px", display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                    {/* Заголовок */}
                    <div className="row clear-row flex-row justify-between mb-0" style={{ borderBottom: '1px solid #eee', flexShrink: 0, position: 'relative', zIndex: 10, background: '#fff' }}>
                        <div className="col" style={{ marginTop: '20px', marginLeft: '10px', display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                            <h5 style={{ fontWeight: '600', marginTop: 0, display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                                <span style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '0px 10px' }}>
                                    Переменные проекта
                                </span>
                                <button className="modal-close waves-effect waves-green btn-flat txt-gray" style={{ marginTop: 0, marginLeft: 0 }}>
                                    <i style={{ color: '#eaeaea' }} className="fa fa-times" aria-hidden="true" onClick={() => { setIsOpenModalVar(false) }} />
                                </button>
                            </h5>
                        </div>
                    </div>

                    {/* Содержимое с прокруткой */}
                    <div className="modal-content" style={{ height: "300px", overflowY: 'auto', paddingTop: '20px', position: 'relative', marginBottom: "30px" }}>
                        <div className="input-field" style={{ width: '100%' }}>
                            {allVariables.length ? (
                                <>
                                    <select
                                        id="variableAdd"
                                        name="variableAdd"
                                        style={{ maxHeight: '200px', overflowY: 'auto' }}
                                        onChange={varChangeHandler}
                                    >
                                        <option value="" disabled selected>Choose variable</option>
                                        {allVariables.map(prjVar => (
                                            <option value={prjVar.id} key={prjVar.id}>{prjVar.name}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="variableAdd">Доступные переменные</label>
                                </>
                            ) : (
                                <div>
                                    Нет доступных переменных для этого проекта
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>

            :
                <></>
            }





        </>
    )

}
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom"
import {SidebarContext} from "../../context/SidebarContext"
import {Loader} from "../partials/Loader"
import {useHttp} from "../../hooks/http.hook"
import {AuthContext} from "../../context/AuthContext"
import {useMessage} from "../../hooks/message.hook"
import { CirclePicker } from 'react-color'

export const ProjectLabelsPage = () => {
    const { token, logout } = useContext(AuthContext)
    const {loading, request, error, clearError} = useHttp()
    const id = useParams().id
    const prj = useContext(SidebarContext)
    const [project, setProject] = useState(null)
    const message = useMessage()
    const history = useHistory()
    const [rows, setRows] = useState([])
    const [isEditing, setIsEditing] = useState(false)
    const [tRow, setTRow] = useState({})
    // const [form, setForm] = useState({
    //     name: '',
    //     slug: ''
    // })

    let label = {
        id: -Date.now(),
        projectId: 0,
        label: '',
        color: 'red',
        isEdit: true
    }

    const str2bool = (value) => {
        if (value && typeof value === "string") {
            if (value.toLowerCase() === "true") return true;
            if (value.toLowerCase() === "false") return false;
        }
        return value;
    }

    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
    })

    const getProject = useCallback(async (token, id) => {
        if (token && id) {
            const data = await request(`/api/project/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
            setProject(data.project)
            // setForm({name: data.project.name, slug: data.project.slug})
        }
    }, [])

    const getLabels = useCallback(async (token, id) => {
        if (token) {
            const data = await request(`/api/label/all`, 'POST', {projectId: id}, {authorization: 'Bearer ' + token})
            setRows(...rows, [...data.label])
            // setForm({name: data.project.name, slug: data.project.slug})
        }
    }, [])

    useEffect(() => {
        // prj.toggle(true, id)
        // get project
        getProject(token, id)
        //get all labels
        getLabels(token, id)

    }, [getLabels, getProject, id])

    const addLabelHandler = () => {
        setRows([...rows, {...label, projectId: id}])
        setIsEditing(true)
    }

    const changeHandler = (event, id) => {
        console.log(event.target.value)
        console.log(event.target.name)
        let idx = rows.findIndex(el => el.id === id)
        rows[idx][event.target.name] = str2bool(event.target.value)
        setRows([...rows])
    }

    const saveLabelHandler = async (r) => {
        // console.log('save', r)
        try {
            if (r.label.trim()) {
                if (r && r.id < 0) {
                    const data = await request('/api/label/create', 'POST', {...r}, {authorization: 'Bearer ' + token})
                    message(data.message)
                    let idx = rows.findIndex(el => el.id === r.id)
                    rows[idx].id = data.id
                    setRows([...rows])
                }
                if (r && r.id > 0) {
                    const data = await request(`/api/label/${r.id}`, 'PUT', {...r}, {authorization: 'Bearer ' + token})
                    message(data.message)
                }
            } else {
                message('Dont saved! Label must have a text!')
            }
        } catch (e) {}
        // setIsEditing(false)
    }

    const deleteLabelHandler = async(r) => {
        try {
            if (r && r.id > 0) {
                const data = await request(`/api/label/${r.id}`, 'DELETE', null, {authorization: 'Bearer ' + token})
                message(data.message)
            }
            setRows([...rows.filter(el => el.id !== r.id)])
        } catch (e) {}
    }


    if (!project || loading) {
        return <Loader />
    }

    // console.log(project)

    return (
        <>
            <div className="row clear-row mt-noHeader">
                <div className="col-auto">
                    <h5>Метки</h5>
                    <p className="txt-gray">{`Управление метками проекта "${project.name}"`}</p>
                </div>
            </div>

            <div className="row clear-row">
                <div className="col-auto w-100" style={{paddingTop: '5px'}}>
                    <hr style={{display: 'block', height: '1px', border: '0', borderTop: '1px solid #ccc'}}/>
                </div>
            </div>

            <div className="row clear-row flex-row justify-between">
                <div className="col s6 ml-0 pl-0">
                    <p className="txt-gray">Метки позволяют легко организовать ваше рабочее пространство вокруг типов задач</p>
                </div>

                <div className="col s5 ml-0 pl-0">
                    <button
                        className={`waves-effect waves-light btn blue lighten-1 noUpper right ${isEditing ? 'opacity-0' : ''}`}
                        onClick={addLabelHandler}
                        disabled={isEditing}
                    >
                        Новая метка
                    </button>
                </div>
            </div>

            <div className="row clear-row">
                <div className="col-auto w-100">
                    <table className="highlight">
                        <thead>
                        {/*<tr>*/}
                            {/*<th></th>*/}
                            {/*<th></th>*/}
                            {/*<th></th>*/}
                        {/*</tr>*/}
                        </thead>
                        <tbody>
                        {rows.length ? rows.map(row => {
                            return (
                                <tr key={row.id} style={{backgroundColor: '#fafafa', borderRadius: '3px', marginBottom: '4px'}}>
                                    <td className="center maw-50">
                                        <div style={{width: '20px', height: '20px', borderRadius: '50%', backgroundColor: row.color, margin: 'auto'}} />
                                    </td>
                                    <td>
                                        <div className="input-field my-0 edit-dis">
                                            <input
                                                placeholder="Новая метка"
                                                type="text"
                                                id="label"
                                                className=""
                                                name="label"
                                                value={row.label}
                                                onChange={(e) => {changeHandler(e, row.id)}}
                                                autoComplete="off"
                                                disabled={!row.isEdit}
                                            />
                                            {/*<label htmlFor="name">User name</label>*/}
                                        </div>
                                    </td>
                                    <td className="center">
                                        {row.isEdit ? (
                                                <>
                                                    <i
                                                        style={{cursor: 'pointer'}}
                                                        className="fa fa-check-square-o "
                                                        aria-hidden="true"
                                                        onClick={(e) => {
                                                            e.target.value = false
                                                            e.target.name = 'isEdit'
                                                            setIsEditing(false)
                                                            changeHandler(e, row.id)
                                                            saveLabelHandler(row)
                                                        }
                                                        }
                                                    />
                                                    <i
                                                        style={{cursor: 'pointer'}}
                                                        className="fa fa-times pl-1"
                                                        aria-hidden="true"
                                                        onClick={(e) => {
                                                            setIsEditing(false)
                                                            e.target.value = false
                                                            e.target.name = 'isEdit'
                                                            changeHandler(e, row.id)
                                                            let idx = rows.findIndex(el => el.id === row.id)
                                                            console.log('rows[idx]', rows[idx])
                                                            console.log('tRow', tRow)

                                                            if (Object.keys(tRow).length === 0) {
                                                                setRows([...rows.filter(el => el.id !== row.id)])
                                                            } else {
                                                                rows[idx] = tRow
                                                                setRows([...rows])
                                                            }

                                                            setTRow({})
                                                        }}
                                                    />

                                                {/*<button*/}
                                                        {/*className="waves-effect waves-light btn btn-flat grey lighten-3 noUpper mr-1"*/}
                                                        {/*style={{color: 'black'}}*/}
                                                        {/*name="isEdit"*/}
                                                        {/*value={false}*/}
                                                        {/*onClick={(e) => {*/}
                                                            {/*setIsEditing(false)*/}
                                                            {/*changeHandler(e, row.id)*/}
                                                            {/*let idx = rows.findIndex(el => el.id === row.id)*/}
                                                            {/*console.log('rows[idx]', rows[idx])*/}
                                                            {/*console.log('tRow', tRow)*/}

                                                            {/*if (Object.keys(tRow).length === 0) {*/}
                                                                {/*setRows([...rows.filter(el => el.id !== row.id)])*/}
                                                            {/*} else {*/}
                                                                {/*rows[idx] = tRow*/}
                                                                {/*setRows([...rows])*/}
                                                            {/*}*/}

                                                            {/*setTRow({})*/}
                                                        {/*}}*/}
                                                    {/*>*/}
                                                        {/*Cancel*/}
                                                    {/*</button>*/}

                                                    {/*<button*/}
                                                        {/*className="waves-effect waves-light btn btn-flat blue lighten-1 noUpper mr-1"*/}
                                                        {/*name="isEdit"*/}
                                                        {/*value={false}*/}
                                                        {/*onClick={(e) => {*/}
                                                            {/*setIsEditing(false)*/}
                                                            {/*changeHandler(e, row.id)*/}
                                                            {/*saveLabelHandler(row)*/}
                                                        {/*}}*/}
                                                        {/*disabled={loading}*/}
                                                    {/*>*/}
                                                        {/*Save*/}
                                                    {/*</button>*/}
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <i
                                                        style={{cursor: 'pointer'}}
                                                        className="fa fa-pencil-square-o opacity-0"
                                                        aria-hidden="true"
                                                        onClick={(e) => {
                                                            setIsEditing(true)
                                                            setTRow({...row})
                                                            e.target.value = true
                                                            e.target.name = 'isEdit'
                                                            changeHandler(e, row.id)
                                                        }}
                                                    />
                                                    <i
                                                        style={{cursor: 'pointer'}}
                                                        className="fa fa-trash-o pl-1 opacity-0"
                                                        aria-hidden="true"
                                                        onClick={() => {deleteLabelHandler(row)}}
                                                    />
                                                    {/*<button*/}
                                                        {/*className="waves-effect waves-light btn btn-flat noUpper mr-1"*/}
                                                        {/*style={{color: 'black'}}*/}
                                                        {/*onClick={() => {deleteLabelHandler(row)}}*/}
                                                        {/*disabled={loading}*/}
                                                    {/*>*/}
                                                        {/*Remove*/}
                                                    {/*</button>*/}

                                                    {/*<button*/}
                                                        {/*className="waves-effect waves-light btn btn-flat  noUpper mr-1"*/}
                                                        {/*name="isEdit"*/}
                                                        {/*value={true}*/}
                                                        {/*onClick={(e) => {*/}
                                                            {/*setIsEditing(true)*/}
                                                            {/*setTRow({...row})*/}
                                                            {/*changeHandler(e, row.id)*/}
                                                        {/*}}*/}
                                                        {/*// disabled={loading}*/}
                                                    {/*>*/}
                                                        {/*Edit*/}
                                                    {/*</button>*/}
                                                </>
                                            )
                                        }
                                    </td>
                                </tr>
                            )
                        }) : (
                            <tr>
                                <td colSpan="3">
                                    <ul>
                                        <li>
                                            <p className="txt-gray">
                                                Нет меток
                                            </p>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            {/*{rows.length ? rows.map(row => {*/}
                {/*return (*/}
                    {/*<div className="row clear-row flex-row justify-between align-center" key={row.id} style={{backgroundColor: '#fafafa', borderRadius: '3px', marginBottom: '4px'}}>*/}
                        {/*<div className="col s1 left my-0 maw-50">*/}
                            {/*<div style={{width: '20px', height: '20px', borderRadius: '50%', backgroundColor: row.color}}>*/}
                            {/*</div>*/}
                        {/*</div>*/}

                        {/*<div className="col s7 left my-0 ml-0">*/}
                            {/*<div className="input-field">*/}
                                {/*<input*/}
                                    {/*placeholder="New label"*/}
                                    {/*type="text"*/}
                                    {/*id="label"*/}
                                    {/*className=""*/}
                                    {/*name="label"*/}
                                    {/*value={row.label}*/}
                                    {/*onChange={(e) => {changeHandler(e, row.id)}}*/}
                                    {/*autoComplete="off"*/}
                                    {/*disabled={!row.isEdit}*/}
                                {/*/>*/}
                                {/*/!*<label htmlFor="name">User name</label>*!/*/}
                            {/*</div>*/}
                        {/*</div>*/}

                        {/*{row.isEdit ? (*/}
                                {/*<div className="col s4 my-0 ml-0 right">*/}
                                    {/*<button*/}
                                        {/*className="waves-effect waves-light btn btn-flat grey lighten-3 noUpper mr-1"*/}
                                        {/*style={{color: 'black'}}*/}
                                        {/*name="isEdit"*/}
                                        {/*value={false}*/}
                                        {/*onClick={(e) => {*/}
                                            {/*setIsEditing(false)*/}
                                            {/*changeHandler(e, row.id)*/}
                                            {/*let idx = rows.findIndex(el => el.id === row.id)*/}
                                            {/*rows[idx] = tRow*/}
                                            {/*setRows([...rows])*/}
                                            {/*setTRow({})*/}
                                        {/*}}*/}
                                    {/*>*/}
                                        {/*Cancel*/}
                                    {/*</button>*/}

                                    {/*<button*/}
                                        {/*className="waves-effect waves-light btn btn-flat blue lighten-1 noUpper mr-1"*/}
                                        {/*name="isEdit"*/}
                                        {/*value={false}*/}
                                        {/*onClick={(e) => {*/}
                                            {/*setIsEditing(false)*/}
                                            {/*changeHandler(e, row.id)*/}
                                            {/*saveLabelHandler(row)*/}
                                        {/*}}*/}
                                        {/*disabled={loading}*/}
                                    {/*>*/}
                                        {/*Save*/}
                                    {/*</button>*/}
                                {/*</div>*/}
                            {/*)*/}
                            {/*:*/}
                            {/*(*/}
                                {/*<div className="col s4 my-0 ml-0">*/}
                                    {/*<button*/}
                                        {/*className="waves-effect waves-light btn btn-flat noUpper mr-1"*/}
                                        {/*style={{color: 'black'}}*/}
                                        {/*onClick={() => {deleteLabelHandler(row)}}*/}
                                        {/*disabled={loading}*/}
                                    {/*>*/}
                                        {/*Remove*/}
                                    {/*</button>*/}

                                    {/*<button*/}
                                        {/*className="waves-effect waves-light btn btn-flat  noUpper mr-1"*/}
                                        {/*name="isEdit"*/}
                                        {/*value={true}*/}
                                        {/*onClick={(e) => {*/}
                                            {/*setIsEditing(true)*/}
                                            {/*setTRow({...row})*/}
                                            {/*changeHandler(e, row.id)*/}
                                        {/*}}*/}
                                        {/*// disabled={loading}*/}
                                    {/*>*/}
                                        {/*Edit*/}
                                    {/*</button>*/}
                                {/*</div>*/}

                            {/*)}*/}

                    {/*</div>*/}
                {/*)*/}
            {/*}) : (*/}
                {/*<div className="row clear-row flex-row justify-between ml-0, mr-0">*/}
                    {/*<div className="col s12 center">*/}
                        {/*<div>*/}
                            {/*<ul>*/}
                                {/*<li>*/}
                                    {/*<p className="txt-gray">*/}
                                        {/*There are also no labels*/}
                                    {/*</p>*/}
                                {/*</li>*/}
                            {/*</ul>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>)*/}
            {/*}*/}

            <div className="row clear-row flex-row">
                <div className="col s12 mt-0 ml-0 clear-row justify-start align-center">
                    {isEditing ? (
                        <div className="pl-0 ml-0 w-100">
                            <CirclePicker
                                width="100%"
                                onChange={(color, e) => {
                                    // console.log('color', color.hex)
                                    let idx = rows.findIndex(el => el.isEdit)
                                    if (idx > -1) {
                                        rows[idx].color = color.hex
                                        setRows([...rows])
                                    }
                                }}
                            />
                        </div>
                    ) : (<></>)}
                </div>
            </div>
        </>
    )

}
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {AuthContext} from "../../context/AuthContext";
import {Loader} from "./Loader"
import {useHttp} from "../../hooks/http.hook"
import {SidebarContext} from "../../context/SidebarContext"

export const Sidebar = () => {
    const history = useHistory()
    const url = useParams()
    const proj = useContext(SidebarContext)
    const auth = useContext(AuthContext)
    const {loading, request, error, clearError} = useHttp()
    const [projects, setProjects] = useState(null)
    const [selected, setSelected] = useState(null)

    // const profileR = useRef(null)
    // const projectR = useRef(null)

    // Аналог document.ready из jQuery - Не работает в React!
    // document.addEventListener("DOMContentLoaded", function(event) {
    //     // Инициализируем Dropdown
    //     let el = document.querySelector('.dropdown-trigger')
    //     window.M.Dropdown.init(el)
    // })

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
        // Инициализируем Dropdown
        // let el = document.querySelector('.dropdown-trigger')
        // window.M.Dropdown.init(el)
    }, [])

    const getProject = useCallback(async (token) => {
        try {
            if (token) {
                const u = await request(`/api/auth/check`, 'POST', {userId: auth.user.id}, {authorization: 'Bearer ' + token})
                if (!u.isUser) {
                    auth.logout()
                    history.push('/')
                    return
                }

                const data = await request(`/api/project/projects`, 'POST', null, {authorization: 'Bearer ' + token})
                setProjects(data.projects)
                auth.login(token, auth.user.id, {...auth.user, projects: data.projects})
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    // load project + label + users
    useEffect(() => {
        try {
            getProject(auth.token)
        } catch (e) {
            console.log(e)
        }
    }, [getProject])

    useEffect(() => {
        if (selected && !loading) {
            let els = document.querySelectorAll('li')
            els.forEach(el => el.classList.remove('active-menu'))

            if (proj.id && selected === 'add') {
                document.getElementById(`prj-${proj.id}-case`).classList.add('active-menu')
                return
            }

            document.getElementById(selected) ? document.getElementById(selected).classList.add('active-menu') : document.getElementById('cases').classList.add('active-menu')
        }

    }, [getProject, selected, proj])

    const logoutHandler = (e) => {
        e.preventDefault()
        auth.logout()
        history.push('/')
    }

    const addProjectHandler = (e) => {
        e.preventDefault()
        history.push('/project/create')
    }

    const profileHandler = (e) => {
        // profileR.current.classList.toggle('list1-close')
    }

    const projectHandler = (e, prjId) => {
        if (e.target.querySelector('.fa.fa-chevron-down')) {
            e.target.querySelector('.fa.fa-chevron-down').classList.toggle('d-none')
            e.target.querySelector('.fa.fa-chevron-right').classList.toggle('d-none')
        } else {
            e.target.parentElement.querySelector('.fa.fa-chevron-down').classList.toggle('d-none')
            e.target.parentElement.querySelector('.fa.fa-chevron-right').classList.toggle('d-none')
        }
        let els = document.querySelectorAll(`.prj-${prjId}`)
        if (els) {
            els.forEach(el => {
                el.classList.toggle('d-none')
            })
        }
        // projectR.current.classList.toggle('d-none')
    }

    const menuClickHandler = async (e, path) => {
        console.log('CLICKED')
        await getProject(auth.token)

        console.log(window.location.href)
        console.log(e.target.id)

        let els = document.querySelectorAll('li')
        els.forEach(el => el.classList.remove('active-menu'))

        // here set active-menu
        if (e.target.tagName === 'LI') {
            setSelected(e.target.id)
            e.target.classList.add('active-menu')
        } else if (e.target.tagName === 'A') {
            setSelected(e.target.parentNode.id)
            e.target.parentNode.classList.add('active-menu')
        } else {
            setSelected(e.target.parentNode.parentNode.id)
            e.target.parentNode.parentNode.classList.add('active-menu')
        }

        history.push(path)
    }

    // console.log(auth.user)
    if (!auth.user || loading) {
        return <Loader />
    }

    return (
        <>
            <div className="row clear-row justify-between align-center mb-0" style={{minWidth: '240'}}>
                <div className="col-auto ml-2">
                       <h5 className="txt-gray" style={{fontWeight: '600'}}>
                           <i className="fa" style={{
                               // fontSize: '1.5rem',
                               position: 'relative',
                               width: '24px',
                               height: '24px',
                               borderRadius: '5px',
                               backgroundColor: '#845ab9',
                               // color: 'white',
                               // paddingLeft: '7px',
                               // paddingBottom: '10px',
                               marginBottom: '-2px'
                           }} ><span
                           style={{
                               position: 'absolute',
                               fontSize: '1.4rem',
                               left: '6.5px',
                               top: '1px',
                               color: 'white',
                               fontFamily: 'Sans-serif'
                           }}
                           >s</span></i> Selecty
                       </h5>
                </div>

                <div className="col-auto mr-2">
                    <i className="fa fa-cogs" aria-hidden="true" style={{cursor: 'pointer', paddingTop: '12px'}} onClick={(e) => {menuClickHandler(e, `/user/profile`)}}/>
                </div>
            </div>

            <div className="row clear-row flex-column text-gray" style={{overflowY: 'auto'}}>
                <div className="col-auto ml-2 mr-2 pl-0 pr-0">
                    <ul style={{marginTop: '0', marginBottom: '0'}} >
                        {/*<li><Link to={`/project/${prj.id}/general`}><i className="fa fa-cogs" aria-hidden="true" /> General</Link></li>*/}
                        {/*<li><Link to={`/project/${prj.id}/labels`}><i className="fa fa-tags" aria-hidden="true" /> Labels</Link></li>*/}
                        {/*<li><br/></li>*/}
                        <li className="sb noHover">
                            <div className="input-field ml-0 pl-0 my-0">
                                <i className="fa fa-search prefix" aria-hidden="true" style={{width: '24px', height: '15px', fontSize: 'inherit', marginTop: '5px'}}/>
                                <input
                                    placeholder="Поиск"
                                    type="text"
                                    id="search"
                                    className=""
                                    style={{marginLeft: '24px', width: 'calc(100% - 2rem)', borderBottom: 'none'}}
                                    name="search"
                                    // value={form.name}
                                    autoComplete="off"
                                    // onChange={changeHandler}
                                    onKeyPress={(key) => {
                                        // console.log(key)
                                        if (key.key === 'Enter') {
                                            // console.log(key.target.value)
                                            history.push(`/`)
                                            history.push(`/search/${key.target.value}`)
                                        }
                                    }}
                                />
                                {/*<label htmlFor="search">Search</label>*/}
                            </div>
                        </li>

                        <li id="mtask" className="sb sb-bolder" onClick={(e) => {menuClickHandler(e, `/tasks`)}}><Link to={`#`}><i className="fa fa-tasks" aria-hidden="true" /> Мои задачи</Link></li>
                        <li id="agents" className="sb sb-bolder" onClick={(e) => {menuClickHandler(e, `/agents`)}}><Link to={`#`}><i className="fa fa-hdd-o" aria-hidden="true" /> Агенты</Link></li>

                        <li id="releases" className="sb sb-bolder mt-1" onClick={(e) => {menuClickHandler(e, `/releases`)}}><Link to={`#`}><i className="fa fa-flag-o" aria-hidden="true" /> Релизы</Link></li>
                        <li id="cycles" className="sb sb-bolder" onClick={(e) => {menuClickHandler(e, `/testCycles`)}}><Link to={`#`}><i className="fa fa-refresh" aria-hidden="true" /> Тестовые циклы</Link></li>
                        <li id="cases" className="sb sb-bolder active-menu" onClick={(e) => {menuClickHandler(e, `/testCases`)}}><Link to={`#`}><i className="fa fa-map-o" aria-hidden="true" /> Тест кейсы</Link></li>
                        {/*<li className="sb " onClick={(e) => {menuClickHandler(e, `/testCases`)}}><Link to={`/testCases`}><i className="fa fa-map-o" aria-hidden="true" /> Test cases</Link></li>*/}
                        <li id="recs" className="sb sb-bolder" onClick={(e) => {menuClickHandler(e, `/records`)}}><Link to={`#`}><i className="fa fa-video-camera" aria-hidden="true" /> Записи тестов</Link></li>
                               {/* <li id="recs" className="sb sb-bolder" onClick={(e) => {menuClickHandler(e, `/records`)}}><Link to={`#`}><i className="fa fa-video-camera" aria-hidden="true" /> Records</Link></li> */}
                        {/*<li><Link to={`/project/${prj.id}/testCases/1`}><i className="fa fa-video-camera" aria-hidden="true" /> test</Link></li>*/}
                    </ul>
                </div>

                {/*<div className="col-auto ml-2 mr-2">*/}
                    {/*<p style={{marginBottom: '5px', cursor: 'pointer'}} onClick={profileHandler}><i className="fa fa-folder-o" aria-hidden="true" /> Account</p>*/}
                    {/*/!*<ul style={{marginTop: '0'}} ref={profileR} className="list1 list1-close">*!/*/}
                    {/*<ul style={{marginTop: '0'}} ref={profileR} className="">*/}
                        {/*<li className="sb" onClick={() => {menuClickHandler('/user/profile')}}><Link to="/user/profile"><i className="fa fa-folder-o opacity-0" aria-hidden="true" /> Profile</Link></li>*/}
                        {/*<li className="sb" onClick={() => {menuClickHandler('/user/password')}}><Link to="/user/password"><i className="fa fa-folder-o opacity-0" aria-hidden="true" /> Password</Link></li>*/}
                        {/*<li className="sb" onClick={() => {menuClickHandler('/user/notify')}}><Link to="/user/notify"><i className="fa fa-folder-o opacity-0" aria-hidden="true" /> Notifications</Link></li>*/}
                    {/*</ul>*/}
                {/*</div>*/}

                {/*{auth.user && auth.user.roles[0].role === 'Admin' ? (*/}
                        {/*<div className="col-auto ml-2 mr-2">*/}
                            {/*<p style={{marginBottom: '5px'}}><i className="fa fa-folder-o" aria-hidden="true" /> Administration</p>*/}
                            {/*<ul style={{marginTop: '0', marginBottom: '0'}}>*/}
                                {/*<li className="sb" onClick={() => {menuClickHandler('/members')}}><Link to="/members"><i className="fa fa-folder-o opacity-0" aria-hidden="true" /> Members</Link></li>*/}
                                {/*/!*<li><Link to="/invite">Invite users to project</Link></li>*!/*/}
                            {/*</ul>*/}
                        {/*</div>*/}
                    {/*) : (<></>)}*/}

                {/*<div className="col-auto ml-2 mr-2">*/}
                    {/*<ul>*/}
                        {/*<li className="sb" onClick={(e) => {menuClickHandler(e, '/applications')}}><Link to="/applications"><i className="fa fa-download" aria-hidden="true" /> Applications</Link></li>*/}
                    {/*</ul>*/}
                {/*</div>*/}

                <div className="col-auto ml-2 mr-2 mt-1">
                    <p style={{marginBottom: '5px'}} className=" sb-bolder"><i className="fa fa-folder-o" aria-hidden="true" /> Проекты</p>
                    {/*<ul style={{marginTop: '0', marginBottom: '0'}} ref={projectR} className="list2 list1-close">*/}
                    <ul style={{marginTop: '0'}} className="">
                        {auth.user && auth.user.projects ? auth.user.projects.map(prj => {
                            return (
                                <React.Fragment key={prj.id}>
                                    <li className="sb sb-bolder" onClick={(e) => {projectHandler(e, prj.id)}} ><i className="fa fa-chevron-down" aria-hidden="true" /><i className="fa fa-chevron-right d-none" aria-hidden="true" /> {prj.name.length > 16 ? prj.name.slice(0, 13)+'...' : prj.name}</li>
                                    <li id={`prj-${prj.id}-gen`} className={`sb prj-${prj.id}`} onClick={(e) => {menuClickHandler(e, `/project/${prj.id}/general`)}} ><Link to={`#`}><i className="fa fa-cogs opacity-0" aria-hidden="true" /> Общее</Link></li>
                                    <li id={`prj-${prj.id}-lab`} className={`sb prj-${prj.id}`} onClick={(e) => {menuClickHandler(e, `/project/${prj.id}/labels`)}} ><Link to={`#`}><i className="fa fa-tags opacity-0" aria-hidden="true" /> Метки</Link></li>
                                    <li id={`prj-${prj.id}-case`} className={`sb prj-${prj.id}`} onClick={(e) => {menuClickHandler(e, `/project/${prj.id}/testCases`)}} ><Link to={`#`}><i className="fa fa-tags opacity-0" aria-hidden="true" /> Тест кейсы</Link></li>
                                    <li id={`prj-${prj.id}-cyc`} className={`sb prj-${prj.id}`} onClick={(e) => {menuClickHandler(e, `/project/${prj.id}/testCycles`)}} ><Link to={`#`}><i className="fa fa-tags opacity-0" aria-hidden="true" /> Тестовые циклы</Link></li>
                                    <li id={`prj-${prj.id}-rel`} className={`sb prj-${prj.id}`} onClick={(e) => {menuClickHandler(e, `/project/${prj.id}/releases`)}} ><Link to={`#`}><i className="fa fa-tags opacity-0" aria-hidden="true" /> Релизы</Link></li>
                                    {/*<li className="sb" onClick={() => {menuClickHandler(`/project/${prj.id}/tasks`)}} ><Link to={`/project/${prj.id}/tasks`}><i className="fa fa-tags opacity-0" aria-hidden="true" /> Tasks</Link></li>*/}
                                    {/*<li className="sb" onClick={() => {menuClickHandler(`/project/${prj.id}/agents`)}} ><Link to={`/project/${prj.id}/agents`}><i className="fa fa-tags opacity-0" aria-hidden="true" /> Agents</Link></li>*/}
                                    <li id={`prj-${prj.id}-rec`} className={`sb prj-${prj.id}`} onClick={(e) => {menuClickHandler(e, `/project/${prj.id}/records`)}} ><Link to={`#`}><i className="fa fa-tags opacity-0" aria-hidden="true" /> Записи тестов</Link></li>
                                    <li id={`prj-${prj.id}- `} className={`sb prj-${prj.id}`} onClick={(e) => {menuClickHandler(e, `/project/${prj.id}/variables`)}} ><Link to={`#`}><i className="fa fa-tags opacity-0" aria-hidden="true" /> Переменные</Link></li>
                                </React.Fragment>
                            )
                        }) : (<li></li>)}
                    </ul>
                </div>

                <div className="col-auto ml-2 mr-2">
                    <ul style={{marginTop: '0', marginBottom: '0'}}>
                        <li id="add" className="sb" onClick={(e) => {menuClickHandler(e, `/project/create`)}}>
                            {/*<a href="#!"><i className="fa fa-plus" aria-hidden="true" style={{paddingRight: '2px'}} /> Add project</a>*/}
                            <Link to={'#'}><i className="fa fa-plus" aria-hidden="true" /> Добавить проект</Link>
                        </li>
                    </ul>
                </div>

                <div className="col-auto ml-2 mr-2" style={{bottom: '10px'}}>
                    <ul style={{marginTop: '0', marginBottom: '0'}}>
                        <li className="left sb"><a href="#!" onClick={logoutHandler}><i className="fa fa-sign-out" aria-hidden="true" /> Выход</a></li>
                    </ul>
                </div>
            </div>

            {/*<div className="text-gray" style={{position: 'absolute', bottom: '2rem', left: '2rem', right: '2rem', backgroundColor: 'white', zIndex: '10'}}>*/}
                {/*<ul style={{marginTop: '0', marginBottom: '0'}}>*/}
                    {/*<li className="left sb" onClick={logoutHandler}><a href="#!"><i className="fa fa-sign-out" aria-hidden="true" /> Logout</a></li>*/}
                {/*</ul>*/}
            {/*</div>*/}
        </>
    )
}
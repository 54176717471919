import React from 'react'
import { Redirect, Route, Switch } from "react-router-dom"

import { ApplicationPage } from "./pages/ApplicationPage"
import { AuthPage } from './pages/AuthPage'
import { MainPage } from "./pages/MainPage"
import { SearchPage } from "./pages/SearchPage"
import { AllAgentsPage } from "./pages/agents/AllAgentsPage"
import { CreateAgentPage } from "./pages/agents/CreateAgentPage"
import { CreateContainersPage } from "./pages/agents/CreateContainersPage"
import { EditAgentPage } from "./pages/agents/EditAgentPage"
import { EditContainerPage } from "./pages/agents/EditContainerPage"
import { InvitePage } from "./pages/members/InvitePage"
import { MembersPage } from "./pages/members/MembersPage"
import { ProjectCreatePage } from "./pages/projects/ProjectCreatePage"
import { ProjectGeneralPage } from "./pages/projects/ProjectGeneralPage"
import { ProjectLabelsPage } from "./pages/projects/ProjectLabelsPage"
import { CreateRecordPage } from "./pages/records/CreateRecordPage"
import { EditRecordPage } from "./pages/records/EditRecordPage"
import { RecordsPage } from "./pages/records/RecordsPage"
import { CreateReleasePage } from "./pages/releases/CreateReleasePage"
import { EditReleasePage } from "./pages/releases/EditReleasePage"
import { ProjectReleasesPage } from "./pages/releases/ProjectReleasesPage"
import { ReleasePage } from "./pages/releases/ReleasePage"
import { AllTestCasesPage } from "./pages/testCases/AllTestCasesPage"
import { CreateTestCasePage } from "./pages/testCases/CreateTestCasePage"
import { EditTestCasePage } from "./pages/testCases/EditTestCasePage"
import { ViewProgressPage } from "./pages/testCases/ViewProgressPage"
import { ProjectTestCasesPage } from "./pages/testCases/ProjectTestCasesPage"
import { TasksPage } from "./pages/testCases/TasksPage"
import { CreateTestCyclePage } from "./pages/testCycles/CreateTestCyclesPage"
import { CycleCasesPage } from "./pages/testCycles/CycleCasesPage"
import { EditTestCyclePage } from "./pages/testCycles/EditTestCyclesPage"
import { TestCyclesPage } from "./pages/testCycles/TestCyclesPage"
import { VariablesPage } from "./pages/variables/VariablesPage"

function PrivateRoute({ component: Component, authed, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => authed === true
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />}
        />
    )
}

export const useRoutes = isAuth => {
    return (
        <Switch>
            <PrivateRoute authed={isAuth} path="/user/profile" component={() => (<MainPage page='Profile' />)} exact />
            <PrivateRoute authed={isAuth} path="/user/password" component={() => (<MainPage page='Password' />)} exact />
            <PrivateRoute authed={isAuth} path="/user/notify" component={() => (<MainPage page='Notification' />)} exact />
            <PrivateRoute authed={isAuth} path='/members/' component={MembersPage} exact />
            <PrivateRoute authed={isAuth} path='/invite/' component={InvitePage} exact />

            <PrivateRoute authed={isAuth} path='/search/:str' component={SearchPage} />
            <PrivateRoute authed={isAuth} path='/tasks' component={TasksPage} exact />

            <PrivateRoute authed={isAuth} path='/agents' component={AllAgentsPage} exact />
            <PrivateRoute authed={isAuth} path='/agents/createAgents' component={CreateAgentPage} exact />
            <PrivateRoute authed={isAuth} path='/agents/createContainers' component={CreateContainersPage} exact />
            <PrivateRoute authed={isAuth} path='/agents/agent/:agentId' component={EditAgentPage} exact />
            <PrivateRoute authed={isAuth} path='/agents/container/:agentId' component={EditContainerPage} exact />
            <PrivateRoute authed={isAuth} path='/releases' component={ProjectReleasesPage} exact />
            <PrivateRoute authed={isAuth} path='/releases/createRelease' component={CreateReleasePage} exact />
            <PrivateRoute authed={isAuth} path='/releases/show/:releaseId' component={ReleasePage} exact />

            <PrivateRoute authed={isAuth} path='/testCycles' component={TestCyclesPage} exact />
            <PrivateRoute authed={isAuth} path='/testCycles/createCycle' component={CreateTestCyclePage} exact />
            <PrivateRoute authed={isAuth} path='/testCycles/show/:cycleId/:releaseId' component={CycleCasesPage} exact />

            <PrivateRoute authed={isAuth} path='/testCases' component={AllTestCasesPage} exact />
            <PrivateRoute authed={isAuth} path='/testCases/createTestCase' component={CreateTestCasePage} exact />

            <PrivateRoute authed={isAuth} path='/applications' component={ApplicationPage} exact />

            <PrivateRoute authed={isAuth} path='/records' component={RecordsPage} exact />
            <PrivateRoute authed={isAuth} path='/records/createRecord' component={CreateRecordPage} exact />
            {/*<PrivateRoute authed={isAuth} path='/records/record/:recordId' component={EditRecordPage} exact />*/}



            <PrivateRoute authed={isAuth} path='/project/create' component={ProjectCreatePage} exact />
            <PrivateRoute authed={isAuth} path="/project/:id/general" component={ProjectGeneralPage} exact />
            <PrivateRoute authed={isAuth} path="/project/:id/labels" component={ProjectLabelsPage} exact />

            <PrivateRoute authed={isAuth} path="/project/:id/testCases" component={ProjectTestCasesPage} exact />
            <PrivateRoute authed={isAuth} path="/project/:id/createTestCase" component={CreateTestCasePage} exact />
            <PrivateRoute authed={isAuth} path="/project/:id/testCases/:caseId" component={EditTestCasePage} exact />
            <PrivateRoute authed={isAuth} path="/project/:id/testCases/:caseId/viewprogress" component={ViewProgressPage} exact />

            <PrivateRoute authed={isAuth} path="/project/:id/testCycles" component={TestCyclesPage} exact />
            <PrivateRoute authed={isAuth} path="/project/:id/createCycle" component={CreateTestCyclePage} exact />
            <PrivateRoute authed={isAuth} path="/project/:id/testCycles/:cycleId" component={EditTestCyclePage} exact />

            <PrivateRoute authed={isAuth} path="/project/:id/releases" component={ProjectReleasesPage} exact />
            <PrivateRoute authed={isAuth} path="/project/:id/createRelease" component={CreateReleasePage} exact />
            <PrivateRoute authed={isAuth} path="/project/releases/:releaseId" component={EditReleasePage} exact />

            <PrivateRoute authed={isAuth} path="/project/:id/tasks" component={TasksPage} exact />

            {/*<PrivateRoute authed={isAuth} path="/project/:id/agents" component={AgentsPage} exact />*/}
            {/*<PrivateRoute authed={isAuth} path="/project/:id/createAgent" component={CreateAgentPage} exact />*/}

            <PrivateRoute authed={isAuth} path='/variables' component={VariablesPage} exact />
            <PrivateRoute authed={isAuth} path="/project/:id/variables" component={VariablesPage} exact />

            <PrivateRoute authed={isAuth} path="/project/:id/records" component={RecordsPage} exact />
            <PrivateRoute authed={isAuth} path="/project/:id/createRecord" component={CreateRecordPage} exact />
            <PrivateRoute authed={isAuth} path="/project/:id/records/:recordId" component={EditRecordPage} exact />

            <Route path="/auth" exact>
                {/*{isAuth ? <MainPage page='Cases' /> : <AuthPage />}*/}
                {isAuth ? <AllTestCasesPage /> : <AuthPage />}
            </Route>

            {/*<Route path="/portfolio" exact>*/}
            {/*<PortfolioPage />*/}
            {/*</Route>*/}

            <Redirect to="/testCases" />
        </Switch>
    )
}
import React from 'react'
import {TestCycleForm} from "./TestCycleForm"
import {useParams} from "react-router-dom"

export const EditTestCyclePage = () => {
    const id = useParams().cycleId
    return (
        <>
            <TestCycleForm cycleId={id} />
        </>
    )
}

import React, {useContext} from 'react'
import {useState, useEffect} from "react";
import {useHttp} from "../../hooks/http.hook"
import {Loader} from "../partials/Loader"
import {useMessage} from "../../hooks/message.hook"
import {useHistory} from "react-router-dom"
import {AuthContext} from "../../context/AuthContext"
import {SidebarContext} from "../../context/SidebarContext"

export const UserPasswordPage = () => {
    const { token, user, logout } = useContext(AuthContext)
    const prj = useContext(SidebarContext)
    const [form, setForm] = useState({
        password: '',
        newPassword: '',
        retypePassword: ''
    })
    const {loading, request, error, clearError} = useHttp()
    const message = useMessage()
    const history = useHistory()

    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
    })

    useEffect(() => {
        prj.toggle(true, null)
    }, [])

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const updateHandler = async () => {
        try {
            // check pass newpass = retypePass && length > 0
            if (!form.newPassword || form.newPassword !== form.retypePassword || form.newPassword.length < 6) {
                message(`"Новый пароль" и "Повторите пароль" должны совпадать!`)
                return
            }
            const data = await request('/api/auth/updatePassword', 'POST', {...form}, {authorization: 'Bearer ' + token, id: user.id})
            message(data.message)
        } catch (e) {}
    }

    if (!user) {
        return <Loader />
    }

    return (
        <>
            <div className="row clear-row mt-noHeader">
                <div className="col-auto">
                    <h5>Пароль</h5>
                    <p className="txt-gray">Вы можете изменить свой пароль</p>
                </div>
            </div>

            <div className="row clear-row flex-column">
                <div className="col s4 ml-0 pl-0">
                    <div className="input-field">
                        <input
                            // placeholder="Текущий пароль"
                            type="password"
                            id="password"
                            className=""
                            name="password"
                            value={form.password}
                            onChange={changeHandler}
                        />
                        <label htmlFor="password">Текущий пароль</label>
                    </div>

                    <div className="input-field">
                        <input
                            // placeholder="Новый пароль"
                            type="password"
                            id="newPassword"
                            className=""
                            name="newPassword"
                            value={form.newPassword}
                            onChange={changeHandler}
                        />
                        <label htmlFor="newPassword">Новый пароль</label>
                    </div>

                    <div className="input-field">
                        <input
                            // placeholder="Повторите пароль"
                            type="password"
                            id="retypePassword"
                            className=""
                            name="retypePassword"
                            value={form.retypePassword}
                            onChange={changeHandler}
                        />
                        <label htmlFor="retypePassword">Повторите пароль</label>

                        <button
                            className="waves-effect waves-light btn blue lighten-1 noUpper"
                            onClick={updateHandler}
                            disabled={loading}
                        >
                            Изменить пароль
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
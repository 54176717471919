import M from 'materialize-css'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from "react-router-dom"
import { AuthContext } from "../../context/AuthContext"
import { SidebarContext } from "../../context/SidebarContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"
import { Header } from "../partials/Header"
import { NoTable } from "../partials/NoTable"

export const VariablesPage = () => {
    const { token, user, logout } = useContext(AuthContext)
    const history = useHistory()
    const id = useParams().id || null
    const prj = useContext(SidebarContext)
    const [variables, setVariables] = useState([])
    const modalRef1 = useRef(null)
    const modalRef2 = useRef(null)
    const { loading, request, error, clearError } = useHttp()
    const message = useMessage()
    const [editVarForm, setEditVarForm] = useState(nullVarForm)

    let nullVarForm = {
        prjId: +id,
        name: '',
        value: '',
        description: '',
    }
    const [varForm, setVarForm] = useState(nullVarForm)
    // Переменная для хранения экземпляра модального окна
    const modalInstance = useRef(null)
    const modalInstance2 = useRef(null)



    useEffect(async () => {
        const allVariables = await request(`/api/variable/all`, 'POST', { projectId: id }, { authorization: 'Bearer ' + token })
        setVariables(allVariables.variables)
    }, [])



    useEffect(() => {
        try {
            if (!loading) {
                window.M.updateTextFields()
                window.M.FormSelect.init(document.querySelectorAll('select'))
                let ta = document.querySelectorAll('textarea')
                if (ta.NodeList && ta.NodeList.length) {
                    window.M.textareaAutoResize(ta)
                }
                // Инициализация модального окна и сохранение экземпляра
                if (modalRef1.current) {
                    modalInstance.current = M.Modal.init(modalRef1.current)
                }
                if (modalRef2.current) {
                    modalInstance2.current = M.Modal.init(modalRef2.current)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }, [loading])

    // Функция для открытия модального окна
    const openModal = () => {
        if (modalInstance.current) {
            modalInstance.current.open()
        }
    }
    const openModal2 = () => {
        if (modalInstance2.current) {
            modalInstance2.current.open()
        }
    }

    const createVariable = () => {
        openModal()
    }

    // header buttons
    const createTestCase = () => {
        if (id) {
            history.push(`/project/${prj.id}/createTestCase`)
        } else {
            history.push(`/testCases/createTestCase`)
        }
    }

    const saveVariable = async () => {
        try {
            const data = await request(`/api/variable/create`, 'POST', { ...varForm }, { authorization: 'Bearer ' + token })
            message(data.message)
            setVarForm(nullVarForm)
            const allVariables = await request(`/api/variable/all`, 'POST', { projectId: id }, { authorization: 'Bearer ' + token })
            setVariables(allVariables.variables)
        } catch (e) {
            console.log(e)
        }
    }


    const updateVariable = async () => {
        try {
            const data = await request(`/api/variable/update`, 'POST', { ...editVarForm }, { authorization: 'Bearer ' + token })
            message(data.message)
            setEditVarForm(nullVarForm)
            const allVariables = await request(`/api/variable/all`, 'POST', { projectId: id }, { authorization: 'Bearer ' + token })
            setVariables(allVariables.variables)
        } catch (e) {
            console.log(e)
        }
    }



    const deleteHandler = async (itemId) => {
        console.log('delete', id)
        try {
            const data = await request(`/api/variable/delete`, 'POST', { id: itemId, projectId: id }, { authorization: 'Bearer ' + token })
            message(data.message)
            setVariables(data.variables)
        } catch (e) {
            console.log(e)
        }
    }


    const changeHandler = (id) => {
        const variableToEdit = variables.find(variable => variable.id === id)
        setEditVarForm(variableToEdit)
        openModal2()
    }

    return (
        <>
            <Header params={{
                title: `Переменные`,
                btnL: {
                    actionHandler: createVariable,
                    title: '+ Создать переменную',
                    display: 'inline-block'
                },
                btnR: {
                    actionHandler: createTestCase,
                    title: '+ Тест кейс',
                    display: 'inline-block'
                },
            }} />

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    <h5>Переменные</h5>
                </div>
            </div>

            <div className="row clear-row flex-row">
                {variables && variables.length ? (
                    <div className="col s12 pl-0" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>


                        {variables.map((variable, key) => (
                            <div key={key} style={{ display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                    <div style={{ fontSize: '20px', fontWeight: '600', padding: '5px 0' }} >{variable.name}</div>
                                    <div style={{ fontSize: '14px', fontWeight: '400', color: '#959595', padding: '5px 0' }}>{variable.description}</div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                                    <div style={{ fontSize: '14px', fontWeight: '400', color: '#959595', backgroundColor: '#F0F3F9', padding: '10px 20px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>Предварительный просмотр данных


                                        <div style={{ float: 'right', display: 'flex', flexDirection: 'row', gap: '5px' }}>


                                            <div onClick={() => { changeHandler(variable.id) }} className="hoverable" style={{ paddingTop: '2px' }}>
                                                <i className='fa fa-pencil-square-o icon' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: '0px' }} ></i>
                                            </div>


                                            <div onClick={() => { deleteHandler(variable.id) }} style={{ paddingTop: '0px', cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className="hoverable">
                                                <i className='fa fa-trash icon' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} ></i>
                                            </div>


                                        </div>


                                    </div>
                                    <div style={{ fontSize: '14px', fontWeight: '400', padding: '20px 20px', border: '1px solid #F0F3F9', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', whiteSpace: 'pre-wrap' }}>
                                        {variable.value}
                                    </div>
                                </div>
                            </div>
                        ))}






                    </div>
                ) : (<NoTable params={{ title: `No variables yet` }} />)}
            </div>

            <div id="modal1" className="modal w-600 modal-fixed-height" ref={modalRef1}>
                <div className="row clear-row flex-row justify-between mb-0" style={{ borderBottom: '1px solid #eee', width: '100%' }}>
                    <div className="col" style={{ marginTop: '20px', marginLeft: '10px', display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                        <h5 style={{ fontWeight: '600', marginTop: 0, display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                            <span style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '0px 10px' }}>
                                Создать переменную
                            </span>
                            <button className="modal-close waves-effect waves-green btn-flat txt-gray" style={{ marginTop: 0, marginLeft: 0 }}>
                                <i style={{ color: '#eaeaea' }} className="fa fa-times" aria-hidden="true" />
                            </button>
                        </h5>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', padding: '30px', gap: '20px', alignItems: 'start' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                        <div className="input-field">
                            <input
                                placeholder="Имя"
                                type="text"
                                id="name"
                                className=""
                                name="name"
                                autoComplete="off"
                                value={varForm && varForm.name} // Убедитесь, что varForm определён
                                onChange={(e) => setVarForm({ ...varForm, name: e.target.value })}
                            />
                            <label htmlFor="name">Имя</label>

                            {/* <input
                                placeholder="Name"
                                type="text"
                                id="name"
                                className=""
                                name="name"
                                autoComplete="off"
                                value={editVarForm && editVarForm.name} // Используйте editVarForm
                                onChange={(e) => setEditVarForm({ ...editVarForm, name: e.target.value })} // Используйте editVarForm
                            />
                            <label htmlFor="name">Name</label> */}
                        </div>

                        <div className="input-field">
                            <input
                                placeholder="Описание"
                                type="text"
                                id="description"
                                className=""
                                name="description"
                                autoComplete="off"
                                value={varForm && varForm.description} // Убедитесь, что varForm определён
                                onChange={(e) => setVarForm({ ...varForm, description: e.target.value })}
                            />
                            <label htmlFor="description">Описание</label>
                        </div>
                    </div>



                    <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                        <div style={{ fontSize: '14px', fontWeight: '400', color: '#959595', backgroundColor: '#F0F3F9', padding: '10px 20px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>Предварительный просмотр данных





                        </div>
                        <textarea
                            onChange={(e) => setVarForm({ ...varForm, value: e.target.value })}
                            value={varForm && varForm.value}
                            style={{ fontSize: '14px', fontWeight: '400', padding: '20px 20px', border: '1px solid #F0F3F9', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', whiteSpace: 'pre-wrap', height: '150px', overflow: 'auto', resize: 'none' }}>

                        </textarea>
                    </div>






                </div>

                <div className="modal-footer mb-1">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <div className="col-auto mr-1">
                            {/* Cancel */}
                            <button
                                style={{ border: '1px solid grey', color: 'black' }}
                                className="waves-effect waves-dark btn white lighten-1 noUpper modal-close"
                            >
                                Отмена
                            </button>
                        </div>
                        <div className="col-auto mr-1">
                            {/* Save changes */}
                            <button
                                className="waves-effect waves-light btn blue lighten-1 noUpper"
                                onClick={saveVariable}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal2" className="modal w-600 modal-fixed-height" ref={modalRef2}>
                <div className="row clear-row flex-row justify-between mb-0" style={{ borderBottom: '1px solid #eee', width: '100%' }}>
                    <div className="col" style={{ marginTop: '20px', marginLeft: '10px', display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                        <h5 style={{ fontWeight: '600', marginTop: 0, display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                            <span style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '0px 10px' }}>
                                Изменить переменную
                            </span>
                            <button className="modal-close waves-effect waves-green btn-flat txt-gray" style={{ marginTop: 0, marginLeft: 0 }}>
                                <i style={{ color: '#eaeaea' }} className="fa fa-times" aria-hidden="true" />
                            </button>
                        </h5>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', padding: '30px', gap: '20px', alignItems: 'start' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                        <div className="input-field">


                            <input
                                placeholder="Имя"
                                type="text"
                                id="name"
                                className=""
                                name="name"
                                autoComplete="off"
                                value={editVarForm && editVarForm.name} // Используйте editVarForm
                                onChange={(e) => setEditVarForm({ ...editVarForm, name: e.target.value })} // Используйте editVarForm
                            />
                            <label htmlFor="name">Имя</label>
                        </div>

                        <div className="input-field">
                            <input
                                placeholder="Описание"
                                type="text"
                                id="description"
                                className=""
                                name="description"
                                autoComplete="off"
                                value={editVarForm && editVarForm.description} // Используйте editVarForm
                                onChange={(e) => setEditVarForm({ ...editVarForm, description: e.target.value })} // Используйте editVarForm
                            />
                            <label htmlFor="description">Описание</label>
                        </div>
                    </div>



                    <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                        <div style={{ fontSize: '14px', fontWeight: '400', color: '#959595', backgroundColor: '#F0F3F9', padding: '10px 20px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>Предварительный просмотр данных





                        </div>
                        <textarea
                            onChange={(e) => setEditVarForm({ ...editVarForm, value: e.target.value })}
                            value={editVarForm && editVarForm.value}
                            style={{ fontSize: '14px', fontWeight: '400', padding: '20px 20px', border: '1px solid #F0F3F9', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', whiteSpace: 'pre-wrap', height: '150px', overflow: 'auto', resize: 'none' }}>

                        </textarea>
                    </div>





                </div>

                <div className="modal-footer mb-1">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <div className="col-auto mr-1">
                            {/* Cancel */}
                            <button
                                style={{ border: '1px solid grey', color: 'black' }}
                                className="waves-effect waves-dark btn white lighten-1 noUpper modal-close"
                            >
                                Отмена
                            </button>
                        </div>
                        <div className="col-auto mr-1">
                            {/* Save changes */}
                            <button
                                className="waves-effect waves-light btn blue lighten-1 noUpper"
                                onClick={updateVariable}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

import React, {useContext} from 'react'
import {UserProfilePage} from './user/Profile'
import {UserPasswordPage} from "./user/Password"
import {UserNotifyPage} from "./user/Notify"

export const MainPage = (props) => {
    // const { userId, token, logout, user } = useContext(AuthContext)
    // const message = useMessage()
    // const [user, setUser] = useState(null)
    // const {loading, request, error, clearError} = useHttp()
    // const history = useHistory()
    //
    // useEffect(() => {
    //     message(error)
    //     if (error === 'Нет авторизации') {
    //         clearError()
    //         logout()
    //         history.push('/')
    //     }
    //     // console.log('err', error)
    //     clearError()
    // }, [error, message, clearError])
    //
    // get user data
    // {userId} {props.page}
    // const fetchUsr = useCallback(async () => {
    //     try {
    //         const usr = await request(`/api/auth/${userId}`, 'GET', null, {authorization: 'Bearer ' + token})
    //         if (usr.user) {
    //             usr.user.token = token
    //             setUser(usr.user)
    //         }
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }, [request, setUser])
    //
    // useEffect(() => {
    //     if (userId) fetchUsr()
    // }, [])
    //
    //
    // if (loading || !user || !props.page) {
    //     return <Loader />
    // }
    // useEffect(() => {
    //     if (user) user.token = token
    // }, [])

    if (props && props.page === 'Profile') return <UserProfilePage />
    if (props && props.page === 'Password') return <UserPasswordPage />
    if (props && props.page === 'Notification') return <UserNotifyPage />

}
import React, {useContext, useEffect, useState} from 'react'
import {useHttp} from "../../hooks/http.hook"
import {useHistory} from "react-router-dom"
import {AuthContext} from "../../context/AuthContext"
import {useMessage} from "../../hooks/message.hook"
import {Loader} from "../partials/Loader"
import {SidebarContext} from "../../context/SidebarContext"

export const ProjectCreatePage = () => {
    const { token, user, logout, login } = useContext(AuthContext)
    const {loading, request, error, clearError} = useHttp()
    const prj = useContext(SidebarContext)
    const [form, setForm] = useState({
        name: '',
        description: '',
        slug: ''
    })
    const message = useMessage()
    const history = useHistory()

    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()

        window.M.textareaAutoResize(document.getElementById('description'))
        // window.M.FormSelect.init(document.querySelectorAll('select'))
    })

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const updateHandler = async () => {
        try {
            const data = await request('/api/project/create', 'POST', {...form}, {authorization: 'Bearer ' + token, id: user.id})
            message(data.message)
            // update user in context
            // get user
            const usr = await request('/api/auth/getWithProjetcs', 'POST', {email: user.email}, {authorization: 'Bearer ' + token})
            login(token, user.id, usr.user)

            setForm({
                name: '',
                description: '',
                slug: ''
            })

            prj.toggle(false, data.id)

            history.push(`/project/${data.id}/testCases`)
        } catch (e) {}
    }

    if (!user) {
        return <Loader />
    }

    return (
        <>
            <div className="row clear-row mt-noHeader">
                <div className="col-auto">
                    <h5>Создание нового проекта</h5>
                    <p className="txt-gray">Создайте новый проект для управления отдельными циклами, рабочими процессами и уведомлениями.</p>
                </div>
            </div>

            <div className="row clear-row flex-row">
                <div className="col s5 ml-0 pl-0">
                    <div className="input-field">
                        <input
                            // placeholder="Project name"
                            type="text"
                            id="name"
                            className=""
                            name="name"
                            value={form.name}
                            autoComplete="off"
                            onChange={changeHandler}
                        />
                        <label htmlFor="name">Название проекта</label>
                    </div>
                </div>
            </div>

            <div className="row clear-row flex-row">
                <div className="col s5 ml-0 pl-0">
                    <div className="input-field">
                        <textarea
                            id="description"
                            name="description"
                            value={form.description}
                            onChange={changeHandler}
                            className="materialize-textarea" />
                        <label htmlFor="description">Описание (необязательно)</label>
                    </div>
                </div>
            </div>

            {/*<div className="row clear-row flex-row align-bottom">*/}
                {/*<div className="col s4 mt-1 ml-0 pl-0">*/}
                    {/*<div className="input-field">*/}
                        {/*<input*/}
                            {/*// placeholder="Project identifier"*/}
                            {/*type="text"*/}
                            {/*id="slug"*/}
                            {/*className=""*/}
                            {/*name="slug"*/}
                            {/*value={form.slug}*/}
                            {/*autoComplete="off"*/}
                            {/*onChange={changeHandler}*/}
                            {/*disabled*/}
                        {/*/>*/}
                        {/*<label htmlFor="slug">Project identifier</label>*/}
                    {/*</div>*/}
                {/*</div>*/}

                {/*<div className="col s4 mt-1 ml-0 pl-2">*/}
                    {/*<div className="input-field">*/}
                        {/*<p className="txt-gray" style={{marginBottom: '18px'}}>This is used as the identifier (e.g. ENG-123) for all issues of the team. Keep it short and simple. It will be created automatic.</p>*/}
                    {/*</div>*/}
                {/*</div>*/}
            {/*</div>*/}

            <div className="row clear-row flex-row align-center">
                <div className="col-auto ml-0">
                    <button
                        className="waves-effect waves-light btn blue lighten-1 noUpper"
                        onClick={updateHandler}
                        disabled={loading}
                    >
                        Создать проект
                    </button>
                </div>
            </div>
        </>
    )

}
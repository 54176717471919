import React, { useCallback, useContext, useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom"
import { SidebarContext } from "../../context/SidebarContext"
import { Loader } from "../partials/Loader"
import { AuthContext } from "../../context/AuthContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"
import { Header } from "../partials/Header"
import { NoTable } from "../partials/NoTable"


export const AllTestCasesPage = () => {
    const { token, logout, user } = useContext(AuthContext)
    const { loading, request, error, clearError, fileUpload } = useHttp()
    const [users, setUsers] = useState(null)
    const [projects, setProjects] = useState(null)
    const message = useMessage()
    const history = useHistory()
    const [cases, setCases] = useState([])
    const inputFile = useRef(null)
    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
        window.M.FormSelect.init(document.querySelectorAll('select'), { classes: 'black-text' })
    })

    const getProject = useCallback(async (token, id) => {
        try {
            // if (token && id) {
            //     const data = await request(`/api/project/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
            //     setProject(data.project)
            // }
            if (token) {
                const data = await request(`/api/project/projects`, 'POST', null, { authorization: 'Bearer ' + token })
                setProjects(data.projects)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])








    const getCases = useCallback(async (token) => {
        try {
            if (token) {
                const data = await request(`/api/case/all`, 'POST', { projectId: null }, { authorization: 'Bearer ' + token })

                // Only last updated execution in each case
                if (data.cases && data.cases.length) {
                    for (let i = 0; i < data.cases.length; i++) {
                        if (data.cases[i].executions.length) {
                            let ex = data.cases[i].executions.sort((a, b) => { return new Date(a.updatedAt) - new Date(b.updatedAt) })
                            ex = ex[ex.length - 1]
                            data.cases[i].executions = ex
                        } else {
                            data.cases[i].executions = { status: 'NoExec' }
                        }
                    }
                }

                setCases(data.cases)
                // console.log(data.cases)
                // console.log('Max date', new Date(Math.max(...data.cases[0].executions.map(ex => new Date(ex.updatedAt)))).toISOString())
                // console.log('arr asc', data.cases[0].executions.sort((a, b) => {return new Date(a.updatedAt) - new Date(b.updatedAt)}))
                // console.log('arr desc', data.cases[0].executions.sort((a, b) => {return new Date(a.updatedAt) - new Date(b.updatedAt)}).reverse())

            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    // get users data
    const fetchUsers = useCallback(async () => {
        try {
            const data = await request(`/api/auth/users`, 'POST', null, { authorization: 'Bearer ' + token })
            if (data.users) {
                setUsers(data.users)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request, setUsers])

    useEffect(() => {
        fetchUsers()
        getProject(token, null)
        getCases(token)
    }, [getCases, getProject])

    // const changeHandler = event => {
    //     setForm({ ...form, [event.target.name]: event.target.value })
    // }
    //
    // const updateHandler = async () => {
    //     try {
    //         const data = await request(`/api/project/${id}`, 'PUT', {...form}, {authorization: 'Bearer ' + token})
    //         setForm({name: data.project.name, slug: data.project.slug})
    //         message(data.message)
    //     } catch (e) {}
    // }

    const deleteHandler = async (caseId) => {
        try {
            const data = await request(`/api/case/${caseId}`, 'DELETE', null, { authorization: 'Bearer ' + token })
            message(data.message)

            setCases([...cases.filter(el => el.id !== caseId)])
        } catch (e) { }
    }






    const fileUploadHendler = async (event, caseId) => {
        console.log("caseId", caseId)
        try {
            const fd = new FormData()
            fd.append('file', event.target.files[0])

            const data = await fileUpload('/api/case/uploadTestScenario', 'POST', fd, { authorization: 'Bearer ' + token, id: caseId })

            // setForm({ ...form, img: `/assets/caseScenario/${caseId.id}.txt?v=${Date.now()}` })
            message(data.message)
        } catch (e) {
            console.log(e)
        }
    }

    const fileDownloadHendler = async (caseId) => {
        console.log("caseId", caseId)
        try {
            const response = await fetch(`/api/case/downloadScenario/${caseId}`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })

            if (response.ok) {
                const blob = await response.blob()
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = `${caseId}.log`
                document.body.appendChild(a)
                a.click()
                a.remove()
                message('Файл успешно загружен')
            } else {
                const data = await response.json()
                message(data.message)
            }
        } catch (e) {
            console.log(e)
        }
    }




    // if (!prj.id || !project || loading) {
    if (loading) {
        return <Loader />
    }

    const changeExecHandler = async (event, execId) => {
        try {
            let idx = cases.findIndex(el => el.executions.id === execId)
            cases[idx].executions.status = event.target.value

            const data = await request(`/api/exec/${execId}`, 'PUT', { status: event.target.value, userId: user.id }, { authorization: 'Bearer ' + token })
            message(data.message)

            setCases([...cases])
        } catch (e) {
            console.log(e)
        }
    }

    const createTestCase = () => {
        history.push(`/testCases/createTestCase`)
    }

    return (
        <>
            <Header params={{
                title: `Все тест кейсы`,
                btnL: {
                    actionHandler: () => { console.log('left') },
                    title: 'btnLeft',
                    display: 'none'
                },
                btnR: {
                    actionHandler: createTestCase,
                    title: '+ Тест кейс',
                    display: 'block'
                },
                loading
            }} />

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    <h5>Все тест кейсы</h5>
                    {/*<p className="txt-gray">Manage your project settings</p>*/}
                </div>
            </div>

            <div className="row clear-row flex-row">
                {cases && cases.length ? (
                    <div className="col s12 pl-0">
                        <table className="highlight">
                            <thead>
                                <tr>
                                    <th className="txt-gray ">id</th>
                                    <th className="txt-gray ">Описание</th>
                                    <th className="txt-gray ">Статус</th>
                                    <th className="txt-gray ">Компонент</th>
                                    <th className="txt-gray ">Проект</th>
                                    <th className="txt-gray ">Метки</th>
                                    <th className="txt-gray ">Создатель</th>
                                    <th className="txt-gray ">Дата создания</th>
                                    <th className="txt-gray " />
                                </tr>
                            </thead>
                            <tbody>
                                {cases && cases.length ? cases.map(c => {
                                    return (
                                        <tr
                                            key={c.id}
                                        >
                                            <td className="py-0">{c.id}</td>
                                            <td className="py-0 td-titleMAW">{c.title || '-'}</td>
                                            <td className="py-0">
                                                {c.executions.status !== 'NoExec' ? (
                                                    <div
                                                        className={`input-field py-0 bRadius-7 ${c.executions.status === 'Unexecuted' ? 'bgGray' : c.executions.status === 'Success' ? 'bgGreen' : 'bgRed'}`}>
                                                        <select
                                                            id={'status' + c.executions.id}
                                                            name="status"
                                                            value={c.executions.status}
                                                            onChange={(e) => { changeExecHandler(e, c.executions.id) }}
                                                        >
                                                            <option value="Unexecuted">Не выполнен</option>
                                                            <option value="Success">Успех</option>
                                                            <option value="Fail">Неудача</option>
                                                        </select>
                                                    </div>
                                                ) : (
                                                    <div className="input-field" style={{ fontSize: '11px', color: '#A0A0A0' }}>Не используется ни в одном релизе</div>
                                                )}
                                            </td>
                                            <td className="py-0">{c.component}</td>


                                            <td className="">
                                                {projects && projects.length
                                                    ? (projects.find(p => p.id === c.projectId)
                                                        ? projects.find(p => p.id === c.projectId).name
                                                        : '-')
                                                    : '-'}
                                            </td>


                                            <td className="py-0">{c.labels.length ? c.labels.map(l => l.label).join(', ') : '-'}</td>
                                            <td className="py-0">{users ? users.find(el => el.id === c.userId).email : '-'}</td>
                                            <td className="py-0 td-cOn">{c.updatedAt ? new Date(c.updatedAt).toLocaleDateString() : '-'}</td>
                                            <td className="py-0 td-actions" style={{ width: '100px' }}>
                                                {/* <div onClick={() => { fileDownloadHendler(c.id) }}> 1111 </div> */}
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>





                                                    {c.executions.status !== "Unexecuted" ?
                                                        < i
                                                            style={{ cursor: 'pointer' }}
                                                            className="fa fa-eye opacity-0"
                                                            aria-hidden="true"
                                                            onClick={() => { history.push(`/project/${c.projectId}/testCases/${c.id}/viewprogress`) }}
                                                        />
                                                        :
                                                        <></>
                                                    }



                                                    {/* {c.executions.status !== "Unexecuted" ?

                                                        <>
                                                            <i class="fa fa-upload opacity-0" aria-hidden="true" style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    inputFile.current.click()
                                                                }} />


                                                            <input
                                                                type='file'
                                                                id="caseScenario"
                                                                ref={inputFile}
                                                                style={{ display: 'none' }}
                                                                // value={form.img}
                                                                onChange={(e) => { fileUploadHendler(e, c.id) }}
                                                                accept=".log"
                                                            />
                                                        </>

                                                        :
                                                        <></>
                                                    } */}

                                                    {/* {c.executions.status !== "Unexecuted" ?

                                                        <>
                                                            <i class="fa fa-upload opacity-0" aria-hidden="true" style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    inputFile.current.click()
                                                                }} />


                                                            <input
                                                                type='file'
                                                                id="caseScenario"
                                                                ref={inputFile}
                                                                style={{ display: 'none' }}
                                                                // value={form.img}
                                                                onChange={(e) => { fileUploadHendler(e, c.id) }}
                                                                accept=".log"
                                                            />
                                                        </>

                                                        :
                                                        <></>
                                                    } */}







                                                    <i
                                                        style={{ cursor: 'pointer' }}
                                                        className="fa fa-pencil-square-o opacity-0"
                                                        aria-hidden="true"
                                                        onClick={() => { history.push(`/project/${c.projectId}/testCases/${c.id}`) }}
                                                    />
                                                    <i
                                                        style={{ cursor: 'pointer' }}
                                                        className="fa fa-trash-o opacity-0"
                                                        aria-hidden="true"
                                                        onClick={() => { deleteHandler(c.id) }}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) : (<></>)}
                            </tbody>
                            <tfoot>
                                {/*<tr onClick={addExecHandler}><td colSpan="7" className="center"><ul><li style={{cursor: 'pointer'}}>+ Add new execution</li></ul></td></tr>*/}
                            </tfoot>
                        </table>
                    </div>
                ) : (<NoTable params={{ title: `There are no Test Cases` }} />)}
            </div>
        </>
    )

}

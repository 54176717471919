import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom"
import {SidebarContext} from "../../context/SidebarContext"
import {Loader} from "../partials/Loader"
import {AuthContext} from "../../context/AuthContext"
import {useHttp} from "../../hooks/http.hook"
import {useMessage} from "../../hooks/message.hook"
import {Header} from "../partials/Header"

export const RecordForm = ({recordId}) => {
    const { token, user, logout } = useContext(AuthContext)
    const {loading, request, error, clearError} = useHttp()
    const id = useParams().id
    const prj = useContext(SidebarContext)
    const [project, setProject] = useState(null)
    const [projects, setProjects] = useState(null)
    const message = useMessage()
    const history = useHistory()
    const [form, setForm] = useState({
        projectId: 0,
        userId: 0,
        browser: 'Firefox',
        os: 'Windows',
        server: '',
        data: '',
        description: '',
        caseId: 0,
    })

    // logout + error
    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    // Активация input для materialize
    useEffect(() => {
        try {
            if (!loading) {
                window.M.updateTextFields()
                window.M.FormSelect.init(document.querySelectorAll('select'))
                // window.M.textareaAutoResize(document.querySelectorAll('textarea'))
            }
        } catch (e) {
            console.log(e)
        }
    })

    const getProject = useCallback(async (token, id) => {
        try {
            if (token && id > 0) {
                const data = await request(`/api/project/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
                setProject(data.project)
            }
            if (token) {
                const data = await request(`/api/project/projects`, 'POST', null, {authorization: 'Bearer ' + token})
                setProjects(data.projects)
                if (!recordId && (!id || id <= 0)) setProject(data.projects[0])

                if (data.projects && data.projects.length && form.projectId === 0) {
                    setForm(prev => {return { ...prev, projectId: data.projects[0].id }})
                }
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    const getRecord = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/record/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
                data.record && data.record.projectId ? setForm(data.record) : setForm(prev => {return {...data.record, projectId: prev.projectId, userId: user.id }})
                // setForm(data.record)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])


    useEffect(() => {
        try {
            if (recordId) {
                getProject(token, id)
                getRecord(token, recordId)
                return
            }

            if (id > 0) {
                // prj.toggle(false, id)
                getProject(token, id)
                setForm({ ...form, projectId: id, userId: user.id })
            } else {
                getProject(token, null)
                setForm({ ...form, userId: user.id })
            }
        } catch (e) {
            console.log(e)
        }
    }, [getProject])

    useEffect(() => {
        console.log(form)
    }, [form])


    // useEffect(() => {
    //     console.log('projects changed')
    //     if (projects && projects.length && form.projectId === 0) {
    //         console.log('set project id')
    //         setForm(prev => {return { ...prev, projectId: projects[0].id }})
    //     }
    // }, [projects])


    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value})
        if (projects && projects.length && form.projectId === 0) {
            setForm(prev => {return { ...prev, projectId: projects[0].id }})
        }
    }

    const updateHandler = async () => {
        try {
            if (recordId) {
                const data = await request(`/api/record/${recordId}`, 'PUT', {form}, {authorization: 'Bearer ' + token})
                message(data.message)
            } else {
                const data = await request('/api/record/create', 'POST', {...form}, {authorization: 'Bearer ' + token})
                message(data.message)
            }
            history.goBack()
        } catch (e) {}
    }

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <Header params={{
                title: `Создание записи`,
                btnL: {
                    actionHandler: ()=>{history.goBack()},
                    title: 'Отмена',
                    display: 'inline-block'
                },
                btnR: {
                    actionHandler: () => {},
                    title: '',
                    display: 'none'
                },
                loading
            }}/>

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    <h5>Создать запись</h5>
                    {/*<p className="txt-gray">Manage your project settings</p>*/}
                </div>
            </div>

            {/*FORM*/}
            <div className="row clear-row flex-row">
                <div className="col s5 mt-0 ml-0 pl-0">
                    {/*<div className="input-field">*/}
                        {/*<input*/}
                            {/*placeholder="FTP"*/}
                            {/*type="text"*/}
                            {/*id="ftp"*/}
                            {/*className=""*/}
                            {/*name="ftp"*/}
                            {/*autoComplete="off"*/}
                            {/*value={form.ftp}*/}
                            {/*onChange={changeHandler}*/}
                        {/*/>*/}
                        {/*<label htmlFor="ftp">FTP</label>*/}
                    {/*</div>*/}

                    {/*TITLE*/}
                    <div className="input-field">
                        <input
                            type="text"
                            id="description"
                            className=""
                            name="description"
                            autoComplete="off"
                            value={form.description}
                            onChange={changeHandler}
                        />
                        <label htmlFor="description">Заголовок</label>
                    </div>

                    {/*OS*/}
                    <div className="input-field">
                        <select
                            id="os"
                            name="os"
                            value={form.os}
                            onChange={changeHandler}
                        >
                            <option value="Windows" dangerouslySetInnerHTML={{__html: `<div style="display: flex; justify-content: start; align-items: center" ><img style="margin-right: 1rem; overflow: hidden; width: 30px; height: 30px" src="/assets/icons/windows.png" alt=""/> <span>Windows</span></div>`}}/>
                            <option value="MacOs" dangerouslySetInnerHTML={{__html: `<div style="display: flex; justify-content: start; align-items: center" ><img style="margin-right: 1rem; overflow: hidden; width: 30px; height: 30px" src="/assets/icons/apple.png" alt=""/> <span>MacOs</span></div>`}}/>
                            <option value="Mobile" dangerouslySetInnerHTML={{__html: `<div style="display: flex; justify-content: start; align-items: center" ><img style="margin-right: 1rem; overflow: hidden; width: 30px; height: 30px" src="/assets/icons/mobile.png" alt=""/> <span>Mobile</span></div>`}}/>
                        </select>
                        <label htmlFor="os">OS</label>
                    </div>

                    {/*BROWSER*/}
                    <div className="input-field">
                        <select
                            id="browser"
                            name="browser"
                            value={form.browser}
                            onChange={changeHandler}
                        >
                            <option value="Firefox" dangerouslySetInnerHTML={{__html: `<div style="display: flex; justify-content: start; align-items: center" ><img style="margin-right: 1rem; overflow: hidden; width: 30px; height: 30px" src="/assets/icons/firefox.png" alt=""/> <span>Firefox</span></div>`}}/>
                            <option value="Opera" dangerouslySetInnerHTML={{__html: `<div style="display: flex; justify-content: start; align-items: center" ><img style="margin-right: 1rem; overflow: hidden; width: 30px; height: 30px" src="/assets/icons/opera.png" alt=""/> <span>Opera</span></div>`}}/>
                            <option value="Chrome" dangerouslySetInnerHTML={{__html: `<div style="display: flex; justify-content: start; align-items: center" ><img style="margin-right: 1rem; overflow: hidden; width: 30px; height: 30px" src="/assets/icons/chrome.png" alt=""/> <span>Chrome</span></div>`}}/>
                            <option value="IE" dangerouslySetInnerHTML={{__html: `<div style="display: flex; justify-content: start; align-items: center" ><img style="margin-right: 1rem; overflow: hidden; width: 30px; height: 30px" src="/assets/icons/ie.png" alt=""/> <span>IE</span></div>`}}/>
                        </select>
                        <label htmlFor="browser">Браузер</label>
                    </div>

                    {/*Server*/}
                    <div className="input-field">
                        <input
                            type="text"
                            id="server"
                            className=""
                            name="server"
                            autoComplete="off"
                            value={form.server}
                            onChange={changeHandler}
                        />
                        <label htmlFor="server">Сервер (http(s)://IP:port)</label>
                    </div>

                    {/*<div className="input-field">*/}
                        {/*<textarea*/}
                            {/*id="data"*/}
                            {/*name="data"*/}
                            {/*value={form.data}*/}
                            {/*onChange={changeHandler}*/}
                            {/*className="materialize-textarea"*/}
                        {/*/>*/}
                        {/*<label htmlFor="data">Data</label>*/}
                    {/*</div>*/}

                    {/*<div className="input-field">*/}
                        {/*<textarea*/}
                            {/*id="description"*/}
                            {/*name="description"*/}
                            {/*value={form.description}*/}
                            {/*onChange={changeHandler}*/}
                            {/*className="materialize-textarea"*/}
                        {/*/>*/}
                        {/*<label htmlFor="description">Description</label>*/}
                    {/*</div>*/}

                </div>

                <div className="col s1 mt-0 ml-0 pl-0">
                </div>

                <div className="col s5 mt-0 ml-0 pl-0">
                    {/*PROJECT*/}
                    <div className="input-field">
                        <select
                            id="project"
                            name="projectId"
                            value={form.projectId}
                            onChange={changeHandler}
                        >
                            {projects && projects.length ? projects.map(p => {
                                return (
                                    <option key={p.id} value={p.id}>{p.name}</option>
                                )
                            }) : (<option value=""></option>)}
                        </select>
                        <label htmlFor="project">Проект</label>
                    </div>

                    {/*USER*/}
                    <div className="input-field">
                        <p><span style={{fontWeight: 'bold'}}>Создатель:</span> {user.email || ''}</p>
                    </div>

                    <div className="input-field">
                        <button
                            className="waves-effect waves-light btn blue lighten-1 noUpper"
                            onClick={updateHandler}
                            disabled={loading}
                        >
                            Сохранить
                        </button>
                    </div>
                </div>

            </div>

        </>
    )

}

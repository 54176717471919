import React from 'react'
import {useParams} from "react-router-dom"
import {RecordForm} from "./RecordForm"

export const EditRecordPage = () => {
    const id = useParams().recordId

    return (
        <>
            <RecordForm recordId={id} />
        </>
    )
}

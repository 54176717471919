import React from 'react'
import {Loader} from "./Loader"

export const SimpleColorProgress = ({props}) => {

    // props = [
    //     {
    //         value: 60,
    //         color: '#eb4d4b'
    //     },
    //     {
    //         value: 7,
    //         color: '#22a6b3'
    //     },
    //     {
    //         value: 23,
    //         color: '#6ab04c'
    //     },
    //     {
    //         value: 10,
    //         color: '#e056fd'
    //     }
    // ]

    if (!props) {
        return (<></>)
    }

    return (
        <div className="multicolor-bar">
            <div className="bars">
                {props && props.length ? props.map((item, i) => {
                    if(item.value > 0) {
                        return (
                            <div className="bar" style={{'backgroundColor': item.color, 'width': item.value + '%'}}  key={i}>

                            </div>
                        )
                    }
                }) : (<></>)}
            </div>
        </div>
    )
}
import React from 'react'
import {ContainerForm} from "./ContainerForm"

export const CreateContainersPage = () => {
    return (
        <>
            <ContainerForm agentId={0} />
        </>
    )
}

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { AuthContext } from "../../context/AuthContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"
import { Header } from "../partials/Header"
import { Icon } from "../partials/Icon"
import { Loader } from "../partials/Loader"
import { NoTable } from "../partials/NoTable"

export const AllAgentsPage = () => {
    const { token, logout } = useContext(AuthContext)
    const { loading, request, error, clearError } = useHttp()
    // const [project, setProject] = useState(null)
    const [projects, setProjects] = useState(null)
    const [users, setUsers] = useState(null)
    const message = useMessage()
    const history = useHistory()
    const [agents, setAgents] = useState([])
    const [isActive, setIsActive] = useState('All')
    const [containerized, setContainerized] = useState([])

    // logout + error
    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
    })

    const getProject = useCallback(async (token, id) => {
        try {
            // if (token && id) {
            //     const data = await request(`/api/project/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
            //     setProject(data.project)
            //     // setCycles(data.cycles)
            //     // setReleases(data.releases)
            //     // if (data.cycles.length && data.releases.length) {
            //     //     setCaseForm({...caseForm, cycleId: data.cycles[0].id, release: data.releases[0].id})
            //     // }
            //     // console.log(data)
            // }
            if (token) {
                const data = await request(`/api/project/projects`, 'POST', null, { authorization: 'Bearer ' + token })
                setProjects(data.projects)
                // setProject(data.projects[0])
                // setCycles(data.cycles)
                // setReleases(data.releases)
                // if (data.cycles.length && data.releases.length) {
                //     setCaseForm({...caseForm, cycleId: data.cycles[0].id, release: data.releases[0].id})
                // }
                // console.log(data)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    // get users data
    const fetchUsers = useCallback(async () => {
        try {
            const data = await request(`/api/auth/users`, 'POST', null, { authorization: 'Bearer ' + token })
            if (data.users) {
                setUsers(data.users)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request, setUsers])

    const getAgents = useCallback(async (token) => {
        try {
            if (token) {
                const data = await request(`/api/agent/all`, 'POST', { projectId: null }, { authorization: 'Bearer ' + token })
                const containerized = await request(`/api/container/all`, 'POST', { projectId: null }, { authorization: 'Bearer ' + token })
                setContainerized(containerized.containers)
                setAgents(data.agents)
            }
        } catch (e) {
            console.log(e)
        }
    }, [])


    console.log("agents", agents)


    useEffect(() => {
        try {
            getProject(token, null)
            getAgents(token)
            fetchUsers()
        } catch (e) {
            console.log(e)
        }
    }, [])

    if (loading) {
        return <Loader />
    }

    // header buttons
    const createAgent = () => {
        history.push(`/agents/createAgents`)
    }

    const createContainer = () => {
        history.push(`/agents/createContainers`)
    }


    // header buttons
    const createTestCase = () => {
        history.push(`/testCases/createTestCase`)
    }

    const deleteHandler = async (id) => {
        if (isActive === "All") {
            try {
                const data = await request(`/api/agent/${id}`, 'DELETE', null, { authorization: 'Bearer ' + token })
                message(data.message)

                let agent = agents.filter(el => el.id !== id)
                setAgents([...agent])
            } catch (e) { }
        } else {
            try {
                const data = await request(`/api/container/${id}`, 'DELETE', null, { authorization: 'Bearer ' + token })
                message(data.message)

                let container = containerized.filter(el => el.id !== id)
                setContainerized([...container])
            } catch (e) { }
        }
    }

    return (
        <>
            <Header params={{
                title: `Агенты`,
                btnL: isActive === "All" ? {
                    actionHandler: createAgent,
                    title: '+ Агент',
                    display: 'inline-block'
                }
                    :
                    {
                        actionHandler: createContainer,
                        title: '+ Контейнер',
                        display: 'inline-block'
                    }
                ,
                btnR: {
                    actionHandler: createTestCase,
                    title: '+ Тест кейс',
                    display: 'inline-block'
                },
                loading
            }} />

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    <h5>Агенты</h5>
                    {/*<Icon name="windows" />*/}
                    {/*<p className="txt-gray">Manage your project settings</p>*/}
                    <div>
                        <button
                            className={`waves-effect waves-light btn btn-flat noUpper mr-1 my ${isActive === "All" ? 'active-hover' : ''}`}
                            style={{ color: 'black' }}
                            onClick={(e) => {
                                setIsActive("All")
                            }}
                            disabled={loading}
                        >
                            Все
                        </button>

                        <button
                            className={`waves-effect waves-light btn btn-flat noUpper mr-1 my ${!isActive === "Containerized" ? 'active-hover' : ''}`}
                            name="isEdit"
                            onClick={(e) => {
                                setIsActive("Containerized")
                            }}
                            disabled={loading}
                        >
                            В контейнере
                        </button>
                    </div>
                </div>
            </div>



            <div className="row clear-row flex-row">

                {
                    isActive === "All"
                        ?
                        <div className="row clear-row flex-row w-100">
                            {agents && agents.length ? (
                                <div className="col s12 pl-0">
                                    <table className="highlight">
                                        <thead>
                                            <tr>
                                                {/*<th className="txt-gray ">id</th>*/}
                                                {/*<th className="txt-gray ">Name</th>*/}
                                                {/*<th className="txt-gray ">Data</th>*/}
                                                {/*<th className="txt-gray ">IP</th>*/}
                                                {/*<th className="txt-gray ">Description</th>*/}
                                                {/*<th className="txt-gray ">User</th>*/}
                                                {/*<th className="txt-gray ">Project</th>*/}
                                                {/*<th className="txt-gray ">Actions</th>*/}
                                                <th className="txt-gray ">Пользователь</th>
                                                <th className="txt-gray ">Расположение</th>
                                                <th className="txt-gray ">Описание</th>
                                                <th className="txt-gray " />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {agents && agents.length ? agents.map(c => {
                                                return (
                                                    <React.Fragment key={c.id}>
                                                        {/*<tr*/}
                                                        {/*key={c.id}*/}
                                                        {/*>*/}
                                                        {/*<td className="">{c.id}</td>*/}
                                                        {/*<td className="">{c.name || '-'}</td>*/}
                                                        {/*<td className="">{c.data || '-'}</td>*/}
                                                        {/*<td className="">{c.ip || '-'}</td>*/}
                                                        {/*<td className="">{c.description || '-'}</td>*/}
                                                        {/*<td className="">{users ? users.find(el => el.id === c.userId).name : '-'}</td>*/}
                                                        {/*<td className="">{projects && projects.length ? projects.find(p => p.id === c.projectId).name : '-'}</td>*/}
                                                        {/*<td className="">*/}
                                                        {/*<i*/}
                                                        {/*style={{cursor: 'pointer'}}*/}
                                                        {/*className="fa fa-pencil-square-o"*/}
                                                        {/*aria-hidden="true"*/}
                                                        {/*// onClick={() => {history.push(`/project/${prj.id}/testCases/${c.id}`)}}*/}
                                                        {/*/>*/}
                                                        {/*<i*/}
                                                        {/*style={{cursor: 'pointer'}}*/}
                                                        {/*className="fa fa-trash-o pl-1"*/}
                                                        {/*aria-hidden="true"*/}
                                                        {/*onClick={() => {deleteHandler(c.id)}}*/}
                                                        {/*/>*/}
                                                        {/*</td>*/}
                                                        {/*</tr>*/}
                                                        <tr
                                                            key={c.id}
                                                        >
                                                            <td className="">{users ? users.find(el => el.id === c.userId).email : '-'}</td>
                                                            <td className="">
                                                                {c.name === 'Windows' ? (<Icon name="windows" />) : (c.name === 'MacOs' ? (<Icon name="apple" />) : (<Icon name="mobile" />))}
                                                                {c.data === 'Firefox' ? (<Icon name="firefox" />) : (c.data === 'Opera' ? (<Icon name="opera" />) : (c.data === 'Chrome' ? (<Icon name="chrome" />) : (<Icon name="ie" />)))}
                                                            </td>
                                                            <td className="">{c.description || '-'}</td>
                                                            <td className="">
                                                                <i
                                                                    style={{ cursor: 'pointer' }}
                                                                    className="fa fa-pencil-square-o opacity-0"
                                                                    aria-hidden="true"
                                                                    onClick={() => { history.push(`/agents/agent/${c.id}`) }}
                                                                />
                                                                <i
                                                                    style={{ cursor: 'pointer' }}
                                                                    className="fa fa-trash-o pl-1 opacity-0"
                                                                    aria-hidden="true"
                                                                    onClick={() => { deleteHandler(c.id) }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            }) : (<></>)}
                                        </tbody>
                                        <tfoot>
                                            {/*<tr onClick={addExecHandler}><td colSpan="7" className="center"><ul><li style={{cursor: 'pointer'}}>+ Add new execution</li></ul></td></tr>*/}
                                        </tfoot>
                                    </table>
                                </div>
                            ) : (<NoTable params={{ title: `Агентов нет` }} />)}
                        </div>
                        :
                        <div className="row clear-row flex-row w-100">
                            {containerized && containerized.length ? (
                                <div className="col s12 pl-0">
                                    <table className="highlight">
                                        <thead>
                                            <tr>
                                                {/*<th className="txt-gray ">id</th>*/}
                                                {/*<th className="txt-gray ">Name</th>*/}
                                                {/*<th className="txt-gray ">Data</th>*/}
                                                {/*<th className="txt-gray ">IP</th>*/}
                                                {/*<th className="txt-gray ">Description</th>*/}
                                                {/*<th className="txt-gray ">User</th>*/}
                                                {/*<th className="txt-gray ">Project</th>*/}
                                                {/*<th className="txt-gray ">Actions</th>*/}
                                                <th className="txt-gray ">Пользователь</th>
                                                <th className="txt-gray ">Расположение</th>
                                                <th className="txt-gray ">Описание</th>
                                                <th className="txt-gray " />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {containerized && containerized.length ? containerized.map(c => {
                                                return (
                                                    <React.Fragment key={c.id}>
                                                        {/*<tr*/}
                                                        {/*key={c.id}*/}
                                                        {/*>*/}
                                                        {/*<td className="">{c.id}</td>*/}
                                                        {/*<td className="">{c.name || '-'}</td>*/}
                                                        {/*<td className="">{c.data || '-'}</td>*/}
                                                        {/*<td className="">{c.ip || '-'}</td>*/}
                                                        {/*<td className="">{c.description || '-'}</td>*/}
                                                        {/*<td className="">{users ? users.find(el => el.id === c.userId).name : '-'}</td>*/}
                                                        {/*<td className="">{projects && projects.length ? projects.find(p => p.id === c.projectId).name : '-'}</td>*/}
                                                        {/*<td className="">*/}
                                                        {/*<i*/}
                                                        {/*style={{cursor: 'pointer'}}*/}
                                                        {/*className="fa fa-pencil-square-o"*/}
                                                        {/*aria-hidden="true"*/}
                                                        {/*// onClick={() => {history.push(`/project/${prj.id}/testCases/${c.id}`)}}*/}
                                                        {/*/>*/}
                                                        {/*<i*/}
                                                        {/*style={{cursor: 'pointer'}}*/}
                                                        {/*className="fa fa-trash-o pl-1"*/}
                                                        {/*aria-hidden="true"*/}
                                                        {/*onClick={() => {deleteHandler(c.id)}}*/}
                                                        {/*/>*/}
                                                        {/*</td>*/}
                                                        {/*</tr>*/}
                                                        <tr
                                                            key={c.id}
                                                        >
                                                            <td className="">{users ? users.find(el => el.id === c.userId).email : '-'}</td>
                                                            <td className="">
                                                                <Icon name="container" />

                                                            </td>
                                                            <td className="">{c.description || '-'}</td>
                                                            <td className="">
                                                                <i
                                                                    style={{ cursor: 'pointer' }}
                                                                    className="fa fa-pencil-square-o opacity-0"
                                                                    aria-hidden="true"
                                                                    onClick={() => { history.push(`/agents/container/${c.id}`) }}
                                                                />
                                                                <i
                                                                    style={{ cursor: 'pointer' }}
                                                                    className="fa fa-trash-o pl-1 opacity-0"
                                                                    aria-hidden="true"
                                                                    onClick={() => { deleteHandler(c.id) }}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            }) : (<></>)}
                                        </tbody>
                                        <tfoot>
                                            {/*<tr onClick={addExecHandler}><td colSpan="7" className="center"><ul><li style={{cursor: 'pointer'}}>+ Add new execution</li></ul></td></tr>*/}
                                        </tfoot>
                                    </table>
                                </div>
                            ) : (<NoTable params={{ title: `Нет агентов в контейнерах` }} />)}
                        </div>

                }

                {/* <NoTable params={{ title: `${isActive ? 'No active or planned releases' : 'No closed or canceled releases'}` }} /> */}
            </div>







        </>
    )

}
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom"
// import {SidebarContext} from "../../context/SidebarContext"
import {Loader} from "../partials/Loader"
import {AuthContext} from "../../context/AuthContext"
import {useHttp} from "../../hooks/http.hook"
import {useMessage} from "../../hooks/message.hook"
import {Header} from "../partials/Header"

export const ReleaseForm = ({releaseId}) => {
    const { token, user, logout } = useContext(AuthContext)
    const {loading, request, error, clearError} = useHttp()
    const id = useParams().id || null
    // const prj = useContext(SidebarContext)
    const [project, setProject] = useState(null)
    const [projects, setProjects] = useState(null)
    const [cycles, setCycles] = useState([])
    const message = useMessage()
    const history = useHistory()
    const [form, setForm] = useState({
        projectId: 0,
        userId: 0,
        name: '',
        start: new Date().toLocaleDateString(),
        end: new Date().toLocaleDateString(),
        status: 'Active',
        cycles: []

    })

    // logout + error
    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    // ru date to ISO
    const toISO = (d) => {
        d = d.split('.')
        return `${d[2]}-${d[1]}-${d[0]}`
    }
    // Активация input для materialize
    useEffect(() => {
        if (!loading) {
            window.M.updateTextFields()
            window.M.FormSelect.init(document.querySelectorAll('select'),  {classes: 'black-text'})
            window.M.Datepicker.init(document.getElementById('start'), {format: 'dd.mm.yyyy', onSelect: (date)=>{
                    setForm({ ...form, start: date.toLocaleDateString() })
                    // if (new Date() < new Date(toISO(date.toLocaleDateString()))) {
                    //     setForm(prev => {return {...prev, status: 'Planned'}})
                    // }
                    // if (new Date() > new Date(toISO(date.toLocaleDateString())) && new Date() < new Date(toISO(form.end))) {
                    //     setForm(prev => {return {...prev, status: 'Active'}})
                    // }
                    // if (new Date() > new Date(toISO(form.end))) {
                    //     setForm(prev => {return {...prev, status: 'Closed'}})
                    // }

                }
            })
            window.M.Datepicker.init(document.getElementById('end'), {format: 'dd.mm.yyyy', onSelect: (date)=>{
                setForm({ ...form, end: date.toLocaleDateString() })
                    // if (new Date() < new Date(toISO(form.start))) {
                    //     setForm(prev => {return {...prev, status: 'Planned'}})
                    // }
                    // if (new Date() > new Date(toISO(form.start)) && new Date() < new Date(toISO(date.toLocaleDateString()))) {
                    //     setForm(prev => {return {...prev, status: 'Active'}})
                    // }
                    // if (new Date() > new Date(toISO(date.toLocaleDateString()))) {
                    //     setForm(prev => {return {...prev, status: 'Closed'}})
                    // }
                }
            })
        }
    })

    const getProject = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/project/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
                setProject(data.project)
            }
            if (token) {
                const data = await request(`/api/project/projects`, 'POST', null, {authorization: 'Bearer ' + token})
                setProjects(data.projects)
                if (!releaseId && !id) setForm(prev => {return { ...prev, projectId: data.projects[0].id }})
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    const getCycles = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/cycle/all`, 'POST', {projectId: id}, {authorization: 'Bearer ' + token})
                setCycles(data.cycles)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])


    // if releaseId !== 0 this is EDIT, not create.
    // Then get cycle by id and set same variables
    const getRelease = useCallback(async () => {
        try {
            // get case by id (with labels, steps, cycles, releases
            const data = await request(`/api/release/${releaseId}`, 'GET', null, {authorization: 'Bearer ' + token})
            console.log('rel', data.release)
            // setForm
            setForm(prev => { return {...prev, ...data.release} })
        } catch (e) {
            console.log(e)
        }
    }, [request])


    useEffect(async () => {
        try {
            // prj.toggle(false, id)
            getProject(token, id)
            if (releaseId) {
                await getRelease()
            } else {
                if (id) {
                    setForm(prev => { return {...prev, projectId: Number(id), userId: user.id }})
                } else {
                    setForm(prev => { return {...prev, userId: user.id }})
                }
            }
            setForm(prev => { return {...prev, userId: user.id }})

            // getCycles(token)
        } catch (e) {
            console.log(e)
        }
    }, [releaseId, getRelease])

    useEffect(() => {
        try {
            if (form.projectId) getCycles(token, form.projectId)
        } catch (e) {
            console.log(e)
        }
    }, [form.projectId])

    useEffect(() => {
        console.log('form', form)
        console.log('cycles', cycles)
    }, [form])


    const changeHandler = event => {
        if (event.target.name === 'cycles') {
            let value = Array.from(event.target.selectedOptions, option => Number(option.value))
            setForm({ ...form, [event.target.name]: value })
        } else {
            setForm({ ...form, [event.target.name]: event.target.value })
        }
    }

    const updateHandler = async () => {
        try {
            if (releaseId) {
                // UPDATE
                const data = await request(`/api/release/${releaseId}`, 'PUT', {form}, {authorization: 'Bearer ' + token})
                message(data.message)

                history.goBack()
            } else {
                // CREATE
                const data = await request('/api/release/create', 'POST', {...form}, {authorization: 'Bearer ' + token})
                message(data.message)
                history.goBack()
            }
        } catch (e) {}
    }

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <Header params={{
                title: !releaseId ? `Создать релиз` : `Изменить релиз`,
                btnL: {
                    actionHandler: ()=>{history.goBack()},
                    title: 'Отмена',
                    display: 'inline-block'
                },
                btnR: {
                    actionHandler: () => {},
                    title: '',
                    display: 'none'
                },
                loading
            }}/>

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    <h5>{!releaseId ? `Создать релиз` : `Изменить релиз`}</h5>
                    {/*<p className="txt-gray">Manage your project settings</p>*/}
                </div>
            </div>

            {/*FORM*/}
            {/*TITLE*/}
            <div className="row clear-row flex-row">
                <div className="col s5 mt-0 ml-0 pl-0">
                    {/*TITLE*/}
                    <div className="input-field">
                        <input
                            type="text"
                            id="name"
                            className=""
                            name="name"
                            autoComplete="off"
                            value={form.name}
                            onChange={changeHandler}
                        />
                        <label htmlFor="name">Заголовок релиза</label>
                    </div>

                    {/*STATUS*/}
                    <div className="input-field">
                        <select
                            id="status"
                            name="status"
                            value={form.status}
                            onChange={changeHandler}
                        >
                            {/*<option value="Planned">Planned</option>*/}
                            <option value="Active">Активен</option>
                            <option value="Closed">Закрыт</option>
                        </select>
                        <label htmlFor="status">Статус</label>
                    </div>

                    {/*DATE START*/}
                    <div className="input-field">
                        <input
                            type="text"
                            id="start"
                            className="datepicker"
                            name="start"
                            value={form.start}
                            onChange={changeHandler}
                        />
                        <label htmlFor="start">Дата начала</label>
                    </div>


                </div>

                <div className="col s1 mt-0 ml-0 pl-0">
                </div>

                <div className="col s5 mt-0 ml-0 pl-0">
                    {/*PROJECT*/}
                    <div className="input-field">
                        <select
                            id="project"
                            name="projectId"
                            value={form.projectId}
                            onChange={changeHandler}
                        >
                            {projects && projects.length ? projects.map(p => {
                                return (
                                    <option key={p.id} value={p.id}>{p.name}</option>
                                )
                            }) : (<option value=""></option>)}
                        </select>
                        <label htmlFor="project">Проект</label>
                    </div>

                    {/*Cycles*/}
                    <div className="input-field">
                        <select
                            id="cycles"
                            name="cycles"
                            multiple={true}
                            value={form.cycles}
                            onChange={changeHandler}
                        >
                            {cycles && cycles.length ? cycles.map(c => {
                                return (
                                    <option key={c.id} value={c.id}>{c.name}</option>
                                )
                            }) : (<option value=""></option>)}
                        </select>
                        <label htmlFor="cycles">Циклы</label>
                    </div>

                    {/*STATUS*/}
                    {/*<div className="input-field">*/}
                        {/*<select*/}
                            {/*id="status"*/}
                            {/*name="status"*/}
                            {/*value={form.status}*/}
                            {/*onChange={changeHandler}*/}
                        {/*>*/}
                            {/*<option value="Planned">Planned</option>*/}
                            {/*<option value="Active">Active</option>*/}
                            {/*<option value="Closed">Closed</option>*/}
                        {/*</select>*/}
                        {/*<label htmlFor="status">Status</label>*/}
                    {/*</div>*/}
                    {/*<div className="input-field stat-dis">*/}
                        {/*<input*/}
                            {/*type="text"*/}
                            {/*id="status"*/}
                            {/*className=""*/}
                            {/*name="status"*/}
                            {/*value={form.status}*/}
                            {/*autoComplete="off"*/}
                            {/*disabled*/}
                        {/*/>*/}
                        {/*<label htmlFor="status">Status</label>*/}
                    {/*</div>*/}

                    {/*DATE END*/}
                    <div className="input-field">
                        <input
                            type="text"
                            id="end"
                            className="datepicker"
                            name="end"
                            value={form.end}
                            onChange={changeHandler}
                        />
                        <label htmlFor="end">Дата окончания</label>
                    </div>

                </div>
            </div>

            <div className="row clear-row flex-row">
                <div className="col s5 mt-0 ml-0 pl-0">
                    <div className="input-field">
                        <button
                            className="waves-effect waves-light btn blue lighten-1 noUpper"
                            onClick={updateHandler}
                            disabled={loading}
                        >
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>

        </>
    )

}

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router-dom"
import { AuthContext } from "../../context/AuthContext"
import { SidebarContext } from "../../context/SidebarContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"
import { Header } from "../partials/Header"
import { Loader } from "../partials/Loader"
import { NoTable } from "../partials/NoTable"


export const ProjectReleasesPage = () => {
    const { token, logout } = useContext(AuthContext)
    const {loading, request, error, clearError} = useHttp()
    const id = useParams().id || null
    const prj = useContext(SidebarContext)
    const [project, setProject] = useState(null)
    const [projects, setProjects] = useState(null)
    const [users, setUsers] = useState(null)
    const message = useMessage()
    const history = useHistory()
    const [releases, setReleases] = useState([])
    const [isActive, setIsActive] = useState(true)
    const [progress, setProgress] = useState(0)

    let modals

    // logout + error
    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
        window.M.FormSelect.init(document.querySelectorAll('select'),  {classes: 'black-text'})
        let elems = document.querySelectorAll('.modal')
        modals = window.M.Modal.init(elems, {})
    })

    const getProject = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/project/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
                setProject(data.project)
            }
            if (token) {
                const data = await request(`/api/project/projects`, 'POST', null, {authorization: 'Bearer ' + token})
                setProjects(data.projects)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    const progr = (data) => {
        if (data && data.length) {
            data.map(rel => {
                let perc = 0

                let relExecs = []

                if (rel.cycles.length) {
                    rel.cycles.forEach(c => {
                        if (c.executions.length && c.executions.filter(e => e.releaseId === rel.id).length) {
                            relExecs = [...relExecs, ...c.executions.filter(e => e.releaseId === rel.id)]
                        }
                    })
                }

                rel.executions = relExecs

                let total = rel.executions.length
                let unexec = rel.executions.filter(ex => ex.status === 'Не выполнено').length
                let succes = rel.executions.filter(ex => ex.status === 'Успех').length
                let fail = rel.executions.filter(ex => ex.status === 'Неудача').length

                perc = Math.round(succes*100/total)

                rel.perc = perc
                rel.planned = unexec === total
                rel.active = succes > 0 || fail > 0 || rel.planned
                if (perc === 100 || rel.status === 'Закрыто') rel.active = false

                return rel
            })

            return data
        }
    }

    const getReleases = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/release/all`, 'POST', {projectId: id}, {authorization: 'Bearer ' + token})
                data.releases = data.releases.map(el => {
                    el.showDetails = false
                    return el
                })
                data.releases = progr(data.releases)
                setReleases(data.releases)

            }
            if (token && !id) {
                const data = await request(`/api/release/all`, 'POST', {projectId: null}, {authorization: 'Bearer ' + token})
                data.releases = progr(data.releases)
                data.releases = data.releases.map(el => {
                    el.showDetails = false
                    return el
                })
                setReleases(data.releases)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

     // get users data
    const fetchUsers = useCallback(async () => {
        try {
            const data = await request(`/api/auth/users`, 'POST', null, {authorization: 'Bearer ' + token})
            if (data.users) {
                setUsers(data.users)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request, setUsers])

    useEffect(() => {
        try {
            console.log('id', id)
            prj.toggle(false, id)
            getProject(token, id)
            getReleases(token, id)
            // getCycles(token, id)
            fetchUsers()
        } catch (e) {
            console.log(e)
        }
    }, [getReleases, getProject, fetchUsers, id])
    
    useEffect(() => {
        // console.log('Exec', execRows)
        // console.log('isActive', isActive)
        console.log('rels', releases)
    }, [isActive, releases])

    if (loading) {
        return <Loader />
    }

    const caseCount = (relId) => {
        let count = 0

        let idx = releases.findIndex(el => el.id === relId)
        if (releases[idx] && releases[idx].cycles.length) {
            releases[idx].cycles.forEach(c => {
                if (c.cases.length) {
                    count += c.cases.length
                }
            })
        }

        return count
    }

    // header buttons
    const createRelease = () => {
        if (id) {
            history.push(`/project/${prj.id}/createRelease`)
        } else {
            history.push(`/releases/createRelease`)
        }
    }

    // header buttons
    const createTestCase = () => {
        if (id) {
            history.push(`/project/${prj.id}/createTestCase`)
        } else {
            history.push(`/testCases/createTestCase`)
        }
    }

    const deleteHandler = async (releaseId) => {
        try {
            const data = await request(`/api/release/${releaseId}`, 'DELETE', null, {authorization: 'Bearer ' + token})
            message(data.message)

            setReleases([...releases.filter(el => el.id !== releaseId)])
        } catch (e) {}
    }

    return (
        <>
            <Header params={{
                title: `Релизы ${id && project ? project.name : ''}`,
                btnL: {
                    actionHandler: createRelease,
                    title: '+ Релиз',
                    display: 'inline-block'
                },
                btnR: {
                    actionHandler: createTestCase,
                    title: '+ Тест кейс',
                    display: 'inline-block'
                },
                loading
            }}/>

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    <h5>Релизы</h5>
                    {/*<p className="txt-gray">Manage your project settings</p>*/}
                    <div>
                        <button
                            className={`waves-effect waves-light btn btn-flat noUpper mr-1 my ${isActive ? 'active-hover' : ''}`}
                            style={{color: 'black'}}
                            onClick={(e) => {
                                setIsActive(true)
                            }}
                            disabled={loading}
                        >
                            Активные
                        </button>

                        <button
                            className={`waves-effect waves-light btn btn-flat noUpper mr-1 my ${!isActive ? 'active-hover' : ''}`}
                            name="isEdit"
                            onClick={(e) => {
                                setIsActive(false)
                            }}
                            disabled={loading}
                        >
                            Выпущенные
                        </button>
                    </div>
                </div>
            </div>

            <div className="row clear-row flex-row">
                {releases && releases.length && releases.filter(r => {
                    if (isActive) {
                        return r.active
                    } else {
                        return !r.active
                    }
                }).length ? (
                    <div className="col s12 pl-0">
                        <table className="highlight">
                            <thead>
                            <tr>
                                <th className="txt-gray ">Заголовок</th>
                                <th className="txt-gray ">Проект</th>
                                <th className="txt-gray ">Руководитель</th>
                                <th className="txt-gray ">Тест кейсы</th>
                                <th className="txt-gray ">Даты</th>
                                <th className="txt-gray ">Статус</th>
                                <th className="txt-gray " />
                            </tr>
                            </thead>
                            <tbody>
                            {releases && releases.length ? releases.filter(r => {
                                if (isActive) {
                                    return r.active
                                } else {
                                    return !r.active
                                }
                            }).map(c => {
                                return(
                                    <React.Fragment key={c.id}>
                                        <tr
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {history.push(`/releases/show/${c.id}`)}}
                                        >
                                            <td className=""><i className="fa fa-folder-o" aria-hidden="true" />{c.name || '-'}</td>
                                            <td className="">{projects && projects.length ? projects.find(p => p.id === c.projectId).name : '-'}</td>
                                            <td className="pl-2" style={{position: 'relative'}}><span style={{position: 'absolute', left: '7px', top: '20px',  width: '15px', height: '15px', borderRadius: '50%', backgroundColor: '#d4c05b'}} />{users && users.length ? users.find(el => el.id === c.userId).email : '-'}</td>
                                            <td className="">{caseCount(c.id)}</td>
                                            <td className="">
                                                <i
                                                    className="fa fa-calendar-o"
                                                    aria-hidden="true"
                                                    style={{color: new Date() > new Date(c.end) ? 'red' : 'black'}}
                                                />
                                                {`${new Date(c.start).toLocaleDateString()} - ${new Date(c.end).toLocaleDateString()}`}
                                            </td>
                                            <td className="">{c.planned && c.status !== 'Закрыт' ? `Планирование` : (c.perc === 100 || c.status === 'Закрыт' ? `Выпущен` : `Active ${c.perc}%`) }</td>
                                            <td className="td-actions" style={{zIndex: '15'}}>
                                                <i
                                                    style={{cursor: 'pointer'}}
                                                    className="fa fa-pencil-square-o opacity-0"
                                                    aria-hidden="true"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                        history.push(`/project/releases/${c.id}`)}
                                                    }
                                                />
                                                <i
                                                    style={{cursor: 'pointer'}}
                                                    className="fa fa-trash-o pl-1 opacity-0"
                                                    aria-hidden="true"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                        deleteHandler(c.id)}
                                                    }
                                                />
                                            </td>
                                        </tr>

                                    </React.Fragment>
                                )
                            }) : (<></>)}
                            </tbody>
                        </table>
                    </div>
                ) : (<NoTable params={{ title: `${isActive ? 'Нет активных или запланированных релизов' : 'Не закрытых или отмененных релизов'}` }} />)}
            </div>


        </>
    )

}
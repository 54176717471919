import React from 'react'

export const Icon = ({name}) => {
    return (
        <img
            style={{overflow: 'hidden', width: '30px', height: '30px', marginRight: '1rem'}}
            src={`/assets/icons/${name}.png`}
            alt=""
        />
    )
}
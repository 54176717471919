import React from 'react'
import {RecordForm} from "./RecordForm"

export const CreateRecordPage = () => {

    return (
        <>
        <RecordForm recordId={0} />
        </>
    )
}

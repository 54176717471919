import React from 'react'
import {AgentForm} from "./AgentForm"

export const CreateAgentPage = () => {
    return (
        <>
            <AgentForm agentId={0} />
        </>
    )
}

import React from 'react'
import {AgentForm} from "./AgentForm"
import {useParams} from "react-router-dom"

export const EditAgentPage = () => {
    const id = useParams().agentId

    return (
        <>
            <AgentForm agentId={id} />
        </>
    )
}

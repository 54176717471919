import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom"
import {SidebarContext} from "../../context/SidebarContext"
import {Loader} from "../partials/Loader"
import {AuthContext} from "../../context/AuthContext"
import {useHttp} from "../../hooks/http.hook"
import {useMessage} from "../../hooks/message.hook"
import {Header} from "../partials/Header"
import {NoTable} from "../partials/NoTable"
import {SimpleColorProgress} from "../partials/SimpleColorProgress"

export const TestCyclesPage = () => {
    const { token, logout } = useContext(AuthContext)
    const {loading, request, error, clearError} = useHttp()
    const id = useParams().id || null
    const prj = useContext(SidebarContext)
    const [project, setProject] = useState(null)
    const [projects, setProjects] = useState(null)
    const message = useMessage()
    const history = useHistory()
    const [cycles, setCycles] = useState([])

    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    // Активация input для materialize
    useEffect(() => {
        try {
            window.M.updateTextFields()
            window.M.FormSelect.init(document.querySelectorAll('select'),  {classes: 'black-text'})
            let elems = document.querySelectorAll('.modal');
            // let instances = window.M.Modal.init(elems, {});
            window.M.Modal.init(elems, {});
        } catch (e) {
            console.log(e)
        }
    })

    const getProject = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/project/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
                setProject(data.project)
            }
            if (token) {
                const data = await request(`/api/project/projects`, 'POST', null, {authorization: 'Bearer ' + token})
                setProjects(data.projects)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    const getCycles = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/cycle/all`, 'POST', {projectId: id}, {authorization: 'Bearer ' + token})
                data.cycles = data.cycles.map(el => {
                    el.showDetails = false
                    return el
                })
                setCycles(data.cycles)
                console.log(data.cycles)
            }
            if (token && !id) {
                const data = await request(`/api/cycle/all`, 'POST', {projectId: null}, {authorization: 'Bearer ' + token})
                data.cycles = data.cycles.map(el => {
                    el.showDetails = false
                    return el
                })
                setCycles(data.cycles)
                console.log(data.cycles)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    useEffect(() => {
        try {
            getProject(token, id)
            getCycles(token, id)
        } catch (e) {
            console.log(e)
        }
    }, [id, getCycles, getProject])

    if (loading) {
        return <Loader />
    }

    const createCycle = () => {
        if (id) {
            history.push(`/project/${id}/createCycle`)
        } else {
            history.push(`/testCycles/createCycle`)
        }
    }

    const createTestCase = () => {
        if (id) {
            history.push(`/project/${id}/createTestCase`)
        } else {
            history.push(`/testCases/createTestCase`)
        }
    }

    const deleteHandler = async (cycleId) => {
        try {
            const data = await request(`/api/cycle/${cycleId}`, 'DELETE', null, {authorization: 'Bearer ' + token})
            message(data.message)

            setCycles([...cycles.filter(el => el.id !== cycleId)])
        } catch (e) {}
    }

    const progress = (releaseId, cycleId) => {
        let res = [{
            value: 100,
            color: '#efefef'
        }]
        let perc = 0

        let cycle = cycles.find(c => c.id === Number(cycleId))

        if (cycle && cycle.executions.length) {
            let total = cycle.executions.filter(ex => ex.releaseId === Number(releaseId)).length
            let unexec = cycle.executions.filter(ex => ex.releaseId === Number(releaseId) && ex.status === 'Unexecuted').length
            let succes = cycle.executions.filter(ex => ex.releaseId === Number(releaseId) && ex.status === 'Success').length
            let fail = cycle.executions.filter(ex => ex.releaseId === Number(releaseId) && ex.status === 'Fail').length

            res = [
                {
                    value: Math.round(succes*100/total),
                    color: '#75b000' //green
                },
                {
                    value: Math.round(fail*100/total),
                    color: '#cc3402' //red
                },
                {
                    value: Math.round(unexec*100/total),
                    color: '#efefef' //gray
                },
            ]

            perc = Math.round(succes*100/total)
        }

        return {res, perc}
    }

    const toggleCycle = (e, cl) => {
        if (e.target.tagName !== 'I') {
            e.target.parentElement.querySelector('.fa.fa-chevron-down').classList.toggle('d-none')
            e.target.parentElement.querySelector('.fa.fa-chevron-right').classList.toggle('d-none')
        } else {
            e.target.parentElement.querySelector('.fa.fa-chevron-down').classList.toggle('d-none')
            e.target.parentElement.querySelector('.fa.fa-chevron-right').classList.toggle('d-none')
        }
        let els = document.querySelectorAll(cl)
        if (els) {
            els.forEach(el => {
                el.classList.toggle('d-none')
            })
        }
    }

    return (
        <>
            <Header params={{
                title: `Циклы ${id && project ? project.name : ''}`,
                btnL: {
                    actionHandler: createCycle,
                    title: '+ Цикл',
                    display: 'inline-block'
                },
                btnR: {
                    actionHandler: createTestCase,
                    title: '+ Тест кейс',
                    display: 'inline-block'
                },
                loading
            }}/>

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    <h5>Test Cycles</h5>
                </div>
            </div>

            <div className="row clear-row flex-row">
                {cycles && cycles.length ? (
                    <div className="col s12 pl-0">
                        <table className="highlight">
                            <thead>
                            <tr>
                                <th className="txt-gray ">Заголовок [Проект]</th>
                                <th className="txt-gray ">Статус</th>
                                <th className="txt-gray ">Прогресс</th>
                                <th className="txt-gray " />
                            </tr>
                            </thead>
                            <tbody>
                            {cycles && cycles.length ? cycles.map(c => {
                                return(
                                    <React.Fragment key={c.id}>
                                        <tr
                                            style={{cursor: 'pointer', borderBottom: 'none'}}
                                            onClick={(e) => {toggleCycle(e, `.rc-${c.id}`)}}
                                        >
                                            <td className="sb-bolder td-titleMAW-350" style={{zIndex: '-15', paddingBottom: '5px'}}>
                                                {c.releases && c.releases.length ? (<i className="fa fa-chevron-down" aria-hidden="true" />) : (<i className="fa fa-chevron-down opacity-0" aria-hidden="true" />)}
                                                {c.releases && c.releases.length ? (<i className="fa fa-chevron-right d-none" aria-hidden="true" />) : (<i className="fa fa-chevron-right d-none opacity-0" aria-hidden="true" />)}
                                                <i className="fa fa-folder-o" aria-hidden="true" />
                                                {c.name} [{projects && projects.length ? projects.find(p => p.id === c.projectId).name : '-'}]
                                            </td>
                                            <td style={{paddingBottom: '5px'}} />
                                            <td style={{paddingBottom: '5px'}} />
                                            {/*<td className="center">{c.executions.length || '-'}</td>*/}
                                            <td style={{paddingBottom: '5px'}} className="td-actions">
                                                <i
                                                    style={{cursor: 'pointer'}}
                                                    className="fa fa-pencil-square-o opacity-0"
                                                    aria-hidden="true"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                        history.push(`/project/${c.projectId}/testCycles/${c.id}`)
                                                    }}
                                                />
                                                <i
                                                    style={{cursor: 'pointer'}}
                                                    className="fa fa-trash-o pl-1 opacity-0"
                                                    aria-hidden="true"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                        deleteHandler(c.id)
                                                    }}
                                                />
                                            </td>
                                        </tr>

                                        {/*RELEASES*/}
                                        {c.releases && c.releases.length ? c.releases.map(r => {
                                            return (
                                                <tr className={`rc-${c.id}`} onClick={() => {
                                                    history.push(`/testCycles/show/${c.id}/${r.id}`)
                                                }}
                                                    style={{cursor: 'pointer', borderBottom: 'none'}}
                                                    key={`rel-${r.id}`}
                                                >
                                                    <td style={{paddingTop: '5px', paddingBottom: '5px'}}>
                                                        <i className="fa fa-chevron-down opacity-0" aria-hidden="true" />
                                                        <i className="fa fa-folder-o opacity-0" aria-hidden="true" />
                                                        {r.name}
                                                    </td>
                                                    <td style={{paddingTop: '5px', paddingBottom: '5px'}} className="td-mid"><SimpleColorProgress props={progress(r.id, c.id).res}/></td>
                                                    <td style={{paddingTop: '5px', paddingBottom: '5px'}}>{progress(r.id, c.id).perc}%</td>
                                                    <td style={{paddingTop: '5px', paddingBottom: '5px'}} />
                                                </tr>
                                            )
                                        }) : (<></>)}

                                    </React.Fragment>
                                )
                            }) : (<></>)}
                            </tbody>
                        </table>
                    </div>
                ) : (<NoTable params={{ title: `Нет тестовых циклов` }} />)}
            </div>
        </>
    )

}
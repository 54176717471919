import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom"
import {Header} from "../partials/Header"
import {AuthContext} from "../../context/AuthContext"
import {useHttp} from "../../hooks/http.hook"
import {useMessage} from "../../hooks/message.hook"
import {Loader} from "../partials/Loader"
import {SimpleColorProgress} from "../partials/SimpleColorProgress"

export const ReleasePage = () => {
    const { token, user, logout } = useContext(AuthContext)
    const {loading, request, error, clearError} = useHttp()
    const [release, setRelease] = useState([])
    const message = useMessage()
    const history = useHistory()
    const id = useParams().releaseId

      // logout + error
    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    const getRelease = useCallback(async () => {
        try {
            const data = await request(`/api/release/${id}`, 'GET', null, {authorization: 'Bearer ' + token, details: 1})
            // console.log('rel', data.release)
            // setForm
            setRelease(prev => { return {...prev, ...data.release} })
        } catch (e) {
            console.log(e)
        }
    }, [request])

    useEffect(async () => {
        try {
            if (id) {
                await getRelease()
            }
            // getCycles(token)
        } catch (e) {
            console.log(e)
        }
    }, [id, getRelease])

    useEffect(() => {
        console.log('rel', release)
    }, [release])


    if (loading) {
        return <Loader />
    }

    // header buttons
    const createTestCase = () => {
        history.push(`/testCases/createTestCase`)
    }

    const progress = (cycleId) => {
        let res = [{
            value: 100,
            // color: '#a0a0a0'
            color: '#efefef'
        }]
        let perc = 0

        let cycle = release.cycles.find(c => c.id === Number(cycleId))

        if (cycle && cycle.executions.length) {
            let total = cycle.executions.length
            let unexec = cycle.executions.filter(ex => ex.status === 'Не выполнено').length
            let succes = cycle.executions.filter(ex => ex.status === 'Успех').length
            let fail = cycle.executions.filter(ex => ex.status === 'Неудача').length

            res = [
                {
                    value: Math.round(succes*100/total),
                    color: '#75b000' //green
                },
                {
                    value: Math.round(fail*100/total),
                    color: '#cc3402' //red
                },
                {
                    value: Math.round(unexec*100/total),
                    color: '#efefef' //gray
                },
                ]

            perc = Math.round(succes*100/total)
        }

        return {res, perc}
    }

    return (
        <>
            <Header params={{
                title: `Релизы > ${release.name}`,
                bk: [
                    {
                        title: 'Релизы ',
                        actionHandler: () => {history.goBack()}
                    },
                    {
                        title: `${release.name}`,
                        actionHandler: () => {}
                    },
                ],
                btnL: {
                    actionHandler: () => {},
                    title: '+ Релиз',
                    display: 'none'
                },
                btnR: {
                    actionHandler: createTestCase,
                    title: '+ Тест кейс',
                    display: 'inline-block'
                },
                loading
            }}/>

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    <h5>{release.name}</h5>
                    <p className="txt-gray" style={{fontSize: '1.1rem'}}>Тестовые циклы</p>
                </div>
            </div>

            <div className="row clear-row flex-row">
                <div className="col s12 pl-0">
                    {/*<SimpleColorProgress className="w-100" props={props}/>*/}
                    <table className="highlight">
                        <thead>
                        <tr>
                            <th className="txt-gray ">Заголовок</th>
                            <th className="txt-gray ">Статус</th>
                            <th className="txt-gray ">Прогресс</th>
                        </tr>
                        </thead>
                        <tbody>
                        {release && release.cycles && release.cycles.length ? release.cycles.map(c => {
                            return (
                                <React.Fragment key={c.id}>
                                    <tr>
                                        <td className="td-titleMAW-250"><i className="fa fa-folder-o" aria-hidden="true" />{c.name}</td>
                                        <td className="td-mid"><SimpleColorProgress props={progress(c.id).res}/></td>
                                        <td>{progress(c.id).perc}%</td>
                                    </tr>
                                </React.Fragment>
                            )
                        }) : (<></>)}
                        </tbody>
                    </table>
                </div>
                {/*<div className="col s5">*/}
                {/*</div>*/}
            </div>
        </>
    )
}

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router-dom"
import { SidebarContext } from "../../context/SidebarContext"
import { Loader } from "../partials/Loader"
import { AuthContext } from "../../context/AuthContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"
import { Header } from "../partials/Header"
import { Icon } from "../partials/Icon"

export const AgentForm = ({ agentId }) => {
    const { token, user, logout } = useContext(AuthContext)
    const { loading, request, error, clearError } = useHttp()
    // const id = useParams().id || null
    // const prj = useContext(SidebarContext)
    // const [project, setProject] = useState(null)
    // const [projects, setProjects] = useState(null)
    const message = useMessage()
    const history = useHistory()
    const [form, setForm] = useState({
        projectId: 0,
        userId: 0,
        name: 'Windows',
        data: 'Firefox',
        ip: '',
        description: '',
        executionId: 0
    })

    // logout + error
    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    // Активация input для materialize
    useEffect(() => {
        try {
            if (!loading) {
                window.M.updateTextFields()
                window.M.FormSelect.init(document.querySelectorAll('select'))
                window.M.textareaAutoResize(document.getElementById('description'))
            }
        } catch (e) {
            console.log(e)
        }
    })

    // const getProject = useCallback(async (token, id) => {
    //     try {
    //         if (token && id) {
    //             const data = await request(`/api/project/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
    //             setProject(data.project)
    //         }
    //         if (token) {
    //             const data = await request(`/api/project/projects`, 'POST', null, {authorization: 'Bearer ' + token})
    //             setProjects(data.projects)
    //             setProject(data.projects[0])
    //         }
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }, [request])

    const getAgent = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/agent/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
                console.log(data.agent)
                setForm(data.agent)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])


    useEffect(() => {
        // try {
        //     if (id) {
        //         // prj.toggle(false, id)
        //         setForm({ ...form, projectId: id, userId: user.id })
        //     } else {
        //         setForm({ ...form, userId: user.id })
        //     }
        //     getProject(token, id)
        // } catch (e) {
        //     console.log(e)
        // }
        if (agentId) {
            getAgent(token, agentId)
        }

        setForm({ ...form, userId: user.id })
        // console.log('agentId', agentId)

    }, [getAgent])//[getProject])

    useEffect(() => {
        console.log(form)
    }, [form])


    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
        // if (projects && projects.length && form.projectId === 0) {
        //     setForm({ ...form, projectId: projects[0].id })
        // }
    }

    const updateHandler = async () => {
        try {
            if (agentId) {
                console.log('update', form)
                const data = await request(`/api/agent/${agentId}`, 'PUT', { form }, { authorization: 'Bearer ' + token })
                message(data.message)
            } else {
                const data = await request('/api/agent/create', 'POST', { ...form }, { authorization: 'Bearer ' + token })
                message(data.message)
            }
            history.goBack()
        } catch (e) { }
    }

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <Header params={{
                title: `Создание агента`,
                btnL: {
                    actionHandler: () => { history.goBack() },
                    title: 'Cancel',
                    display: 'inline-block'
                },
                btnR: {
                    actionHandler: () => { },
                    title: '',
                    display: 'none'
                },
                loading
            }} />

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    <h5>Создание агента</h5>
                    {/*<p className="txt-gray">Manage your project settings</p>*/}
                </div>
            </div>

            {/*FORM*/}
            <div className="row clear-row flex-row">
                <div className="col s5 mt-0 ml-0 pl-0">
                    {/*TITLE*/}
                    {/*<div className="input-field">*/}
                    {/*<input*/}
                    {/*type="text"*/}
                    {/*id="name"*/}
                    {/*className=""*/}
                    {/*name="name"*/}
                    {/*autoComplete="off"*/}
                    {/*value={form.name}*/}
                    {/*onChange={changeHandler}*/}
                    {/*/>*/}
                    {/*<label htmlFor="name">Agent title</label>*/}
                    {/*</div>*/}

                    {/*PROJECT*/}
                    {/*<div className="input-field">*/}
                    {/*<select*/}
                    {/*id="project"*/}
                    {/*name="projectId"*/}
                    {/*value={form.projectId}*/}
                    {/*onChange={changeHandler}*/}
                    {/*>*/}
                    {/*{projects && projects.length ? projects.map(p => {*/}
                    {/*return (*/}
                    {/*<option key={p.id} value={p.id}>{p.name}</option>*/}
                    {/*)*/}
                    {/*}) : (<option value=""></option>)}*/}
                    {/*</select>*/}
                    {/*<label htmlFor="project">Project</label>*/}
                    {/*</div>*/}

                    {/*OS*/}
                    <div className="input-field">
                        <select
                            id="name"
                            name="name"
                            value={form.name}
                            onChange={changeHandler}
                        >
                            <option value="Windows" dangerouslySetInnerHTML={{ __html: `<div style="display: flex; justify-content: start; align-items: center" ><img style="margin-right: 1rem; overflow: hidden; width: 30px; height: 30px" src="/assets/icons/windows.png" alt=""/> <span>Windows</span></div>` }} />
                            <option value="MacOs" dangerouslySetInnerHTML={{ __html: `<div style="display: flex; justify-content: start; align-items: center" ><img style="margin-right: 1rem; overflow: hidden; width: 30px; height: 30px" src="/assets/icons/apple.png" alt=""/> <span>MacOs</span></div>` }} />
                            <option value="Mobile" dangerouslySetInnerHTML={{ __html: `<div style="display: flex; justify-content: start; align-items: center" ><img style="margin-right: 1rem; overflow: hidden; width: 30px; height: 30px" src="/assets/icons/mobile.png" alt=""/> <span>Mobile</span></div>` }} />
                            <option value="Containerized" dangerouslySetInnerHTML={{ __html: `<div style="display: flex; justify-content: start; align-items: center" ><img style="margin-right: 1rem; overflow: hidden; width: 30px; height: 30px" src="/assets/icons/container.png" alt=""/> <span>Containerized</span></div>` }} />
                        </select>
                        <label htmlFor="name">Операционная система</label>
                    </div>

                    {/*BROWSER*/}
                    {form.name !== 'Containerized' ?
                        <div className="input-field">
                            <select
                                id="data"
                                name="data"
                                value={form.data}
                                onChange={changeHandler}
                                
                            >
                                <option value="Firefox" dangerouslySetInnerHTML={{ __html: `<div style="display: flex; justify-content: start; align-items: center" ><img style="margin-right: 1rem; overflow: hidden; width: 30px; height: 30px" src="/assets/icons/firefox.png" alt=""/> <span>Firefox</span></div>` }} />
                                <option value="Opera" dangerouslySetInnerHTML={{ __html: `<div style="display: flex; justify-content: start; align-items: center" ><img style="margin-right: 1rem; overflow: hidden; width: 30px; height: 30px" src="/assets/icons/opera.png" alt=""/> <span>Opera</span></div>` }} />
                                <option value="Chrome" dangerouslySetInnerHTML={{ __html: `<div style="display: flex; justify-content: start; align-items: center" ><img style="margin-right: 1rem; overflow: hidden; width: 30px; height: 30px" src="/assets/icons/chrome.png" alt=""/> <span>Chrome</span></div>` }} />
                                <option value="IE" dangerouslySetInnerHTML={{ __html: `<div style="display: flex; justify-content: start; align-items: center" ><img style="margin-right: 1rem; overflow: hidden; width: 30px; height: 30px" src="/assets/icons/ie.png" alt=""/> <span>IE</span></div>` }} />
                            </select>
                            <label htmlFor="data">Браузер</label>
                        </div>
                        :
                        <div className="input-field">
                            <select
                                id="data"
                                name="data"
                                disabled
                            >
                            </select>
                            <label htmlFor="data">Браузер</label>
                        </div>
                    }
                    {/*USER*/}
                    <div className="input-field">
                        <p><span style={{ fontWeight: 'bold' }}>Создатель:</span> {user.name || ''}</p>
                    </div>

                    <div className="input-field">
                        <button
                            className="waves-effect waves-light btn blue lighten-1 noUpper"
                            onClick={updateHandler}
                            disabled={loading}
                        >
                            Сохранить
                        </button>
                    </div>
                </div>

                <div className="col s1 mt-0 ml-0 pl-0">
                </div>

                <div className="col s5 mt-0 ml-0 pl-0">
                    {/*IP*/}
                    <div className="input-field">
                        <input
                            type="text"
                            id="ip"
                            className=""
                            name="ip"
                            autoComplete="off"
                            value={form.ip}
                            onChange={changeHandler}
                        />
                        <label htmlFor="ip">IP</label>
                    </div>

                    {/*DATA*/}
                    {/*<div className="input-field">*/}
                    {/*<textarea*/}
                    {/*id="data"*/}
                    {/*name="data"*/}
                    {/*value={form.data}*/}
                    {/*onChange={changeHandler}*/}
                    {/*className="materialize-textarea"*/}
                    {/*/>*/}
                    {/*<label htmlFor="data">Data</label>*/}
                    {/*</div>*/}

                    <div className="input-field">
                        <textarea
                            id="description"
                            name="description"
                            value={form.description}
                            onChange={changeHandler}
                            className="materialize-textarea"
                        />
                        <label htmlFor="description">Описание</label>
                    </div>

                </div>

            </div>

        </>
    )

}

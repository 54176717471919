// DualProgressBar.js
import React from 'react';
// import './DualProgressBar.css';

const DualProgressBar = ({ progress1, progress2 }) => {
  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '32px',
    backgroundColor: '#e0e0df',
    borderRadius: '10px',
    overflow: 'hidden'
  };

  const progressStyle = (progress, color) => ({
    position: 'absolute',
    height: '100%',
    width: `${progress}%`,
    backgroundColor: color,
    borderRadius: '10px',
    transition: 'width 0.3s ease'
  });

  return (
    <div style={containerStyle}>
      <div style={progressStyle(progress1, '#9676D1')} />
      <div style={progressStyle(progress2, '#21133E')} />
    </div>
  );
};

export default DualProgressBar;

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { AuthContext } from "../../context/AuthContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"
import { Header } from "../partials/Header"
import { Loader } from "../partials/Loader"

export const ContainerForm = ({ agentId }) => {
    const { token, user, logout } = useContext(AuthContext)
    const { loading, request, error, clearError } = useHttp()
    // const id = useParams().id || null
    // const prj = useContext(SidebarContext)
    // const [project, setProject] = useState(null)
    // const [projects, setProjects] = useState(null)
    const message = useMessage()
    const history = useHistory()
    const [form, setForm] = useState({
        projectId: 0,
        userId: 0,
        ip: '',
        description: '',
        executionId: 0
    })

    // logout + error
    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    // Активация input для materialize
    useEffect(() => {
        try {
            if (!loading) {
                window.M.updateTextFields()
                window.M.FormSelect.init(document.querySelectorAll('select'))
                window.M.textareaAutoResize(document.getElementById('description'))
            }
        } catch (e) {
            console.log(e)
        }
    })



    const getContainer = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/container/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
                console.log(data.container)
                setForm(data.container)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])


    useEffect(() => {

        if (agentId) {
            getContainer(token, agentId)
        }

        setForm({ ...form, userId: user.id })

    }, [getContainer])

    useEffect(() => {
        console.log(form)
    }, [form])


    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })

    }

    const updateHandler = async () => {
        try {
            if (agentId) {
                console.log('update', form)
                const data = await request(`/api/container/${agentId}`, 'PUT', { form }, { authorization: 'Bearer ' + token })
                message(data.message)
            } else {
                const data = await request('/api/container/create', 'POST', { ...form }, { authorization: 'Bearer ' + token })
                message(data.message)
            }
            history.goBack()
        } catch (e) { }
    }

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <Header params={{
                title: `Создание агента в контейнере`,
                btnL: {
                    actionHandler: () => { history.goBack() },
                    title: 'Отмена',
                    display: 'inline-block'
                },
                btnR: {
                    actionHandler: () => { },
                    title: '',
                    display: 'none'
                },
                loading
            }} />

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    <h5>Создание агента в контейнере</h5>
                    {/*<p className="txt-gray">Manage your project settings</p>*/}
                </div>
            </div>

            {/*FORM*/}
            <div className="row clear-row flex-row">


                {/* <div className="col s1 mt-0 ml-0 pl-0">
                </div> */}

                <div className="col s5 mt-0 ml-0 pl-0">
                    {/*IP*/}
                    <div className="input-field">
                        <input
                            type="text"
                            id="ip"
                            className=""
                            name="ip"
                            autoComplete="off"
                            value={form.ip}
                            onChange={changeHandler}
                        />
                        <label htmlFor="ip">IP</label>
                    </div>


                    <div className="input-field">
                        <textarea
                            id="description"
                            name="description"
                            value={form.description}
                            onChange={changeHandler}
                            className="materialize-textarea"
                        />
                        <label htmlFor="description">Описание</label>
                    </div>

                    <div className="input-field">
                        <p><span style={{ fontWeight: 'bold' }}>Создатель:</span> {user.name || ''}</p>
                    </div>

                    <div className="input-field">
                        <button
                            className="waves-effect waves-light btn blue lighten-1 noUpper"
                            onClick={updateHandler}
                            disabled={loading}
                        >
                            Сохранить
                        </button>
                    </div>

                </div>

            </div>

        </>
    )

}

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router-dom"
import { SidebarContext } from "../../context/SidebarContext"
import { Loader } from "../partials/Loader"
import { AuthContext } from "../../context/AuthContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"
import { Header } from "../partials/Header"

export const TestCycleForm = ({ cycleId }) => {
    const { token, user, logout } = useContext(AuthContext)
    const { loading, request, error, clearError } = useHttp()
    const id = useParams().id || null
    const prj = useContext(SidebarContext)
    const [project, setProject] = useState(null)
    const [projects, setProjects] = useState(null)
    const [users, setUsers] = useState(null)
    const message = useMessage()
    const history = useHistory()
    const [cases, setCases] = useState([])
    const [form, setForm] = useState({
        projectId: 0,
        userId: 0,
        name: '',
        status: 'Unexecuted',
        description: '',
        cases: []
    })

    // logout + error
    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError])

    // Активация input для materialize
    useEffect(() => {
        try {
            if (!loading) {
                window.M.updateTextFields()
                window.M.textareaAutoResize(document.getElementById('description'))
                window.M.FormSelect.init(document.querySelectorAll('select'), { classes: 'black-text' })
            }
        } catch (e) {
            console.log(e)
        }
    })

    const getProject = useCallback(async (token, id) => {
        try {
            if (token && id) {
                console.log('has id')
                const data = await request(`/api/project/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
                setProject(data.project)
            }
            if (token) {
                console.log('NO id')
                const data = await request(`/api/project/projects`, 'POST', null, { authorization: 'Bearer ' + token })
                setProjects(data.projects)
                if (!cycleId && !id) setForm(prev => { return { ...prev, projectId: data.projects[0].id } })
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    // if cycleId !== 0 this is EDIT, not create.
    // Then get cycle by id and set same variables
    const getCycle = useCallback(async () => {
        try {
            // get case by id (with labels, steps, cycles, releases
            const data = await request(`/api/cycle/${cycleId}`, 'GET', null, { authorization: 'Bearer ' + token })
            // setForm
            setForm(prev => { return { ...prev, ...data.cycle } })
        } catch (e) {
            console.log(e)
        }
    }, [request])

    const getCases = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/case/all`, 'POST', { projectId: Number(id) }, { authorization: 'Bearer ' + token })
                setCases(data.cases)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    // get users data
    const fetchUsers = useCallback(async () => {
        try {
            const data = await request(`/api/auth/users`, 'POST', null, { authorization: 'Bearer ' + token })
            if (data.users) {
                setUsers(data.users)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request, setUsers])

    useEffect(async () => {
        try {
            // prj.toggle(false, id)
            // console.log('use id', id)
            getProject(token, id)
            fetchUsers()
            if (cycleId) {
                await getCycle()
            } else {
                if (id) {
                    setForm(prev => { return { ...prev, projectId: Number(id), userId: user.id } })
                } else {
                    setForm(prev => { return { ...prev, userId: user.id } })
                }
            }
            console.log('user', user.id)
            // setForm(prev => { return {...prev, userId: user.id }})
        } catch (e) {
            console.log(e)
        }
    }, [getProject, cycleId, getCycle, id])

    useEffect(() => {
        try {
            if (form.projectId) getCases(token, form.projectId)
        } catch (e) {
            console.log(e)
        }
    }, [form.projectId])

    useEffect(() => {
        console.log('form', form)
        console.log('cases', cases)
        console.log('projects', projects)
    }, [form])

    const changeHandler = event => {
        if (event.target.name === 'cases') {
            let value = Array.from(event.target.selectedOptions, option => Number(option.value))
            setForm({ ...form, [event.target.name]: value })
        } else {
            setForm({ ...form, [event.target.name]: event.target.value })
        }
    }

    const updateHandler = async () => {
        try {
            if (cycleId) {
                // UPDATE
                // const data = await request(`/api/cycle/${cycleId}`, 'PUT', {form, execRows}, {authorization: 'Bearer ' + token})
                const data = await request(`/api/cycle/${cycleId}`, 'PUT', { form }, { authorization: 'Bearer ' + token })
                message(data.message)

                history.goBack()
            } else {
                // CREATE
                // const data = await request('/api/cycle/create', 'POST', {form, execRows}, {authorization: 'Bearer ' + token})
                const data = await request('/api/cycle/create', 'POST', { form }, { authorization: 'Bearer ' + token })
                message(data.message)
                history.goBack()
            }
        } catch (e) { }
    }

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <Header params={{
                title: !cycleId ? `Создание цикла` : `Изменение цикла`,
                btnL: {
                    actionHandler: () => { history.goBack() },
                    title: 'Отмена',
                    display: 'inline-block'
                },
                btnR: {
                    actionHandler: () => { },
                    title: '',
                    display: 'none'
                },
                loading
            }} />

            <div className="row clear-row afterHeader">
                <div className="col-auto">
                    <h5>{!cycleId ? `Создать цикл` : `Изменить цикл`}</h5>
                    {/*<p className="txt-gray">Manage your project settings</p>*/}
                </div>
            </div>

            {/*FORM*/}
            {/*TITLE*/}
            <div className="row clear-row flex-row">
                <div className="col s5 mt-0 ml-0 pl-0">
                    <div className="input-field">
                        <input
                            type="text"
                            id="name"
                            className=""
                            name="name"
                            autoComplete="off"
                            value={form.name}
                            onChange={changeHandler}
                        />
                        <label htmlFor="name">Заголовок цикла</label>
                    </div>

                    {/*DESCRIPTION*/}
                    <div className="input-field">
                        <textarea
                            id="description"
                            name="description"
                            value={form.description}
                            onChange={changeHandler}
                            className="materialize-textarea" />
                        <label htmlFor="description">Описание (необязательно)</label>
                    </div>

                    {/*CASES*/}
                    <div className="input-field">
                        <select
                            id="cases"
                            name="cases"
                            multiple={true}
                            value={form.cases}
                            onChange={changeHandler}
                        >
                            {cases && cases.length ? cases.map(c => {
                                return (
                                    <option key={c.id} value={c.id}>{c.title}</option>
                                )
                            }) : (<option value=""></option>)}
                        </select>
                        <label htmlFor="cases">Тестовые кейсы</label>
                    </div>

                </div>

                <div className="col s1 mt-0 ml-0 pl-0">
                </div>

                <div className="col s5 mt-0 ml-0 pl-0">
                    {/*PROJECT*/}
                    <div className="input-field">
                        <select
                            id="project"
                            name="projectId"
                            value={form.projectId}
                            onChange={changeHandler}
                        >
                            {projects && projects.length ? projects.map(p => {
                                return (
                                    <option key={p.id} value={p.id}>{p.name}</option>
                                )
                            }) : (<option value=""></option>)}
                        </select>
                        <label htmlFor="project">Проект</label>
                    </div>


                </div>

            </div>

            <div className="row clear-row flex-row">
                <div className="col s5 mt-0 ml-0 pl-0">
                    <div className="input-field">
                        <button
                            className="waves-effect waves-light btn blue lighten-1 noUpper"
                            onClick={updateHandler}
                            disabled={loading}
                        >
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>
        </>
    )

}

import React, {useRef, useEffect} from 'react'

export const CircleProgress = ({
                   size=100,
                   // width=100,
                   // height=100,
                   progress=0,
                   showText=true,
                   fontSize='10px',
                   strokeWidth='4px',
                   color='#FFB10A'
}) => {

    const circle = useRef(null)
    const pct = useRef(null)

    let circleStyle = {
        fill: 'none',
        stroke: color,
        strokeWidth,
        transformOrigin: 'center',
        transform: 'rotate(-90deg)'
    }

    let textStyle = {
        dominantBaseline: 'middle',
        textAnchor: 'middle',
        fontSize,
        display: showText ? 'block' : 'none'
    }

    function setProgress(percent, circumference) {
        circle.current.style.strokeDashoffset = circumference * (1 - percent / 100)
        pct.current.innerHTML = percent.toFixed(0) + '%'
    }

    useEffect(() => {
        if (circle && pct) {

            const circumference = 2 * Math.PI * circle.current.r.baseVal.value
            circle.current.style.strokeDasharray = `${circumference} 1000`

            console.log('Set progress', Number(progress))
            setProgress(Number(progress), circumference)

            // requestAnimationFrame(draw)
            //
            // function draw(t) {
            //     requestAnimationFrame(draw)
            //     setProgress((t/100)%progress, circumference)
            // }

        }
    }, [circle, pct, progress])

    return (
        <div className='p-0 m-0' style={{minWidth: size, minHeight: size, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <svg width={size} height={size}>
                <circle cx={size/2} cy={size/2} r={size/3} style={circleStyle} id="circle" ref={circle} />
                <circle cx={size/2} cy={size/2} r={size/3.5} style={{...circleStyle, stroke: '#eff1f4', strokeDashoffset: '100', strokeWidth: '2px'}} />
                <text x={size/2} y={size/2} style={textStyle} id="pct" ref={pct}/>
            </svg>
        </div>
        // <svg className="svg_circle_loader" width={width} height={height}>
        //     <circle cx={width/2} cy={width/2} r={width/4} style={circleStyle} id="circle" ref={circle} />
        //     <text x={width/2} y={width/2} style={textStyle} id="pct" ref={pct}/>
        // </svg>
    )
}
import React from 'react'
import {TestCycleForm} from "./TestCycleForm"

export const CreateTestCyclePage = () => {
    return (
        <>
            <TestCycleForm cycleId={0} />
        </>
    )
}

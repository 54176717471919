import React from 'react'
import {TestCaseForm} from "./TestCaseForm"

export const CreateTestCasePage = () => {
    return (
        <>
            <TestCaseForm caseId={0} />
        </>
    )

}
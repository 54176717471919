import React from 'react'
import {TestCaseForm} from "./TestCaseForm"
import {useParams} from "react-router-dom"

export const EditTestCasePage = () => {
    const id = useParams().caseId
    return (
        <>
            <TestCaseForm caseId={id} />
        </>
    )

}